.upselling-modal__header {
    display: block;
    text-align: left;
    color:var(--color-primary);
    padding: 2.5rem;
    /*background:var(--color-warning);*/
    @media screen and (max-width: 767px){
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
}
.upselling-modal__body{
    padding:0!important;
}
.upselling-modal__footer {
    background-color: var(--color-turquoise);
    padding: 1.5rem;
    color: #fff;
    text-align: center;
    @media screen and (min-width: 768px){
        padding: 3rem 3.5rem;
    }
}
.upselling-modal__img{
    position: relative;
}
.upselling-modal__img .upselling-modal__badge {
    position: absolute;
    top: calc(10rem/16);
    left: calc(10rem/16);
    z-index: 2;
}
.upselling-modal__img.upselling-modal__img--sold-out:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    background: rgba(204, 204, 204, 0.3);
}
.upselling-modal__product-description li {
    @media screen and (max-width: 767px){
        font-size: .75rem;
    }
}
.upselling-modal__product-description li:not(:last-child) {
    margin-bottom: .325rem;
}
.upselling-modal__item:not(:last-child) {
    border-bottom: 2px solid #f5f5f5;
}
.upselling-modal__item{
    padding: 1rem;
    @media screen and (min-width: 768px){
        padding: 2.5rem;
    }
}
.upselling-modal__btn {
    margin: 0.5rem 0;
    padding: 1rem 2rem!important;
    @media screen and (max-width: 767px){
        padding: .5625rem 1.5rem!important;
        font-size: .625rem;
        line-height: .75rem;
        letter-spacing: .0625rem;
    }
}
.upselling-modal__link {
    padding-bottom: calc(4rem/16);
    position: relative;
    transition: transform 0.15s ease-in;
    font-size: .75rem;
    font-family: var(--font-default-bold);
    @media screen and (min-width:768px){
        font-size: .875rem;
    }
}
.upselling-modal__link:after {
    content: "";
    position: absolute;
    bottom: 0;
    right:0;
    left: 0;
    height: calc(2rem/16);
    background-color: var(--color-text-default);
    transform: scaleX(0);
    transition: transform 0.15s ease-in;
}
.upselling-modal__link:hover:after ,
.upselling-modal__link:focus:after  {
    transform: scaleX(1);
}