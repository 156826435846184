.horizontal-teaser {
    letter-spacing: calc(.5rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(24/16);
    }
}
.horizontal-teaser + .horizontal-teaser {
    margin-top: calc(40rem / 16);
}
.horizontal-teaser__body {
    padding-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: 0;
    }
}
.horizontal-teaser__body__title {
    font-size: calc(18rem / 16);
    line-height: calc(32/20);
    margin-bottom: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.horizontal-teaser--wide .horizontal-teaser__body {
    @media screen and (min-width: 768px) {
        padding-top: calc(22rem / 16);
    }
}

.horizontal-teaser--bordered + .horizontal-teaser--bordered {
    border-top: calc(1rem / 16) solid var(--color-secondary);
    padding-top: calc(40rem/16);
}