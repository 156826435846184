.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
}

.content-block + .content-block.content-block--big-xs {
    @media (max-width: 767px) {
        margin-top: calc(72rem/16);
    }
}

.content-block + .content-block.content-block--sm {
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}

.block-title{
    border: none;
    margin-bottom: calc(18rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(32rem / 16);
    }
}
.block-title--sm {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(10rem / 16);
        font-size: calc(20rem / 16);
    }
}

.page-title{
    color:var(--color-primary);
    margin: 1rem 0 1.5rem 0;
    @media screen and (min-width: 768px) {
        margin: 2rem 0 1.5rem 0;
    }
}

.page-title--checkout {
    margin:2.5rem 0 1rem 0;
    @media screen and (min-width: 768px) {
        margin:4rem 0 1.5rem 0;
    }
}

.page-title__wysiwyg {
    margin:1rem 0 1rem 0;
    @media screen and (min-width: 768px) {
        margin:1.5rem 0 2.5rem 0;
    }
}

.page-sub-title.page-sub-title{
    font-size:1.25rem;
    font-family: var(--font-default-bold);
    margin: 0 0 1rem 0;
    @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
        font-size:1.5rem;
    }
}

/* this should remove the space between this (last) element and the page-footer */
#main-content > .content-block:last-child.content-block--no-footer-margin:not(.content-block--no-spacing),
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin:not(.content-block--no-spacing) {
    margin-bottom: calc(-40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-80rem/16);
    }
}

.content-block--standalone {
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
}