.language-modal__header.modal-header {
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    @media screen and (max-width: 767px) {
        padding: 2.5rem 2.5rem 1.25rem 2.5rem;
    }
}
.language-modal__body.modal-body {
    padding: calc(40rem/16) calc(80rem/16) calc(120rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(40rem/16) calc(60rem/16);
    }
}
.language-modal__link {
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-semi-bold);
    display: flex;
    align-items:center;
    padding: calc(10rem/16) 0;
    transition: color 0.2s ease;
}
.language-modal__link:hover {
    color: var(--color-primary-dark);
}
.language-modal__cart-icon {
    margin-left: calc(10rem/16);
    color: var(--color-dark-grey);
    font-size: calc(18rem/16);
}
.language-modal__dropdown-icon {
    color: var(--color-dark-grey);
    font-size: calc(10rem/16);
    margin-left: calc(8rem/16);
}
.language-modal__dropdown-toggle:after {
    display: none;
}
.language-modal__dropdown-menu  {
    font-size: calc(13rem/16);
    letter-spacing: calc(1rem/16);
}
.language-modal__dropdown-item:hover {
    background-color: var(--color-dark-grey);
    color:#fff;
}
.language-modal__flag {
    width: calc(24rem/16);
    height: calc(24rem/16);
    margin-right: calc(10rem/16);
}

.language-modal__legend {
    letter-spacing: calc(1rem/16);
    font-size: calc(14rem/16);
    margin-top:3.5rem;
    @media screen and (min-width: 768px) {
        margin:0;
        position: absolute;
        right:2rem;
        bottom:2rem;
    }
}
.language-modal__legend .icon {
    font-size: calc(21rem/16);
    line-height:1;
    position: relative;
    top: 0.25rem;
}