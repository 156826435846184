.navbar-main {
    padding: 0;

    @media screen and (min-width: 768px) {
        min-height: calc(55rem/16);
    }
}
.navbar-main .nav-item {
    @media screen and (min-width: 768px) {
        margin: 0 calc(2rem / 16);
    }
}
.navbar-main .nav-item .nav-link {
    font-size: calc(14rem / 16);
    padding: calc(30rem / 16) calc(10rem / 16);
    position: relative;
    font-family: var(--font-default-semi-bold);
    white-space:nowrap;

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) calc(12rem / 16) calc(18rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .navbar-main .nav-item .nav-link:after {
        content: '';
        position: absolute;
        bottom: calc(14rem / 16);
        left: calc(26rem/16);
        right: calc(26rem/16);
        height: 1px;
        background: var(--color-primary);
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }
    .navbar-main .nav-item .nav-link:hover:after,
    .navbar-main .nav-item .nav-link.active:after,
    .navbar-main .nav-item.is-open .nav-link:after,
    .navbar-main .nav-item:hover .nav-link:after{
        transform-origin: bottom left;
        transform: scaleX(1);
    }
}

/* sale */
.navbar-main .nav-item .nav-link.has-sale {
    color: var(--color-danger);
}
.navbar-main .nav-item .nav-link.has-sale:after {
    background: var(--color-danger);
}


.navbar-main__dropdown {
    background: var(--color-light-grey);
    color: var(--color-text-default);
    z-index: 11;
    display: none;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    transition-delay: 100ms;
    content-visibility: auto;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        display: block;
        visibility: hidden;
        opacity: 0;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    }
}

@media screen and (min-width: 768px) {
    .col-navbar-main-desktop {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .navbar-main__dropdown__inner {
        padding: calc(40rem / 16) 1.5rem;
    }
    .navbar-main__dropdown__list__more > a,
    .navbar-main__dropdown__list__title,
    .navbar-main__dropdown li > a {
        display: inline-block;
        position: relative;
        margin-bottom: calc(8rem / 16);
    }
    .navbar-main__dropdown__list__title.has-sale {
        color: var(--color-danger) !important;
    }
    .navbar-main__dropdown__list__more > a:after,
    .navbar-main__dropdown__list__title:after,
    .navbar-main__dropdown li > a:after {
        content: '';
        position: absolute;
        bottom: -.125rem;
        left: 0;
        right: 0;
        height: calc(1rem / 16);
        background: currentColor;
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }

    /* with icon */
    a.navbar-main__dropdown__list__title--w-icon {
        padding-left: calc(32rem / 16);
    }
    .navbar-main__dropdown__list__heart {
        position: absolute;
        left:0;
        top:-.125rem;
    }
    a.navbar-main__dropdown__list__title--w-icon:after {
        left:2rem;
    }

    .navbar-main__dropdown__list__more > a:hover:after,
    a.navbar-main__dropdown__list__title:hover:after,
    .navbar-main__dropdown li > a:hover:after {
        opacity: 1;
    }
}
a.navbar-main__dropdown__list__title:hover,
.navbar-main__dropdown li > a:hover {
    color: var(--color-primary);
}
.navbar-main .nav-item.is-open  .navbar-main__dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .navbar-main .nav-item:hover  .navbar-main__dropdown,
    .navbar-main .nav-item.is-open  .navbar-main__dropdown,
    .navbar-main__dropdown:hover {
        visibility: visible;
        opacity: 1;
    }
    .navbar-main__dropdown__list {
        margin-bottom: calc(40rem/16);
        max-width:85%;
    }
}
.navbar-main__dropdown__list__title {
    font-size: calc(14rem / 16);
    font-family: var(--font-default-semi-bold);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.navbar-main__dropdown .navbar-main__dropdown__list__title:after {
    height: calc(2rem / 16);
}
.navbar-main__dropdown__list > ul li + li {
    margin-top: 6px;
}
.navbar-main__dropdown__list__more {
    color: var(--color-dark-grey);
}

/* affix */
.navbar-container {
    position: relative;
    z-index: 500;
}
.navbar-container.is-affix {
    position: fixed;
    top: 0;
    left: 0;
    right: var(--fixed-offset-right);
}
.navbar-language-switch {
    display: flex;
    font-family: var(--font-default-semi-bold);
    align-items: center;
    color: inherit;
    white-space: nowrap;
    font-size: calc(14rem/16);
    padding: calc(8rem/16) calc(10rem/16);
    border-radius:1rem;
}
.navbar-language-switch--footer {
    padding:.375rem 0 0 0;
    display: inline-block;
    background: transparent;
}
.navbar-language-switch:active,
.navbar-language-switch:hover,
.navbar-language-switch:focus {
    background: #fff;
    border:0;
    outline: 0;
}
.navbar-language-switch__flag {
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-right: calc(10rem/16);
}