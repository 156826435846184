.list-seperator--dots li{
    position: relative;
}

.list-seperator--dots li:not(:last-child){
    margin-right: calc(12rem / 16);
}

.list-seperator--dots li:not(:last-of-type):after{
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: var(--color-primary);
    position: absolute;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
}