.login-box {
    padding:1.25rem;
    background: #fff;

    @media screen and (min-width: 768px) {
        padding:2.5rem;
    }
}
.login-box__headline {
    font-family: var(--font-default-bold);
    font-size:1.25rem;
    margin-bottom:1rem;
}