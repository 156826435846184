:root {
    --navbar-height: calc(139rem/16);
    --footer-height: calc(674rem/16);
    --navbar-height-mobile: calc(75rem/16);
    --container-max-width: calc(1720rem/16);
    --row-gutter: calc(15rem/16);
}

html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-light-grey);
    overflow-x: hidden;
}
body.magazine {
    background-color: #fff;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
}
.container.container {
    max-width: calc(1720rem/16);
    width: 100%;
}

.container.container-narrow {
    max-width: calc(816rem / 16);
    margin-left: auto;
    margin-right: auto;
}

.container.container-medium {
    max-width: calc(1140rem / 16);
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1720px) {
    .container.container__move-right {
        /*margin-left: calc((100vw - var(--container-max-width)) / 2 + var(--row-gutter));*/
        max-width: calc(var(--container-max-width) + (100vw - var(--container-max-width)) / 2 - var(--row-gutter));
        padding-right: 0;

        margin-left: auto;
        margin-right: 0;
    }
}

/* fix container in container */
.container .container {
    padding-left: 0;
    padding-right: 0;
}

/* todo: can be removed? */
.container.container-slim{
    max-width: calc(850rem / 16);
}

.has-anchor-offset {
    border-top: 1px solid transparent;
    margin-top: calc(var(--navbar-height) * -1);
    padding-top: calc(var(--navbar-height));
    @media (max-width: 767px) {
        margin-top: calc(var(--navbar-height-mobile) * -1);
        padding-top: calc(var(--navbar-height-mobile));
    }
}

body:not(.magazine) #main-content {
    margin-bottom: calc(40rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(80rem/16);
    }
}