.usps {
    position: relative;
    background-color: #FFFFFF;
    color: var(--color-text-default);
    z-index: 50;
    text-wrap: balance;
}
.usps--sale {
    background-color: var(--color-sale);
    color:#fff;
}
.navbar-container .usps .container {
    padding:0 1rem;
}
.usps__items {
    height: calc(34rem/16);
    color:inherit;
    font-family: var(--font-default-semi-bold);
    @media  screen and (min-width: 768px) {
        height: calc(41rem/16);
        letter-spacing: 1px;
    }
}

.usps__item{
    display: flex;
    align-items: center;
    font-size: calc(10rem/16);
    @media  screen and (min-width: 768px) {
        font-size: calc(12rem/16);
    }
}
.usps__icon{
    font-size: 1rem;
    margin-right: calc(6rem/16);
}
.usps__icon.icon-trust{
    font-size: calc(20rem/16);
    @media  screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.usps-flyout {
    color:var(--color-redesign-primary-contrast);
    height: auto;
    max-height: calc(100dvh - 2.125rem);
    overflow-y: auto;
    padding: 2.75rem 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: auto;
    background: var(--color-redesign-primary);
    transition: transform 250ms ease-out;
    transform: translateY(-120%);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    @media  screen and (min-width: 768px) {
        padding:5rem 0 6rem 0;
        max-height:90vh;
    }
}
.is-affix .usps-flyout--open,
.not-is-affix .usps-flyout--open {
    transform: translateY(-110%);
}
.usps-flyout--open {
    transform: translateY(0%);
}
.usps-flyout__close {
    position: absolute;
    right:2rem;
    top:2rem;
    width:2rem;
    height:2rem;
    line-height: 2rem;
    border-radius: 100%;
    text-align: center;
    font-size: .625rem;
    border:1px solid var(--color-redesign-primary-contrast);
    color:var(--color-redesign-primary-contrast);

@media screen and (max-width: 767px) {
        right:1rem;
        top:1rem;
        width:1.625rem;
        height:1.625rem;
        line-height: 1.625rem;
    }
}
.usps-flyout__close:hover,
.usps-flyout__close:focus {
    background:var(--color-redesign-primary-contrast);
    color:var(--color-redesign-primary);
}
.usps-flyout__item {
    display: block;
    text-align: center;
    font-family: var(--font-default-bold);
    transition: transform 120ms ease-out;
}
.usps-flyout__item > .icon {
    display: block;
    width:auto;
    height:calc(81rem / 16);
    font-size:calc(80rem / 16);
    margin-bottom:.625rem;
    transition: transform 120ms ease-out;

    @media screen and (max-width: 767px) {
        font-size:calc(54rem / 16);
        height:calc(56rem / 16);
    }
}
.usps-flyout__item:hover > .icon,
.usps-flyout__item:focus > .icon {
    transform: translateY(-.375rem);
}
.usps-flyout__text {
    position: relative;
    display: inline-block;
    transition: transform 120ms ease-out;
}
.usps-flyout__text:before {
    content:'';
    width:.5rem;
    height:.5rem;
    display:inline-block;
    margin-right: .5rem;
    vertical-align: .1em;
    border:.125rem solid;
    border-width:0 .125rem .125rem 0;
    transform: rotate(-45deg);
}
