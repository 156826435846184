.tt-menu.tt-menu{
    background-color: white;
    max-height: calc( 100vh - 200px );
    overflow: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding:0 calc(20rem / 16);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);

    @media screen and (min-width: 768px){
        width:25rem;
        left:auto !important;
        right:0;
        margin:-.625rem 0 0 0;
    }
}
.tt-menu.tt-menu.tt-open:not(.tt-empty) {
    display: flex !important; /* overwrite tt inline style */
}

.tt-menu .typeahead-heading{
    font-family: var(--font-default-bold);
    padding: calc(20rem / 16) calc(10rem / 16) calc(6rem/16);
    border: none;
}

.tt-menu .typeahead-suggestion{
    padding-left: 0;
}

.tt-suggestion {
    padding: calc(5rem / 16) calc(10rem / 16);
    margin: 0;
    text-align: left;
}

.tt-dataset {
    margin: 1rem 0;
    width: 100%;
}
.tt-dataset:empty {
    display: none;
}
.tt-dataset-male_category,
.tt-dataset-female_category {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
}

.tt-suggestion--product + .tt-suggestion--product {
    border-top: 2px solid var(--color-light-grey);
}

.tt-suggestion--product {
    align-items: center;
    padding: calc(10rem/16) 0;
}

.tt-suggestion--search-button {
    margin-top: calc(16rem/16);
}

.tt-menu .tt-selectable.tt-cursor{
    color: var(--color-primary);
    text-decoration: underline;
}

.tt-menu{
    font-size: calc(14rem / 16);
}

.tt-suggestion__product-image {
    width: 90px;
}
