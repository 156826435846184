.table-responsive--w-gradient {
    position: relative;
    background: #fff;

    @media screen and (max-width: 767px){
        white-space:nowrap;
    }
}

@media screen and (max-width: 767px){
    .table-responsive--w-gradient:after {
        content:'';
        position: absolute;
        top:0;
        bottom:0;
        right:-1px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        width:2.5rem;
    }
}

.table--lastOrders {
    @media screen and (min-width: 768px){
        margin-top: calc(20rem / 16);
    }
}

.table--lastOrders thead th{
    border: none;
}

.table--lastOrders thead{
    @media screen and (max-width: 767px){
        display: none;
    }
}
.table--lastOrders tbody td{
    border-bottom:1px solid var(--color-primary);
}
/*.table--lastOrders thead th,
.table--lastOrders tbody td{
    font-size: calc(13rem / 16);
}

.table.table--return thead th,
.table.table--return tbody td{
    font-size: calc(13rem / 16);
}*/


.table--align-middle th,
.table--align-middle td {
    vertical-align: middle;
}

.table--bordered-light td, .table--bordered-light th {
    border-top: 3px solid var(--color-light-grey);
}


.table--lastOrders tbody,
.table--lastOrders tbody tr,
.table--lastOrders tbody td    {
    font-size: calc(14rem / 16);
    @media screen and (max-width: 767px){
        display: block;
        border: none;
        padding: 5px 0;
    }
}

.table--lastOrders tbody tr {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(44rem / 16);
    }
}

.table-name{
    white-space: nowrap;
}

.loading-overlay--table{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}


.table-cart{
    display: table;
}

.table-cart__thead{
    display: table-header-group;
}

.table-cart__tbody{
    display: table-row-group;
    border-bottom: 1px solid var(--color-primary);
}

.table-cart__tr{
    display: table-row;
}

.table-cart__cell{
    display: table-cell;
    vertical-align: top;
    padding: 0.75rem 0;
    @media (min-width: 768px) {
        padding: 0.75rem;

    }
}

.table--lastOrders thead th{
    background: #fff;
}

.table--lastOrders tbody td{
    padding-top: calc(14rem / 16);
    padding-bottom: calc(14rem / 16);
}

.table--lastOrders thead{
    white-space: nowrap;
}