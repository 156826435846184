.feature-teaser{

}

.text-img-teaser{
    overflow: hidden;
    width: fit-content
}

.text-img-teaser--margin{
    @media screen and (min-width: 768px){
        margin-top: calc(75rem/16);
    }
}

.text-img-teaser.text-img-teaser--mb{
    margin-bottom: calc(30rem / 16) !important;
}

.text-img-teaser__link--pb{
    padding-bottom: calc(5rem / 16) !important;
}

.img-zoom{
    transform: scale(1);
    transition-duration: .75s;
}

.img-zoom:hover,
.product-teaser:hover .img-zoom{
    transform: scale(1.05);
}

.product-teaser__additional{
    position: absolute;
    top: calc(11rem/16);
    right: calc(11rem/16);
    z-index:10;
    transition: opacity 500ms ease;
    @media screen and (min-width: 768px){
        top: calc(11rem/16);
    }
}
.product-teaser__additional-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-grey);
    width: calc(30rem/16);
    height: calc(30rem/16);
    font-size:1.25rem;
    outline:0 !important;
    @media screen and (max-width: 767px){
        display: none !important;
    }
    @media screen and (min-width: 768px){
        font-size:1.5rem;
        width: calc(40rem/16);
        height: calc(40rem/16);
        opacity:0;
        transition: opacity 500ms ease;
    }
}
.hide-additional-products .product-teaser__additional-btn {
    display: none !important;
}

@media screen and (min-width: 768px){
    .product-teaser:hover .product-teaser__additional-btn {
        opacity:1;
    }
}

.product-teaser__icons{
    position: absolute;
    top: calc(8rem/16);
    left: calc(10rem/16);
    right: 2.5rem;
    @media screen and (min-width: 768px) {
        right: 3.25rem;
    }
}
.product-teaser__icons__feature {
    width: calc(70rem/16);
    margin-right: .5rem;
}
.product-teaser__icons-list {
    margin-left: .5rem;
    margin-bottom: .25rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
    }
}
.product-teaser__product-badge {
    padding: .5rem;
}

.text-img-teaser__subtitle-margin,
.text-img-teaser__text-margin{
    margin-bottom: calc(15rem / 16);
}

.product-teaser__title-container{
    margin: calc(5rem / 16) 0;
}

.portal-section .product-teaser__title-container a:not(.btn),
.portal-section .product-teaser__title-container a:not(.btn):focus,
.portal-section .product-teaser__title-container a:not(.btn):hover{
    color:inherit;
}

.product-badge{
    display: inline-block;
    padding: calc(2rem / 16) calc(4rem / 16);
    font-size: calc(8rem / 16);
    @media (min-width: 400px) and (max-width: 767px) {
        font-size: calc(9rem / 16);
    }
    @media (min-width: 768px) {
        font-size: calc(10rem / 16);
        padding: calc(8rem / 16) calc(10rem / 16);
    }
}

.product-badge--small {
    @media (min-width: 768px) {
        padding: calc(4rem / 16) calc(6rem / 16);
    }
}


.product-badge.badge--red{
    background-color: #e23036;
}
.product-badge.badge--pink{
    background-color: #ec5f72;
}
.product-badge.badge-dark--red{
    background-color: var(--color-sale);
}
.product-badge.badge-dark--grey{
    background-color: #666666;
}
.product-badge.badge--grey{
    background-color: #909090;
}
.product-badge.badge--green{
    background-color: #259f82;
}
.product-badge.badge--olive{
    background-color: #306f26;
}
.product-badge.badge--outline{
    background-color: #ffffff;
    border: 1px solid #666666;
}
