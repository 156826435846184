.dealer-search {
    @media (min-width: 768px) {
        height: calc(700rem/16);
        height: 100vh;
        height: calc(100vh - calc(170rem/16));
    }
}

.dealer-search__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 768px) {
        margin-top: 1.5rem;
    }
}

.dealer-search__list {
    position: relative; /* important for scrolling with js*/
    flex: 1;
    margin-bottom: calc(20rem/16);
    overflow-y: auto;
}

.dealer-search__map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.dealer-search__map-wrapper {
    position: relative;
    height: 100%;
}

@media (max-width: 767px) {
    .dealer-search__map-wrapper {
        position: relative;
        overflow: hidden;
    }

    .dealer-search__map-wrapper:before {
        display: block;
        content: "";
        padding-top: 100%;
    }
}
