.blog-teaser {
    letter-spacing: calc(.5rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(24/16);
    }
}
.blog-teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(32/20);
    margin-top: calc(12rem / 16);
    margin-bottom: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        margin-top: calc(26rem / 16);
    }
}
.blog-teaser__img {
    transition: transform 250ms ease-in-out;
}
.blog-teaser:hover .blog-teaser__img {
    transform: scale(1.05);
}