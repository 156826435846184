.slide {
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(80rem/16);
        padding-bottom: calc(80rem/16);
    }
}

.slide--top-xl {
    @media screen and (min-width: 768px) {
        padding-top: calc(160rem/16);
    }
}

.slide--bottom-xl {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(160rem/16);
    }
}

.slide--top-sm {
    padding-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(40rem/16);
    }
}

.slide--bottom-sm {
    padding-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(40rem/16);
    }
}
.no-results__variant .slide,
.slide--top-none {
    padding-top:0;
    @media screen and (min-width: 768px) {
        padding-top:0;
    }
}

.slide--bottom-none {
    padding-bottom:0;
    @media screen and (min-width: 768px) {
        padding-bottom:0;
    }
}

.slide--xl {
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }
}

/*
.slide--bordered {
    border-top: calc(1rem / 16) solid var(--color-secondary);
    border-bottom: calc(1rem / 16) solid var(--color-secondary);
}
.slide--bordered,
.slide[class*='border-'] {
    margin: 0;
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin: 0;
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}

.slide--hr + .slide--hr {
    margin-top: 0;
    padding-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        padding-top: calc(40rem/16);
    }
    border-top: calc(1rem / 16) solid var(--color-secondary);
}*/

/* this should remove the space between this (last) element and the page-footer */
#main-content > .slide:last-child:not(.blog-related),
.pimcore_area_content:last-child .slide:not(.blog-related) {
    margin-bottom: calc(-40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-80rem/16);
    }
}