.navbar-brand {
    text-align:center;
    width:auto;
    @media (min-width: 768px) and (max-width: 1650px) {
        text-align:left;
        margin-bottom:.625rem;
    }
    @media screen and (max-width: 767px) {
        display:block;
        margin:0 auto;
        padding:0 0 .625rem 0;
    }
}
.navbar-brand__img {
    height: calc(32rem / 16);
    width: auto;
    max-width:100%;
    position:relative;
    @media screen and (max-width: 767px) {
        height: calc(22rem / 16);
    }
}
.navbar-brand__img--rollingsoft {
    height: calc(46rem / 16);
    @media screen and (max-width: 767px) {
        margin-top:-.5rem;
        height: calc(28rem / 16);
    }
}
.navbar-brand__img--pius {
    @media (min-width: 768px) {
        height: calc(26rem / 16);
        margin-top:-.5rem;
    }
    @media screen and (max-width: 767px) {
        height: calc(18rem / 16);
        margin-top:-.5rem;
    }
}
.navbar-top {
    padding: calc(12rem / 16) 0;
    line-height: calc(17/12);
    @media (min-width: 768px) and (max-width: 1650px) {
        margin-top:.625rem;
    }
}
@media (min-width: 768px) {
    .navbar-main {
        position: static;
    }
    .navbar-top__inner {
        min-height: calc(57rem/16);
    }
}

/*responsive cols*/
.navbar-top__nav-col {
    position: static;
}
@media (min-width: 768px) and (max-width: 1650px) {
    .navbar-top__brand-col {
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        order:1;
    }
    .navbar-top__nav-col {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        order:3;
    }
    .navbar-top__misc-col {
        justify-content: end;
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        order:2;
    }
}

.navbar-top__dropdown {
    position: relative;
    z-index: 15;
}
.navbar-top__dropdown__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(12rem/16) calc(15rem/16);
    font-size:calc(14rem / 16);
    border: 0;
    border-radius: 1rem;
    text-align: center;
    z-index: 1;
}
.navbar-top__dropdown.is-open .navbar-top__dropdown__link {
    background: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.navbar-top__dropdown__menu {
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    background: #fff;
    margin-top: calc(-1rem / 16);
    z-index: -1;
    word-break: normal;
    min-width: calc(400rem/16);
    border: 1px solid var(--color-light-grey);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
}
.navbar-top__dropdown.is-open .navbar-top__dropdown__menu {
    display: block;
}
.navbar-top__dropdown__menu-body-inner {
    max-height: calc(100vh - 18rem);
    overflow-y: auto;
    padding: calc(40rem / 16);
    margin-bottom:calc(100rem / 16);
}
.navbar-top__cart-btns {
    position: absolute;
    padding: calc(20rem / 16);
    left:0;
    right:0;
    bottom:0;
    background: #fff;
    box-shadow: 0 -5px 12px rgba(0, 0, 0, 0.05);
}
.navbar-top__dropdown__menu__item {
    font-size: calc(14rem/16);
    font-family: var(--font-default-semi-bold);
    margin-bottom: calc(8rem/16);
}
/*.navbar-top__dropdown__menu--lg {
    min-width: calc(544rem / 16);
    width: auto;
}*/

/* search input */
.navbar-top__search {
    position: relative;
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: 15rem;
        min-width: 10rem;
    }
}
.navbar-top__search__input.navbar-top__search__input {
    padding: .5rem 1.25rem .5rem 2.875rem;
    font-size: calc(12rem/16);
    height: auto;
    background: white;
    font-family: var(--font-default-semi-bold);
    border-radius: 1.875rem;
    border: 0;
}

.navbar-top__search__input.navbar-top__search__input:focus {
}

.navbar-top__search-btn.navbar-top__search-btn {
    position: absolute;
    height: 100%;
    width: calc(44rem/16);
    left: 0;
    top:.125rem;
    padding-left: calc(12rem/16);
    text-align: center;
    font-size: 1rem;
    z-index: 1;
}

.navbar-container.is-affix {
    background-color: var(--color-light-grey);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.magazine .navbar-container.is-affix {
    background-color: white;
}

.navbar-top__icon-text {
    border-radius: 50%;
    position: absolute;
    top:.875rem;
    right:.25rem;
    background:var(--color-text-default);
    color:#fff;
    width:.9375rem;
    height:.9375rem;
    line-height:.9375rem;
    text-align: center;
    font-family: var(--font-default-semi-bold);
    font-size: calc(10rem/16);
}
