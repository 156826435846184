.premium-icon {
    position: relative;
    display: inline-block;
    line-height: 0;
}
.premium-icon__points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: .4em;
    font-family: var(--font-default-bold);
}
.premium-icon__point-indicator {
    position: absolute;
    right: .1em;
    top: .1em;
    width: .2em;
    height: .2em;
    border-radius: 50%;
    background: linear-gradient(220.95deg, #AEAEAE 0%, #DFDFDF 11.63%, #F7F7F7 14.01%, #E6E6E6 39.16%, #949494 100%)
}