.wysiwyg-with-medium__additional-icon {
    flex: 0 0 calc(65rem/16);
}

.wysiwyg-with-medium__additional-icon img{
    max-height: 4rem;
}

.wysiwyg-with-picto__picto {
    width: 100%;
    max-width: 6rem;
    height: auto;
    @media (min-width: 768px) {

    }
}