.scroll-top{
    display: flex;
    position: fixed;
    right: var(--fixed-offset-right);
    top: 80%;
    cursor: pointer;
    z-index: 9;
    transform: rotate(270deg);
    -webkit-transform-origin: 50%  51%;
    opacity: 0;

    transition: opacity;
    transition-duration: 0.5s;
    scroll-behavior: smooth;
    pointer-events: none;
}

.scroll-top.scroll-top--show{
    opacity: 1;
    pointer-events: all;
}

.rotate-right{
    transform: rotate(90deg);
}

@media (max-width: 767px) {
    .scroll-top {
        top: auto;
        bottom:.5rem;
        right:.5rem;
    }

}