.small-prime-teaser {
    position: relative;
    border-radius:.25rem;
    background: #fff;
    padding:1.25rem;
    box-shadow: 0 10px 40px 0 rgba(34,34,34,0.2);
    width:auto;
    max-width:22rem;
    margin:0 auto;
    z-index:3;
}
.small-prime-teaser .progress-bar {
    background: var(--bg-standard);
}
.small-prime-teaser__points {
    font-size:calc(30rem / 16);
    font-family: var(--font-default-bold);
}
.small-prime-teaser__points-info {
    font-size:calc(12rem / 16);
}
.small-prime-teaser__icon {
    float:right;
    font-size: 1rem;
    background: var(--bg-standard);
    border-radius: 100%;
    width:1.875rem;
    height:1.875rem;
    line-height: 1.625rem;
    text-align: center;
}

.small-prime-teaser__id {
    display: flex;
    justify-content: space-between;
    padding:1rem 1.25rem 0 1.25rem;
    margin:1rem -1.25rem 0 -1.25rem;
    width: auto;
    font-size:calc(12rem / 16);
    border-top: 1px solid #E0E0E0;
}