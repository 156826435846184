.teaser-max {
    background: #fff;
}
.teaser-max__body {
    position: relative;
    height: 100%;
    padding: 1.5rem;
    @media (min-width: 768px) {
        padding: 5rem;
    }
}
.teaser-max__body--logo.teaser-max__body--logo {
    padding: 5rem 0;
    text-align: center;
    @media (min-width: 768px) {
        padding: 2rem 0;
    }
}
.teaser-max__logo {
    max-width: 70%;
    margin: 0 auto;
}
.teaser-max__txt {
    margin:1rem 0;
    line-height:2;
}
.teaser-max__link a:not(.btn) {
    color:inherit;
    border-bottom:1px solid;
}