.vertical-teaser {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.vertical-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color:#fff;
    padding:1.25rem;

    @media (min-width: 768px) {
        min-height:calc(300rem / 16);
        padding:calc(32rem / 16) calc(20rem / 16);
        flex-grow: 1;
    }
}

/* image only */
.vertical-image-teaser {
    position: relative;
}
.vertical-image-teaser__title {
    text-shadow: 0 0 15px rgba(0,0,0,.35);
}
.vertical-image-teaser__cta-body,
.vertical-image-teaser__top-body {
    position: absolute;
    left:0;
    top:1rem;
    right:0;
    padding:1.25rem;
}
.vertical-image-teaser__cta-body {
    top:auto;
    bottom:1rem;
}
.vertical-image-teaser__cta-body--middle {
    top:50%;
    transform: translateY(-50%);
    bottom:auto;
}