.nav-tabs--underline {
    @media screen and (max-width: 767px) {
        overflow-x: auto;
        flex-wrap: nowrap;
        white-space: nowrap;
        padding-bottom: 1px;
    }
}
.nav-tabs--underline .nav-link {
    border-width: 0;
    border-bottom-width: 2px;
    padding: 0 0 .25rem 0;
    font-family: var(--font-default-bold);
    font-size: .875rem;
    color: var(--color-dark-grey);
    margin-left:1rem;
    margin-right:1rem;

    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
        margin-right:2.25rem;
    }
}

.nav-tabs--underline .nav-link:first-child {
    margin-left: 0;
}
.nav-tabs--underline .nav-link:last-child {
    margin-left: 0;
}

/*.nav-tabs--underline .nav-link:last-child {
    margin-right: 2rem;
}*/

.nav-tabs--underline .nav-item.show .nav-link,
.nav-tabs--underline .nav-link.active {
    border-color: transparent;
    border-bottom-color: var(--color-primary);
    color: var(--color-primary);

}