.redesign-classic-teaser {
    position: relative;
    color:var(--color-redesign-primary-contrast);
}

.redesign-classic-teaser__badge {
    position:absolute;
    right:.625rem;
    top:.625rem;
    display: block;
    width: auto;
    height: auto;
    line-height: 1;
    font-size:calc(10rem / 16);
    color:var(--color-text-default);
    text-transform: uppercase;
    padding:calc(10rem / 16);
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
    z-index:2;
}

.redesign-classic-teaser__img {
    margin-bottom:1.5rem;
    @media screen and (max-width: 767px){
        margin-bottom:1rem;
    }
}
.redesign-classic-teaser__title {
    font-size: 1.25rem;
    font-family: var(--font-default-bold);
    margin-bottom:.625rem;
    @media screen and (max-width: 767px){
        font-size: 1.125rem;
        margin-bottom:.625rem;
    }
}
.redesign-classic-teaser__txt {
    font-size:1rem;
    line-height:1.6;
    margin-bottom:1.125rem;
    @media screen and (max-width: 767px){
        font-size: .875rem;
        margin-bottom:.75rem;
    }
}
.redesign-classic-teaser__link {
    font-size:1rem;
    padding-bottom:.125rem;
    font-family: var(--font-default-bold);
    border-bottom:.125rem solid var(--color-redesign-primary-contrast);
    @media screen and (max-width: 767px){
        font-size: .875rem;
    }
}
.redesign-classic-teaser__link:hover,
.redesign-classic-teaser__link:focus {
    border-bottom:.125rem solid transparent;
}