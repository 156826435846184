.cart-payment__img {
    height: calc(18rem / 16);
    width: auto;
}

.cart-payment__img--large {
    height: calc(32rem / 16);
}

/* empty wishlist */
.cart-empty {
    padding:1.25rem;
    text-align: center;
    background:#fff;
    @media screen and (min-width: 768px){
        padding:2.5rem;
    }
}
.cart-empty__headline {
    font-family: var(--font-default-bold);
    margin:0;
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
}

/* cart info */
.cart-info {
    padding:2rem;
    text-align: center;
    border:1px solid #FFA100;
    background:#FFECCC;
    font-size: calc(14rem / 16);
}
.cart-info__close {
    padding: 1rem .5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    appearance: none;
    border:0;
    background:transparent;
    color:#000;
    opacity:.5;
    font-size: 1.5rem;
    line-height:1px;
    @media screen and (min-width: 768px){

    }
}
.cart-info__close:hover,
.cart-info__close:focus {
    opacity: 1;
}
.cart-info__headline {
    display: inline-block;
    margin:0 auto;
    position: relative;
    padding-left:1.75rem;
    font-family:var(--font-default-semi-bold);
    @media screen and (min-width: 768px){
        font-size: calc(17rem / 16);
    }
}
.cart-info__headline .icon {
    position:absolute;
    left:0;
    top: .125rem;
}
.cart-info.cart-info--sticky-pds {
    display: none;
    position: fixed;
    bottom:1rem;
    right:1rem;
    left:1rem;
    z-index: 1010;
    padding:1rem;
    margin:0;
    @media screen and (min-width: 768px){
        width: 22.5rem;
        left:auto;
        margin:0;
    }
}
.cart-info--sticky-pds .cart-info__headline {
    padding-left:1.375rem;
    font-size: calc(15rem / 16);
}



.cart-info,
.cart-empty {
    margin-bottom: calc(10rem/16);
    @media (min-width: 768px) {
        margin-bottom: calc(32rem/16);
    }
}
