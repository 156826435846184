.modal__close.modal__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 10;
}

@media (max-width: 767px) {
    body.modal-open {
        overflow: auto;
    }
    .modal-header {
        padding: 1.5rem;
    }
    .modal-body {
        padding: 0 1.5rem 1.5rem;
    }
    .modal-title {
        padding-right:2rem;
    }
}

.modal-body + .modal-body {
    border-top: 2px solid var(--color-light-grey);
}
