.teaser-wide {
    background: #fff;
}

.teaser-wide__body {

    padding: 1.5rem;

    @media screen and (min-width: 768px) {
        padding: 1.5rem 2.5rem;
        font-size: 1rem;
        font-size: clamp(.75rem, 1.3vw, 1rem);
    }
    @media screen and (min-width: 1200px) {
        padding: 1.5rem calc(55rem/16);
    }
}

.teaser-wide__txt {
    margin:1rem 0;
    line-height:2;
}

.teaser-wide__link a {
    color:inherit;
    border-bottom:1px solid;
}

.teaser-wide__logo {
    max-width: 70%;
}

.teaser-wide__title {
    font-size: 1.875em;
}