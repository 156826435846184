.promotion-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
    min-height:calc(400rem/16);

    @media screen and (max-width: 767px){
        min-height:calc(300rem/16);
    }
}
.promotion-teaser__img {
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    object-fit: cover;
    @media screen and (max-width: 767px){
        bottom: auto;
        right:auto;
        left: 50%;
        top: 50%;
        width:50vw;
        transform: translateX(-50%) translateY(-50%);
        height: auto;
    }
}
/*.promotion-teaser:before {
    z-index:1;
    content:'';
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
}*/
.promotion-teaser__topline {
    margin-bottom:.125rem;
    text-transform: uppercase;
}
.promotion-teaser__detail {
    text-align: center;
    color:#fff;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    padding: calc(20rem/16);
    z-index:2;

    @media (max-width: 767px) {
        padding: calc(12rem/16);
    }
    @media (min-width: 1200px) {
        padding: calc(20rem/16);
    }
    @media (min-width: 1600px) {
        padding: calc(30rem/16);
    }
}
.promotion-teaser__link {
    margin-top:1.25rem;
}
.promotion-teaser__link .btn {
    @media (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(13rem/16);
    }
}