.portal-features {
    @media screen and (min-width: 768px){
        padding-top:1rem;
    }
}

.portal-features-teaser {
    position: relative;
    background:#fff;
    height:100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 1.875rem;
    @media screen and (min-width: 768px){
        padding:3.5rem 2rem;
    }
}
.portal-features-teaser__body {
    text-align: center;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
.portal-features-teaser__title {
    margin:1.5rem 0 .625rem 0;
    font-family: var(--font-default-bold);
    font-size:1.125rem;
    @media screen and (min-width: 768px){
        font-size:1.25rem;
    }
}
.portal-features-teaser__img {
    display: block;
    margin: 0 auto;
    width:auto;
    height:calc(105rem / 16);
}
.portal-features-teaser__txt {
    margin-bottom:.75rem;
}
.portal-features-teaser__link {
    font-size:1rem;
    padding-bottom:.125rem;
    font-family: var(--font-default-bold);
    border-bottom:.125rem solid var(--color-primary);
    @media screen and (max-width: 767px){
        font-size: .875rem;
    }
}
.portal-features-teaser__link:hover,
.portal-features-teaser__link:focus {
    border-bottom:.125rem solid transparent;
}