.account-icon-button {
    /*todo hover*/
    display: block;
    text-align: center;
    color: var(--color-primary);
}
.account-icon-button__icon-wrapper {
    display: inline-block;
    position: relative;
    padding: .5rem;
}
.account-icon-button__icon {
    font-size: 1.75rem;

    @media screen and (min-width: 768px) {
        font-size: 2.5rem;
    }
}
.account-icon-button__bubble {
    position: absolute;
    top: -0.3rem;
    right: -0.7rem;
    border-radius: 50%;
    min-width: 1rem;
    height: 1rem;
    background-color: var(--color-turquoise);
    color: #fff;
    font-size: .625rem;
    line-height: 1rem;
    font-family: var(--font-default-bold);
    padding-left: .25em;
    padding-right: .25em;
}
.account-icon-button__title {
    font-family: var(--font-default-bold);
    line-height: 1.125rem;
}
.account-icon-button__title--has-info {
    max-width: 10rem;
    display: inline-block;

    @media screen and (max-width: 767px) {
        max-width: 12.5rem;
    }
}
.account-icon-button__subline {
    font-size: 12px;
    margin-top:.75rem;
}
.account-icon-button:hover .account-icon-button__title,
.account-icon-button:focus .account-icon-button__title,
.account-icon-button:active .account-icon-button__title {
    text-decoration: underline;
}
.account-icon-button:hover .account-icon-button__icon,
.account-icon-button:focus .account-icon-button__icon,
.account-icon-button:active .account-icon-button__icon {
    animation-name: account-icon-button-wiggle;
    animation-duration: 450ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@keyframes account-icon-button-wiggle {
    0% {transform: rotate(4deg);}
    33% {transform: rotate(-4deg);}
    66% {transform: rotate(3deg);}
    100% {transform: rotate(0deg);}
}
