.order-tabs{
    border-bottom: 1px solid var(--color-primary);
    padding-top: calc(15rem / 16);
    margin-bottom: calc(15rem / 16);

    font-size: calc(14rem / 16);
}

.order-tabs li:hover,
.order-tabs li.active{
    border-bottom: 1px solid var(--color-primary);
}
.order-tabs li.active{
    font-family: var(--font-default-bold);
}

.order-print{
    margin-top: calc(15rem / 16);
    margin-bottom: calc(30rem / 16);
    font-size: calc(14rem / 16);
}

.all-order-print{
    margin-top: calc(10rem / 16);
    margin-bottom: calc(15rem / 16);
    font-size: calc(14rem / 16);
}

.refund-heading{
    padding-left: calc(12rem / 16);
    @media (max-width: 767px) {
        padding-left: calc(8rem/16);
    }
}

.block-title.block-title--order-info{
    margin-bottom: calc(22rem / 16);
}

.order-info{
    font-size: calc(20rem / 16);
}

.user-info{
    font-size: calc(15rem / 16);
}

.invoice-number{
    @media screen and (min-width: 768px){
        margin-left: calc(15rem / 16);
        margin-top: calc(15rem / 16);
    }
}

.invoice-number > div{
    margin-bottom: calc(10rem / 16);
}

.print-info{
    font-size: calc(12rem / 16);
}

.delivery-state{
    margin-bottom: calc(15rem / 16);
}


.order-modal-list {
}

.order-modal-list__item {
    position: relative;
}

.order-modal-list__item {
    @media screen and (max-width: 767px){
        margin-bottom: calc(32rem/16);
        padding-bottom:calc(40rem/16);
    }
}

.order-modal-list__item + .order-modal-list__item {
    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }
}
@media screen and (max-width: 767px){
    .order-modal-list__item:before {
        content:'';
        position: absolute;
        bottom:0;
        left:-1.5rem;
        right:-1.5rem;
        width: auto;
        border-bottom:1px solid rgba(0,0,0,.25);
    }
}

/* table styling */
.table.table--refund{
    font-size: calc(14rem / 16);
}
.table.table--refund td,
.table.table--refund th {
    border: none;
    font-family: var(--font-default);
}
.table.table--refund thead td,
.table.table--refund thead th {
    font-family: var(--font-default-bold);
}
.table.table--refund thead td{
    border-top: none;
    border-bottom-color: var(--color-primary);
}
.table.table--refund td {
    border: none;
}
.table.table--refund tfoot tr:first-child td,
.table.table--refund tfoot tr:first-child th {
    border-top: 1px solid var(--color-primary);
}
@media (min-width: 768px) {
    .table.table--refund tbody tr:first-child td {
        border-top: 1px solid var(--color-primary);
    }
}
@media (max-width: 767px) {
    .table.table--refund tbody td:first-child {
        border-top: 1px solid var(--color-primary);
    }
}

.table.table--refund tfoot td,
.table.table--refund tfoot th {
    padding-top: calc(5rem / 16);
    padding-bottom: calc(5rem / 16);
}
.table.table--refund tfoot tr:first-of-type td,
.table.table--refund tfoot tr:first-of-type th {
    padding-top: calc(15rem / 16);
}
.table.table--refund tfoot tr:not(:first-of-type) td{
    border-top: none;
}
.table.table--refund .table--refund__sum{
    padding-left: calc(5rem / 16);
    padding-right: calc(5rem / 16);
}
@media (max-width: 767px) {
    .table-mobile-blocks .table--refund__footer  tr{
        display: flex;
    }

    .table-mobile-blocks .table--refund__sum {
        flex: 1;
    }

}

@media (min-width: 768px) {
    .table--refund__subtotal{
        font-size: calc(17rem / 16);
        line-height: 1;
    }
}

.table--tracking td{
    border-color: var(--color-secondary);
    padding-top: calc(7rem / 16);
    padding-bottom: calc(7rem / 16);
}

.return-order-heading{
    margin-bottom: calc(10rem / 16);
}

@media (max-width: 767px) {
    .table.table--tracking td {
        border: none;
    }

    .table.table--tracking tbody td:first-child {
        border-top: 1px solid var(--color-secondary);
    }
}
