.adyen-checkout__payment-method.adyen-checkout__payment-method {
    border-radius: 0;
    border:0;
    background: #fff;
    max-height: none;
    margin:.5rem 0;
}
.adyen-checkout__payment-method__header.adyen-checkout__payment-method__header {
    display: block !important;
    padding: calc(15rem / 16);
    padding-right: calc(20rem/16);
    padding-left: calc(50rem/16);
    @media (min-width: 768px) {
        padding: calc(25rem / 16);
        padding-left: calc(60rem/16);
    }
}
.adyen-checkout__payment-method__brands {
    margin:0;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right: calc(20rem/16);
    height:1.5rem;
    @media (min-width: 768px) {
        right: calc(25rem / 16);
    }
}
.adyen-checkout__payment-method__brands img,
.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
    height: 1.5rem;
    width: 2.25rem;
}
.adyen-checkout__payment-method__header__title {
    padding:0;
    outline: 0 !important;
}
.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__radio {
    display: block; /* show radio also, when standalone */
}
.adyen-checkout__payment-method__radio {
    border: 1px solid var(--color-primary);
    margin-top: -.1rem;
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    top: calc(16rem/16);
    left: calc(14rem/16);
    background: #ffffff;

    @media screen and (min-width: 768px) {
        margin-top:.1rem;
        width:1.25rem;
        height:1.25rem;
        top: calc(27rem/16);
        left: calc(24rem/16);
    }
}

.adyen-checkout__card__brands img {
    border-radius: 0;
    top: -1px;
    position: relative;
    height: 1.5rem;
    width: 2.25rem;
}
.adyen-checkout__card__brands {
    height:1.5rem;
    margin-bottom: 1.5rem;
    margin-top: -.75rem;
}
.adyen-checkout__card__brands__brand-wrapper {
    height: 1.5rem;
    width: 2.25rem;
}
.adyen-checkout__payment-method__radio--selected {
    border-color: var(--color-primary);
    border-width:2px;
}
.adyen-checkout__payment-method__radio--selected:after {
    width: calc(6rem/16);
    height: calc(6rem/16);
    border-radius: 50%;
    background: var(--color-primary);
}
.adyen-checkout__payment-method__details {
    padding:0 calc(20rem/16) 1px calc(50rem/16);
    @media (min-width: 768px) {
        padding:0 calc(25rem/16) 1px calc(60rem/16);
    }
}

.adyen-checkout__status {
    border-radius: 0;
    border:0;
    margin-top:.5rem;
}