.custom-radio {
    position: relative;
    padding-left: 2.875rem;

    @media screen and (min-width: 768px) {
        padding-left:2rem;
    }

    @media screen and (max-width: 767px) {
        margin:.5rem .75rem .25rem 0;
    }

}
.custom-radio--inline {
    display: inline-block;
    margin-right: 1.5rem;
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem / 16);
    overflow: hidden;
    border: 1px solid var(--color-primary);
    background: #ffffff;
    margin-top: -.1rem;
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;

    @media screen and (min-width: 768px) {
        margin-top:.1rem;
        width:1.25rem;
        height:1.25rem;
    }

}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}

.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box,
.custom-radio__input:checked ~ .custom-radio__box {
    border-color: var(--color-primary);
    border-width:2px;
}
/*.has-error .custom-radio__box {
    border-color: var(--color-danger);
}*/
/*.has-error .custom-radio__input:focus ~ .custom-radio__box{
    border-color: var(--color-danger);
}*/
.custom-radio__text {
    display: block;
    overflow: hidden;
}
/* increased specificity to make sure validated disabled inputs look disabled */
.custom-radio__input:disabled ~ .custom-radio__text.custom-radio__text {
    color: var(--color-medium-grey);
}

/* checkbox sizes */
.custom-radio--sm {
    font-size: calc(11rem / 16);
    letter-spacing: calc(1.2rem / 16);
    text-transform: uppercase;
}
.custom-radio--sm + .custom-radio--sm {
    margin-top: calc(2rem / 16);
}

/* text only */
.custom-radio--text-only {
    padding-left: 0;
}
.custom-radio--text-only .custom-radio__box {
    visibility: hidden;
}

.custom-radio.custom-radio--vertical {
    padding-left: 0;
}

.custom-radio--vertical label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-radio--vertical .custom-radio__box {
    position: relative;
}