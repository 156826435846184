.content-teaser {
    letter-spacing: calc(.5rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(24/16);
    }
}
.content-teaser__body {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }
}
.content-teaser__body__title {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.content-teaser__img {
    transition: transform 250ms ease-in-out;
}
.content-teaser:hover .content-teaser__img {
    transform: scale(1.05);
}