:root {
    --color-primary: #222222;
    --color-secondary: #ececec;
    --color-text-default: #222222; /* war #444 */
    --color-text-muted: #575757;
    --color-redesign-primary:#EADBD5;
    --color-redesign-primary-contrast:var(--color-text-default);

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #F5F5F5;
    --color-medium-grey: #CCCCCC;
    --color-dark-grey: #999999;
    --color-dark: #343A40;
    --color-turquoise: #7BC5C3;

    --color-gabor: var(--color-success);
    --color-success: #53cbab;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-sale: #A83847;
    --color-danger: var(--color-sale);

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #000;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #000000;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --bg-prime-bronze: linear-gradient(217.61deg, #EADDD7 0%, #F6EFEC 21.09%, #FAF6F4 28.77%, #F5EEEB 41.24%, #EADBD5 100%);
    --bg-prime-silver: linear-gradient(214.53deg, #C0C0C0 0%, #F1F1F1 53.25%, #C0C0C0 100%);
    --bg-prime-gold: linear-gradient(204.45deg, #ECC677 0%, #FFE8B8 40.21%, #ECC576 100%);
    --bg-standard: #e8e8e8;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Inter Regular", sans-serif;
    --font-default-semi-bold: "Inter SemiBold", sans-serif;
    --font-default-bold: "Inter Bold", sans-serif;

    --font-size-default: 1rem;

    /* header */
    --header-height: calc(122rem/16);
    --header-height-md: calc(188rem/16);
    --header-height-xs: calc(146rem/16);

    /* slider vars */
    --slider-dot-background: var(--color-medium-grey);
    --slider-dot-active-background: var(--color-text-default);
    --slider-dot-size: calc(8rem/16);

    --fixed-offset-right: 0;
}
