.cart-checkout-list {
    @media (max-width: 767px) {
        margin-bottom: 2rem;
    }
}

.cart-item {
    position: relative;
}

.cart-item__body {
    padding: calc(20rem/16);
    @media (min-width: 1200px) {
        padding: calc(30rem/16);
    }
}

.cart-item + .cart-item {
    margin-top: calc(10rem/16);
    @media (min-width: 768px) {
        margin-top: calc(32rem/16);
    }
}

.cart-item--count-1 .cart-item__price {
    @media (min-width: 768px) {
        opacity:0;
    }
}

@media (max-width: 767px) {
    .cart-item--count-1 .cart-item__price {
        display:none;
    }
    .cart-item--count-1 .cart-item__total-price {
        margin-left:33.3333%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
}

.cart-item__title {
    font-size: calc(16rem/16);
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.cart-item--overlay .cart-item__title {
    padding-right: calc(32rem/16);
}

.cart-item__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: calc(20rem/16);
}

.cart-item--overlay .cart-item__delete {
    bottom:auto;
    top:.375rem;
}

.cart-items-header {
    padding: 0 calc(40rem/16);
}

.cart-items-header__first-heading {
    margin-left: calc(-40rem/16);
}