.pds-wrapper {
    @media (max-width: 767px) {
        background: #fff;
    }
}

.shoe-size .shoe-size__item:not(.shoe-size__item--disabled){
    cursor: pointer;
}
.shoe-size .shoe-size__item:not(.shoe-size__item--disabled):hover:after,
.shoe-size .shoe-size__item.active:after{
    transition: .5s;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: -5px;
    left: 0;
}
.shoe-size__item--disabled {
    opacity: .5;
}


/* Sliders */
.product-detail-color-slider-container {
    @media (max-width: 767px) {
        text-align: center;
    }
}
.product-detail-slider__main {
    background: white;
    margin-bottom: 0;
    @media (min-width: 768px) {
        margin-bottom: 1rem;
    }
}
.slick-initialized.product-detail-slider__main {
    padding-bottom: 0;
}
.slider.product-detail-slider__main .slick-arrow {
    left: .875rem;
}
.slider.product-detail-slider__main .slick-arrow.slick-next {
    left: auto;
    right:.875rem;
}
.slider.product-detail-slider__main .slick-dots {
    position: static;
    margin-top: .5rem;
}

@media (min-width: 768px) {
    .product-detail-slider__main__item {
        padding: 0 calc(48rem/16);
    }
}

.slider.product-detail-slider__main .slick-dots li button {
    text-indent: -9999em;
}
.product-detail-slider__main__color-placeholder {
    position: absolute;
    left:3rem;
    right:3rem;
    top:0;
    bottom:calc(6rem / 16);
    background: #fff;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity .1s ease-in-out;
}
.product-detail-slider__main__color-placeholder.active {
    opacity:1;
}

.product-detail-slider__small-360{
    width: calc(90rem / 16);
    height: calc(90rem / 16);
    font-size: calc(46rem/16);
}
.pds-color-select {
    position: relative;
}
.product-detail-color-slider {
    line-height: 1px;
    position: relative;
}
.product-detail-color-slider:not(.slick-initialized) {
    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }
    @media (max-width: 767px) {
        display: inline-block;
        margin: 0 auto !important;
        width: auto;
    }
}
.product-detail-color-slider:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    border:.125rem solid var(--color-light-grey);
    @media (max-width: 767px) {
        right:-.125rem;
    }
}
.product-detail-color-slider .slick-arrow {
    left: -2.125rem;
    color:var(--color-text-default);
    @media (max-width: 767px) {
        background: transparent;
        border-color: transparent;
        left: -1.125rem;
        justify-content: flex-start;
    }
}
.product-detail-color-slider .slick-arrow.slick-disabled {
    opacity: 0;
    pointer-events: initial;
}
.product-detail-color-slider .slick-arrow.slick-next {
    left: auto;
    right:-2.125rem;
    @media (max-width: 767px) {
        right:-1.125rem;
        justify-content: flex-end;
    }
}
.pds-color-select__lbl {
    background: #fff;
    line-height:1.25;
    @media (max-width: 767px) {
        font-size:.75rem;
    }
}
.pds-color-select__lbl,
.pds-color-select__right-placeholder {
    width: 6.125rem;
}
.pds-color-select .pds-color-select__item {
    position: relative;
    display: block;
    max-width: 100%;
    padding:0 .25rem;
    border: .125rem solid transparent;
    margin:0;
    width: 70px;

    @media screen and (min-width: 768px) {
        width: 80px;
    }
}
.product-detail-color-slider:not(.slick-initialized) .pds-color-select__item{
    @media (max-width: 767px) {
        float:left;
    }
}
.pds-color-select .pds-color-select__item:before {
    @media screen and (max-width: 991px) {
        content:'';
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background:#fff;
    }
}

.product-detail-color-slider:not(.slick-initialized) {
    height: 62px;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        height: 72px;
    }
}

@media (min-width: 768px) {
    .pds-color-select .pds-color-select__item:hover,
    .pds-color-select .pds-color-select__item:focus {
        outline: .125rem solid var(--color-light-grey);
    }
}
.pds-color-select .pds-color-select__item.active {
    border: .125rem solid var(--color-text-default);
    position: relative;
    z-index: 1;
}

.shipping-time--w-dot {
    position: relative;
    display: inline-block;
    padding-left:1.25rem;
}
.shipping-time--w-dot:before {
    content:'';
    border-radius: 100%;
    display: block;
    width:.75rem;
    height:.75rem;
    background:var(--color-gabor);
    position: absolute;
    left:0;
    top:.125rem;
}

/*size tabs*/
.nav-tabs--shoe-size.nav-tabs .nav-link{
    border: 0;
    padding: 0;
    font-size:calc(14rem/16);
    border-bottom: 2px solid transparent;
    margin: calc(3rem / 16) calc(6rem / 16);
}
.nav-tabs--shoe-size.nav-tabs .nav-link:hover{
    border-bottom: 2px solid var(--color-primary);
}
.nav-tabs--shoe-size .nav-item.nav-link.active{
    border-bottom: 2px solid var(--color-primary);
    color: var(--color-primary);
}


/*colors*/
.pds-colors-sidebar {
    width: 100%;
    margin:.375rem 0 1.875rem 1rem;
    max-width:calc(400rem / 16);
    @media (max-width: 767px) {
        max-width:calc(320rem / 16);
    }
}

/*size select*/
.pds-selection {
    width: 100%;
    max-width:calc(400rem / 16);
}
.pds-selection--full {
    width: 100%;
    max-width:none;
}
.pds-selection__btn {
    width: 100%;
    background: transparent;
    text-align: left;
    font-family: var(--font-default-bold);
    font-size: .875rem;
    color: var(--color-text-default);
    padding:.75rem 1rem;
    border: 2px solid var(--color-light-grey);
    -webkit-appearance: none;
}
.pds-selection .pds-selection__btn,
.pds-selection .btn {
    height:100%;
}

@media (max-width: 767px) {
    html:lang(nl) .pds-selection__btn {
        font-size: calc(13rem/16);
    }
}
.pds-selection__btn--sticky-sizes {
    padding:1.1875rem 1rem;
}
.pds-selection__btn--sticky-colors {
    padding:0 1rem;
}
.pds-selection__btn span,
.pds-selection__btn .pds-selection__btn-lbl,
.pds-selection__btn--sticky-sizes span,
.pds-selection__btn--sticky-colors span,
.pds-selection__btn--sticky-colors .pds-selection__sticky-color-img {
    pointer-events: none; /* fix for gtm tracking */
}
.pds-selection__sticky-color-img {
    height:calc(59rem / 16);
    width:auto;
}
.pds-selection__btn-lbl {
    font-size: .625rem;
    font-family: var(--font-default);
    color:var(--color-dark-grey);
}


/*characteristics*/
.characteristics{
    margin: 0 calc(15rem / 16) calc(10rem / 16) 0;
}
.characteristics:last-child{
    margin-right: 0;
}
.pds-characteristics {
    display: flex;
    align-items: center;
    font-family: var(--font-default-bold);
}
.pds-characteristics__img {
    width:calc(70rem / 16);
    height: auto;
    max-height:calc(70rem / 16);
    display: block;
    margin-right:.75rem;
}


/* details */
.product-info-detail{
    background:#fff;
    @media (min-width: 768px) {
        padding: calc(40rem/16);
    }
}
.product-info-detail .slide.slide{
    /* dirty reset */
    padding:0 0 1rem 0;
    margin: .75rem 0 0 0 !important;

    @media (min-width: 768px) {
        margin-top: 2rem!important;
    }
}
.product-detail-main-info {
    @media (min-width: 768px) {
        padding: calc(40rem/16);
        background:#fff;
    }
}

@media (min-width: 1200px) {
    .product-detail-main-info__right-col {
        max-width: calc(550rem/16);
    }
}
.product-detail-main-info__title {
    margin-top: .5rem;
    margin-bottom: .5rem;
    @media screen and (min-width: 768px) {
        margin-top: 1rem;
    }
}
.product-detail-main-info__sub-title {
    margin-bottom: .5rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
    }
}


.product-info{
    @media (max-width: 767px) {
        color:var(--color-text-default);
        font-size: calc(13rem / 16);
    }
}
.product-info__col {
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    .is-showroom .product-info__col {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .product-info__td,
    .product-info__th{
        position: relative;
        padding-left:1.625rem;
    }
    .product-info__td:before,
    .product-info__th:before{
        content:'';
        position: absolute;
        left:0;
        top:.75rem;
        display: inline-block;
        width:calc(10rem / 16);
        height:calc(2rem / 16);
        background:var(--color-text-default);
    }
    .product-info__td:before{
        opacity: 0;
    }
}
.product-info__th{
    font-family: var(--font-default-bold);
}



.modal-btn.modal-btn--spacing{
    padding-top: calc(40rem / 16);
}

.product-teaser .product-badge{
    max-width: 100%;
    white-space: nowrap;
    @media (min-width: 768px) {
        border:1px solid;
        margin-bottom: .25rem;
    }
}

.pds-payment__img {
    height: calc(22rem / 16);
    width: auto;
}

.pds-payment__img--large {
    height: calc(38rem / 16);
}


/*sticky bar*/
.pds-sticky-bar {
    background: #fff;
    position: fixed;
    right: var(--fixed-offset-right);
    left: 0;
    bottom: 0;
    z-index: 100;
    transform: translateY(100%);
    transition: transform .2s;
    @media (min-width: 768px) {
        padding:1.125rem 1.125rem 0 1.125rem;
        height:calc(100rem / 16);
    }
}
.pds-sticky-bar.show {
    transform: translateY(0);
    z-index: 10;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
    .pds-sticky-bar__col-price {
        text-align: center;
    }
}
.color-overlay-item {
    display: block;
    padding:.5rem;
    border:2px solid var(--color-light-grey);
}
.color-overlay-item--active {
    border:2px solid var(--color-text-default);
}


.size-advisor-size {
    display: flex;
    align-items: center;
    font-size: .75rem;
    font-family: var(--font-default-bold);
}
.size-advisor-size > .icon {
    font-size:1.125rem;
    position: relative;
    margin-right:.5rem;
}


/*additional-info*/
.pds-additional-info {
    position: relative;
    padding:0 0 0 2.5rem;
    font-size: calc(12rem / 16);
}
.pds-additional-info a {
    text-decoration: underline;
}
.pds-additional-info a:hover,
.pds-additional-info a:focus {
    text-decoration: none;
}
.pds-additional-info__img {
    width:1.875rem;
    height: auto;
    display: block;
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
}


/*PDS Detail Tabs*/
:root{
    --pbs-nav-tabs-gutter: .75rem;
    --pbs-nav-tabs-gutter-lg: 1rem;
}
.pds-nav-tabs-wrapper {
    position: relative;
    padding:0;
    overflow-x: hidden;
    margin:0;

    @media (max-width: 767px) {
        margin:0 -1rem;
    }
}
.pds-nav-tabs {
    position: relative;
    border-bottom: .125rem solid var(--color-light-grey);

    flex-wrap: nowrap;
    word-break: keep-all;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        margin-left: calc(var(--pbs-nav-tabs-gutter) * -1);
        margin-right: calc(var(--pbs-nav-tabs-gutter) * -1);
    }
    @media (max-width: 767px) {
        display:block;
        width: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin:0;
        padding: 0 1rem;
        -webkit-overflow-scrolling: touch;
    }
    @media (min-width: 1100px) {
        margin-left: calc(var(--pbs-nav-tabs-gutter-lg) * -1);
        margin-right: calc(var(--pbs-nav-tabs-gutter-lg) * -1);
    }
}
.pds-nav-tabs--dark-border {
    border-color:var(--color-medium-grey);
}
@media (max-width: 767px) {
    .pds-nav-tabs::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .pds-nav-tabs::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
        background-color:transparent;
    }
    .pds-nav-tabs::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        border-radius: .25rem;
    }
}
.pds-nav-tabs .nav-item {
    flex: 1 1 auto;
    margin-bottom: -.125rem;
    z-index:1;
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        margin-right:1.25rem;
        display:inline-block;
    }

    @media (min-width: 768px) {
        padding-left: var(--pbs-nav-tabs-gutter);
        padding-right: var(--pbs-nav-tabs-gutter);
    }

    @media (min-width: 1100px) {
        padding-left: var(--pbs-nav-tabs-gutter-lg);
        padding-right: var(--pbs-nav-tabs-gutter-lg);
    }
}
@media (min-width: 768px) {
    .pds-nav-tabs .nav-item:first-child:nth-last-child(2),
    .pds-nav-tabs .nav-item:first-child:nth-last-child(2) ~ .nav-item {
        /*if the are exactly two tabs split the half half*/
        flex-basis: 50%;
    }

    .pds-nav-tabs .nav-item + .nav-item ~ .nav-item:last-child {
        /* align the last element only if there are at least 3 items*/
        flex-grow: 0;
    }
}
.pds-nav-tabs .nav-link {
    position: relative;
    padding:0 0 .25rem 0;
    font-size:calc(14rem / 16);
    font-family: var(--font-default-bold);
    color:var(--color-dark-grey);
    border:0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @media (min-width: 1100px) {
        padding:0 0 1.25rem 0;
        font-size:calc(15rem / 16);
    }
    @media (min-width: 1300px) {
        font-size:1.125rem;
    }
}
.pds-nav-tabs .nav-item:nth-last-child(n+5):first-child .nav-link,
.pds-nav-tabs .nav-item:nth-last-child(n+5):first-child ~ .nav-item .nav-link {
    /* if there are more than 5 elements use a smaller font size*/
    @media (min-width: 768px) {
        font-size: .75rem;
    }

    @media (min-width: 1100px) {
        font-size: .875rem;
    }

    @media (min-width: 1300px) {
        font-size:1rem;
    }
}

.pds-nav-tabs .nav-link:hover, .pds-nav-tabs .nav-link:focus,
.pds-nav-tabs .nav-item.show .nav-link, .pds-nav-tabs .nav-link.active {
    color:var(--color-text-default);
}
@media (min-width: 768px) {
    .pds-nav-tabs .nav-link:hover:after, .pds-nav-tabs .nav-link:focus:after,
    .pds-nav-tabs .nav-item.show .nav-link:after, .pds-nav-tabs .nav-link.active:after {
        content:'';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height:.125rem;
        background:var(--color-text-default);
    }
}


/* jumpnav */
.pds-jumpnav {
    position: relative;
}
.pds-jumpnav__title {
    font-size: .75rem;
    font-family: var(--font-default-semi-bold);
    @media (min-width: 768px) {
        font-size: .875rem;
    }
}
.pds-jumpnav__icon {
    display: none;
    position: relative;
    vertical-align: -.2em;
    font-size: 1.125rem;
    margin-right: .5rem;
    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
}
.pds-jumpnav__item {
    text-decoration: none;
    background:var(--color-light-grey);
    display: inline-block;
    font-size: .75rem;
    padding:.25rem .875rem;
    @media (min-width: 768px) {
        font-size: .875rem;
    }
}
.pds-jumpnav__item:hover,
.pds-jumpnav__item:focus {
    background:var(--color-medium-grey);
}

.pds-jumpnav__item-w-icon {
    text-decoration: none;
    display: inline-block;
    position: relative;
    font-size: .875rem;
    margin:.25rem 0;
    font-family: var(--font-default-semi-bold);
    @media (min-width: 768px) {
        font-size: .875rem;
    }
}

.pds-jumpnav__item-w-icon:before {
    content:'';
    position: absolute;
    bottom:-.125rem;
    right:0;
    left:1.875rem;
    height:.125rem;
    width: auto;
    background:#000;
}
.pds-jumpnav__item-w-icon:hover:before,
.pds-jumpnav__item-w-icon:focus:before {
    background: transparent;
}


/* pds-iconlist */
.pds-iconlist{
    font-size: .75rem;
    list-style: none;
    padding-left: 0;
}
.pds-iconlist li{
    position: relative;
    margin-bottom: calc(10rem / 16);
    padding-left: calc(26rem / 16);
}
.pds-iconlist li > .icon{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(19rem / 16);
}
.pds-iconlist li > .icon-items-shipped{
    font-size: calc(12rem / 16);
}