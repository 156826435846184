.quickfilter-btn-container {
    margin-top: calc(-5rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-10rem / 16);
    }
}
.quickfilter-btn {
    background: var(--color-light-grey);
    border: 1px solid var(--color-medium-grey);
    font-size: calc(12rem / 16);
    line-height: calc(12/10);
    padding: calc(4rem / 16) calc(6rem/16);
    margin-top: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(6rem / 16);
        margin-top: 0;
    }
}
.quickfilter-btn:hover {
    border-color: var(--color-primary);
}
.quickfilter-btn.is-size-advisor {
    background-color:#fff;
    padding:.625rem .875rem;
    letter-spacing: .05rem;
    /*border-color: #000;*/

    @media screen and (min-width: 768px) {
        margin-left:2.75rem;
    }

    @media screen and (max-width: 767px) {
        border:0;
    }
}
.quickfilter-btn__icon {
    margin-left: calc(12rem / 16);
    font-size: calc(16rem / 16);
}
.quickfilter-btn__icon--left {
    margin:0 calc(12rem / 16) 0 0;
    vertical-align: -.2em;
}
.quickfilter-btn:not(:last-child) {
    margin-right: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem / 16);
    }
}