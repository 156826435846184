/* items */
.wishlist-item {
    background: #fff;
    position: relative;
}
.wishlist-item--sold-out {
    border:1px solid #000;
}
.wishlist-item--sold-out .wishlist-item__body {
    opacity: .5;
    pointer-events: none;
}

.wishlist-item__head {
    background:#000;
    color:#fff;
    text-align: center;
    padding:.375rem 1rem;
    font-size: calc(13rem / 16);
    font-family:var(--font-default-semi-bold);
    @media (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.wishlist-item__head a {
    color:#fff;
    text-decoration: underline;
}
.wishlist-item__head a:hover,
.wishlist-item__head a:focus {
    text-decoration: none;
}
.wishlist-item__body {
    padding: calc(20rem/16);
    position: relative;
    @media (min-width: 1200px) {
        padding: calc(30rem/16);
    }
}

.wishlist-info + .wishlist-item,
.wishlist-item + .wishlist-item {
    margin-top: calc(10rem/16);
    @media (min-width: 768px) {
        margin-top: calc(32rem/16);
    }
}

.wishlist-item__title {
    font-size: calc(16rem/16);
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.wishlist-item__delete {
    position: absolute;
    right: 0;
    top: 0;
    font-size: calc(20rem/16);
}

.cart-items-header {
    padding: 0 calc(40rem/16);
}



/* buttons */
.wishlist-btn {
    border:0;
    color:var(--color-text-default);
    -webkit-appearance: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-grey);
    width: calc(30rem/16);
    height: calc(30rem/16);
    font-size:1rem;
    outline:0 !important;
    pointer-events: auto;
    @media screen and (min-width: 768px){
        font-size:1.25rem;
        width: calc(40rem/16);
        height: calc(40rem/16);
        margin-bottom:calc(10rem/16);
    }
}
.wishlist-btn.is-added {
    color:var(--color-danger);
}
.wishlist-btn.is-added > .icon {
    transform: scale(1);
    animation: pulse 1.25s 1;
}
.wishlist-btn.is-added > .icon:before {
    content:var(--icon-heart-filled);
}

.wishlist-btn.wishlist-btn--pds {
    height:100%;
    margin:0 0 0 .625rem;
    padding-left:2rem;
    padding-right:2rem;
} 

.wishlist-btn.wishlist-btn--pds-mobile {
    display:block;
    text-align: center;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    padding:0;
}

.wishlist-btn.wishlist-btn--cart,
.wishlist-btn.wishlist-btn--wishlist {
    position: absolute;
    right: 0;
    top: -.25rem;
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px){
        width: calc(40rem / 16);
        height: calc(40rem / 16);
    }
    @media screen and (min-width: 768px){
        top: .5rem;
    }
}

.wishlist-btn.wishlist-btn--cart {
    @media screen and (max-width: 767px){
        right: -.5rem;
    }
}


.wishlist-btn.wishlist-btn--wishlist {
    background: transparent;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.85);
    }

    50% {
        transform: scale(1.25);
    }

    75% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}


/* empty wishlist */
.wishlist-persist,
.wishlist-empty {
    padding:1.25rem;
    background:#fff;
    @media screen and (min-width: 768px){
        padding:2.5rem;
    }
}

/* wishlist info */
.wishlist-info {
    padding:2rem 1rem;
    text-align: center;
    border:1px solid #FFA100;
    background:#FFECCC;
    font-size: calc(14rem / 16);
    font-family:var(--font-default-semi-bold);
}
.wishlist-info__headline {
    display: inline-block;
    margin:0 auto;
    position: relative;
    padding-left:1.75rem;
    font-size: calc(17rem / 16);
}
.wishlist-info__headline .icon {
    position:absolute;
    left:0;
    top: .125rem;
}