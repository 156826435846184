.file-input-wrapper + .file-input-wrapper {
    display: none;
}
.file-input-wrapper.has-file + .file-input-wrapper,
.file-input-wrapper.has-file{
    display: block !important;
}

.file-input-wrapper.has-file .input-group{
    display: none;
}

.file-input-delete {
    padding:.75rem 1rem;
    margin-bottom: .5rem;
    background:var(--color-light-grey);
    display: none;
}
.has-file .file-input-delete {
    display: block;
}
.file-input-delete__btn {
    font-family: var(--font-default-bold);
}
.file-input-delete__btn .icon {
    position: relative;
    top:.125rem;
    margin-left:.375rem;
}

@media screen and (max-width: 767px) {
    .file-input-wrapper .form-control>span {
        font-size: .875rem;
        line-height: 1.5;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}