.btn{
    padding: .625rem 1.5rem;

    @media screen and (min-width: 768px) {
        padding: 1.125rem 2rem;
    }
    @media screen and (min-width: 1200px) {
        padding: 1.125rem 2.5rem;
    }
}

.btn.disabled, .btn:disabled{
    opacity: 0.35;
}
.btn-cta.disabled, .btn-cta:disabled{
    background:#999;
}
.btn-cta--inital-disabled{
    pointer-events: none;
    background: var(--color-gabor);
    opacity: 0.6;
}

.btn-primary{
    font-size: calc(14rem / 16);
}

.btn-primary:hover,
.btn-primary:focus{
    background-color: white;
    color: var(--color-primary);
}

.btn-primary.disabled, .btn-primary:disabled{
    background-color: var(--color-primary);
    opacity: 0.5;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover{
    color: white;
}

/* CTA Button */
.btn-cta {
    padding: 1.25rem 2rem;
    color:#fff;
    background: var(--color-gabor);
    font-size: calc(14rem / 16);
    font-family:var(--font-default-semi-bold);

    @media screen and (min-width: 768px) {
        padding: 1.25rem 3.25rem;
        font-size: calc(16rem / 16);
    }
}
.btn-cta.btn-block {
    padding-left:1rem;
    padding-right:1rem;
}
.btn-cta:hover,
.btn-cta:focus {
    color:#fff;
    background: #47a990;
}

/* btn-outline-primary */
.btn-outline-primary{
    font-family: var(--font-default-semi-bold);
    font-size: calc(14rem / 16);
    border:2px solid;
}
.btn-outline-primary:focus,
.btn-outline-primary:hover{
    color: white;
    background-color: var(--color-primary);
}

/* btn-outline-primary */
.btn-rounded-white{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    border-radius: 6px;
    color:var(--color-primary);
    font-size: calc(14rem / 16);
    border:2px solid #fff;
    background: #fff;
}
.btn-rounded-white:focus,
.btn-rounded-white:hover{
    background: var(--color-light-grey);
}

/* btn-white */
.btn.btn-white{
    font-family: var(--font-default-bold);
    border-radius:0;
    color:var(--color-primary);
    font-size: calc(14rem / 16);
    background: #fff;
}
.btn.btn-white:focus,
.btn.btn-white:hover{
    color:#fff;
    background:var(--color-primary);
}

/* btn-rounded-outline */
.btn-rounded-outline{
    position: relative;
    margin:.25rem;
    font-family: var(--font-default-bold);
    border-radius: 50px;
    color:var(--color-primary);
    font-size: calc(14rem / 16);
    border:0;
    background: #fff;
}
.btn-rounded-outline:before{
    content:'';
    position: absolute;
    left:-.25rem;
    right:-.25rem;
    bottom:-.25rem;
    top:-.25rem;
    border-radius: 50px;
    border:2px solid #fff;
}
.btn-rounded-outline:focus:before,
.btn-rounded-outline:hover:before{
    border:6px solid #fff;
}

/*btn-secondary*/
.btn.btn-secondary{
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid var(--color-primary);
}

.btn.btn-secondary:hover{
    border-bottom: 2px solid transparent;
}

.btn--inputgroup {
    padding-top:0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    white-space: nowrap;
}

.btn.btn-ajax{
    position: relative;
}

.btn.btn-ajax .ajax-loading,
.btn.btn-ajax .ajax-loading-success{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    background-color: #808080;
}

a.bg-secondary{
    border: 2px solid transparent;
    transition-duration: .25s;
}

a.bg-secondary:hover,
a.bg-secondary:focus{
    background-color: white !important;
    border: 2px solid black;
}

.btn.btn-sm{
    padding: calc(9rem / 16) calc(24rem / 16);
    font-size: calc(10rem / 16);
    line-height: calc(12rem / 16);
    letter-spacing: calc(1rem / 16);
}

.btn.btn-md{
    font-family: var(--font-default-semi-bold);
    padding: calc(16rem / 16) calc(16rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(18rem / 16);
    @media screen and (min-width: 1400px) {
        padding: calc(16rem / 16) calc(32rem / 16);
    }
}

.btn.btn-wide{
    @media screen and (min-width: 768px) {
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }
    @media screen and (min-width: 1200px) {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }
}

/* icon btns */
.btn-icon .icon {
    font-size: 150%;
    vertical-align: -0.2em;
    margin-right:.375rem;
}

@media screen and (max-width: 767px) {
    .btn .icon-dhl {
        display: block;
    }
}

.new-customer-btn{
    margin-top: calc(25rem / 16);
}

.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.input-group-append .btn {
    padding: calc(9rem/16) calc(12rem/16);
    border: 1px solid var(--color-primary);
    border-left: none;
}

.btn:disabled {
    pointer-events: none;
}