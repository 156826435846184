.portal-mainheadline {
    margin-top:2rem;
    padding:0 1.5rem;
    text-align: center;
}

.pimcore_area_redesign-portal-teaserslider + .pimcore_area_redesign-portal-teaserslider {
    @media screen and (min-width: 1680px){
        margin-top:calc(60rem / 16);
    }
}

.pimcore_area_redesign-portal-genderswitch + .pimcore_area_redesign-portal-teaserslider {
    @media screen and (min-width: 1440px){
        margin-top:calc(-60rem / 16);
    }
}

.portal-section {
    background: var(--color-redesign-primary);
    padding:calc(50rem / 16) 0 calc(50rem / 16) 0;
    @media screen and (min-width: 768px){
        padding:calc(80rem / 16) 0 calc(104rem / 16) 0;
    }
}
.portal-section__txt {
    color:var(--color-redesign-primary-contrast);
}
.portal-section a:not(.btn),
.portal-section a:not(.btn):hover,
.portal-section a:not(.btn):focus {
    color:var(--color-redesign-primary-contrast);
}
.portal-section__img {
    margin-top:3rem;
    @media screen and (min-width: 1440px){
        margin-bottom:0rem;
        margin-top:calc(-115rem / 16);
    }
    @media screen and (min-width: 1680px){
        margin-top:calc(-145rem / 16);
    }
}