.teaser-max-fw {
    background: #fff;

    /*@media screen and (min-width: 768px){
        width:100vw;
        margin-left:50%;
        transform: translateX(-50%);
    }*/
}
.teaser-max-fw__img {

}
.teaser-max-fw__body {
    @media screen and (max-width: 767px){
        padding:1.5rem;
    }
}
.teaser-max-fw__topline {
    font-size: .875rem;
    letter-spacing: 2px;
    margin-bottom:.5rem;
    font-family: var(--font-default);
    text-transform: uppercase;
}
.teaser-max-fw__text {
    margin:2.5rem 0;
    line-height:2;
    @media screen and (max-width: 767px){
        margin:1.5rem 0;
    }
}



/* theme gabor */
.teaser-max-fw--gabor {
    background: #5197CF;
}
.teaser-max-fw--gabor .teaser-max-fw__body {
    color:#fff;
}


/* theme pius */
.teaser-max-fw--pius {
    background: #ccc;
}
.teaser-max-fw--pius .teaser-max-fw__title span {
    color:#fff;
    padding:.25rem .375rem;
    text-transform: uppercase;
    background:var(--color-primary);
}


/* theme pius */
.teaser-max-fw--rollingsoft {
    background: #ECB26F;
}
.teaser-max-fw--rollingsoft .teaser-max-fw__body {
    color:#fff;
}