/* enable pinch zoon on mobile */
@media screen and (max-width: 767px){
    .slick-slider {
        touch-action: auto !important;
    }
}

.hero-slider.slider .slick-dots{
    top: 80%;
    left: 67%;
}

.hero-slider__text{
    padding: 100px 10px 0;
    @media screen and (max-width: 767px){
        padding: 50px 10px 30px;
    }
}

.hero-slider .slick-dots li button{
    border-radius: 25px;
    background: 0 0;
    padding: 0;
    border: 2px solid #666!important;
    box-shadow: 0 0 6px hsla(0,0%,100%,.4)!important;
    width: 10px;
    height: 10px;
}

/*.slider .slick-prev.slider__arrow--product-detail{
    transform: translateX(-50%) translateY(-100%);
    position: absolute;
    left: 50%;
    top: -15px;
}

.slider .slick-next.slider__arrow--product-detail{
    transform: translateX(-50%) translateY(100%);
    position: absolute;
    bottom: calc(-15rem / 16);
    top: initial;
    left: 50%;
}*/
.slider--hide-dots .slick-dots,
.slider--hide-dots-md .slick-dots{
    @media screen and (min-width: 768px){
        position: absolute;
        top:0;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;
    }
}

.slider--hide-dots .slick-dots,
.slider--show-dots-md .slick-dots{
    @media screen and (max-width: 767px){
        position: absolute;
        top:0;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;
    }
}

@media screen and (min-width: 768px) {
    .slider--hide-arrows-md .slick-arrow {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}

.hero-slider.slider .slick-dots>li>button{
    border-radius: 25px;
    background: 0 0;
    padding: 0;
    border: 2px solid #666!important;
    box-shadow: 0 0 6px hsla(0,0%,100%,.4)!important;
    width: 10px;
    height: 10px;
    font-size: 0;
}

.hero-slider.slider .slick-dots>li>button:hover{
    background-color: #666;
}

.hero-slider.slider .slick-dots>li.slick-active>button{
    background-color: #666;
    box-shadow: 0 0 6px hsla(0,0%,100%,.4)!important;
    border-color: #666!important;
}

.hero-slider__text--absolute{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0 18px;
}

.hero-slider__text--absolute div{
    height: 100%;
    margin-left: auto;
    width: 350px;
    margin-right: 44px;
}

.hero-slider.hero-slider--absolute.slider .slick-dots{
    right: 186px;
    left: inherit;
}

.threeSixty{
    width: 300px;
    height: 300px;
    cursor: grab;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.threeSixty .loading-overlay {
    position:absolute;
}

.threeSixty.threeSixty--Iframe{
    width: 460px;
    height: 460px;
    @media screen and (min-height: 700px) {
        width: 700px;
        height: 700px;
    }
}

.threeSixty img{
    max-width: 100%;
    height: auto;
    pointer-events: none;
}

.threeSixty.mousedown {
    cursor: grabbing;
}

.threeSixty__overlay {
    position: absolute;
    bottom: 10%;
    width: 100%;
    z-index: 1;

    display: flex;
    justify-content: center;
    pointer-events: none;
}

.threeSixty__drag-info {
    background: var(--color-secondary);
    padding: .5rem 1rem;
}

.threeSixty.threeSixty--Iframe,
.threeSixty--center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.threeSixty--center {
    overflow: hidden;
}


.current-slide-small{
    display: none;
    transition-duration: .5s;
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    background-color: var(--color-primary);

    z-index: 1;
    transform: translateX(-50%);
    width: calc(90rem / 16);
}

.lightbox__item{
    cursor: zoom-in;
}

.lightbox__btn-container{
    position: absolute;
    top: calc(10rem/16);
    right: calc(10rem/16);
    z-index: 10;
    background: var(--color-light-grey);
    @media (min-width: 768px) {
        top: 2px;
        right: 2px;
        background: transparent;
    }
}

.lightbox__btn {
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    font-size: 1.25rem;
    @media (min-width: 768px) {
        font-size:1.25rem;
    }
}

.lightbox__btn .icon-threesixty {
    font-size: calc(24rem/16);
    @media (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}

.slider .slick-arrow:before{
    content: '' !important;
}

.hero-slider__arrow__icon .icon{
    font-size: calc(40rem / 16);
}

.slider:not(.hero-slider--absolute ) .slick-arrow.hero-slider__arrow__icon{
    @media screen and (max-width: 767px){
        top: 25%;
    }
}

.product-slider {
    margin-left: calc(-10rem/16);
    margin-right: calc(-10rem/16);
    @media (min-width: 768px) {
        margin-left: calc(-20rem/16);
        margin-right: calc(-20rem/16);
    }
}

.product-slider.slider .slick-arrow.slick-next.slick-next {
    right: calc(10rem / 16);

    @media screen and (min-width: 1850px){
        right: calc(-50rem/16);
    }
}
.product-slider.slider .slick-arrow.slick-prev.slick-prev {
    left: calc(10rem / 16);

    @media screen and (min-width: 1850px){
        left: calc(-50rem/16);
    }
}

.product-slide__heading{
    padding-top: calc(60rem / 16);
    padding-bottom: calc(30rem / 16);
    @media screen and (max-width: 767px){
        padding-top: calc(30rem / 16);
    }
}

.product-slide__heading-icon{
    position:relative;
    top:.125rem;
    margin-right:.375rem;
    @media screen and (max-width: 767px){
        top:.125rem;
    }
}

.product-slider__item {
    padding: 0 calc(10rem/16);
    @media (min-width: 768px) {
        padding: 0 calc(20rem/16);
    }

}


/* same height slider */
@media (min-width: 768px) {
    .slider--same-height.slick-slider {
        display: block;
    }
    .slider--same-height .slick-track {
        display: flex;
        flex-direction: row;
    }
    .slider--same-height .slick-slide {
        flex-grow: 1;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        height: auto;
    }
    .slider--same-height .slick-slide > div {
        display: flex;
        flex: 1 0 auto;
        height: auto;
    }
}

@media (max-width: 767px) {
    .slider.recommendation-slider--magazine .slick-arrow,
    .slider.recommendation-slider--magazine .slick-arrow:hover,
    .slider.recommendation-slider--magazine .slick-arrow:focus {
       transform: translateY(0);
        top:20%;
        background: #fff;
        color: var(--color-text-default);
    }
}

.slider.slider--small-arrows .slick-arrow.slick-arrow {
    right:auto;
    left:-.5rem;
    text-indent: 0;
    font-size: .625rem;
    padding: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--color-text-default);
    color: #fff;
}
.slider.slider--small-arrows .slick-arrow.slick-next.slick-next {
    left:auto;
    right:-.5rem;
}
.slider--transparent-arrows .slick-arrow{
    opacity: .3;
    transition: opacity 120ms ease-out;
}
.slider--transparent-arrows .slick-arrow.slick-disabled {
    opacity: .1;
}
.slider--transparent-arrows:hover .slick-arrow:not(.slick-disabled) {
    opacity: .8;
}
.slider--transparent-arrows:hover .slick-arrow:not(.slick-disabled):hover {
    opacity: 1;
}

.slider--initial-slides-1:not(.slick-initialized) > * + * {
    display: none;
}