@media screen and (max-width: 767px) {
    .navbar-container .container {
        padding: 0;
        overflow: hidden;
    }
    .navbar-top__nav-col {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        order:3;
    }
    .navbar-top__misc-col {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        order:2;
    }
    .navbar-main {
        position: fixed;
        left: 0;
        right: 0;
        top: calc(75+34rem / 16);
        padding: 1.25rem 1.25rem 4rem 1.25rem;
        transform: translateX(-100%);
        height: calc(100vh - calc(112rem / 16));
        transition: transform 200ms ease-in-out;
        background: var(--color-light-grey);
        z-index: 10;
        display: block;
    }
    .is-open .navbar-main {
        transform: translateX(0);
    }
    .navbar-main .navbar-collapse {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .navbar-toggler {
        display: inline-block;
        position: relative;
        line-height:1px;
        font-size:1.25rem;
        color: var(--color-text-default);
        padding:1rem .625rem;
        outline: 0 !important;
    }
    .navbar-toggler .icon {
        line-height:1px;
        pointer-events: none;
    }
    .navbar-toggler .icon-close {
        display: none;
    }
    .navbar-toggler.is-open .icon-close {
        display: block;
        font-size: 1rem;
        margin-right: 0.375rem;
    }
    .navbar-toggler.is-open .icon-menu-search {
        display: none;
    }
    .navbar-toggler--menu {
        position: absolute;
        top:0;
        right:.375rem;
    }
    .navbar-toggler--account {
        font-size: 1.375rem;
        position: absolute;
        right: 3.75rem;
        top: 0;
    }
    .navbar-top {
        height:7rem;
    }
    .navbar-main .nav-link {
        border-bottom: calc(1rem / 16) solid #e0e0e0;
    }
    .navbar-main .nav-item.is-open > .nav-link {
        border-color:transparent;
        padding-bottom:.75rem;
    }
    .nav-item.is-open>.nav-link>.nav-link__arrow {
        margin-top:.375rem;
    }
    .navbar-nav--main>.nav-item:last-child{
        margin-bottom: 4rem;
    }
    .navbar-nav--main>.nav-item:last-child .nav-link {
        border-bottom: none;
    }
    .navbar-main .nav-item {
        margin:0;
    }
    .navbar-main__dropdown__list {
        padding-left: calc(10rem/16);
    }
    .navbar-main__dropdown__list__heart {
        vertical-align: -.4em;
        margin-left: .5rem;
    }
/*    .navbar-main__dropdown__list:before {
        content: '';
        position: absolute;
        left: calc(-8rem / 16);
        top: 0;
        bottom: 0;
        width: calc(1rem / 16);
        background: transparent;
    }
    .is-open.navbar-main__dropdown__list:before {
        background: #d1d1d1;
    }*/

    .navbar-main__dropdown__list__title {
        display: block;
        position: relative;
        padding: calc(16rem / 16) calc(10rem / 16) calc(16rem / 16) calc(26rem / 16);
        border-bottom: 1px solid #e0e0e0;
    }

    .navbar-main__dropdown__list__title.has-sale {
        color: var(--color-danger);
    }
/*    .navbar-main__dropdown__list__title:before {
        content: '';
        position: absolute;
        left: calc(-8rem / 16);
        top: 0;
        bottom: 0;
        width: calc(2rem / 16);
        background: var(--color-primary);
        opacity: 0;
    }
    .is-open > .navbar-main__dropdown__list__title:before {
        opacity: 1;
    }*/
    .nav-link__arrow,
    .navbar-main__dropdown__arrow {
        position: absolute;
        right: calc(10rem/16);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: transform .2s ease;
    }

    .nav-item.is-open > .nav-link > .nav-link__arrow,
    .navbar-main__dropdown__list.is-open > .navbar-main__dropdown__list__title > .navbar-main__dropdown__arrow {
        transform: translateY(-50%) rotate(-270deg);
    }

    .navbar-main__dropdown__list__subnav {
        display: none;
    }
    .is-open > .navbar-main__dropdown__list__subnav {
        display: block;
    }
    .navbar-main__dropdown__list__subnav > ul > li {
        padding: calc(16rem/16) calc(10rem/16);
        margin: 0;
        border-bottom: calc(1rem / 16) solid #e0e0e0;
        font-size: calc(14rem / 16);
    }
    .navbar-main__dropdown__list__subnav a {
        padding-left:2rem;
    }
    .navbar-main__dropdown__list__subnav > ul > li.navbar-top__dropdown__menu__item--sub {
        padding-left:calc(20rem/16);
    }

    .navbar-top {
        font-size: calc(18rem / 16);
    }
    .navbar-top__cart-link {
        font-size: calc(35rem / 16);
        height: calc(35rem / 16);
        position: relative;
        padding-right: calc(8rem / 16);
        display: inline-block;
        vertical-align: -.2em;
    }
    .navbar-top__cart-link__text {
        position: absolute;
        top: 0;
        right: calc(2rem / 16);
        font-size: calc(10rem / 16);
    }

    /* search */
    .navbar-top__search {
        background: #fff;
        margin: 0;
        font-size: calc(12rem / 16);
    }

    .language-switch {
        font-size: calc(12rem / 16);
    }
}