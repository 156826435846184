.inline-nav {
    margin-left: calc(-40rem/16);
    margin-right: calc(-40rem/16);
}

.inline-nav__item {
    margin-left: calc(40rem/16);
    margin-right: calc(40rem/16);
}

.inline-nav__item:hover,
.inline-nav__item:focus,
.inline-nav__item.active {
    border-bottom: 1px solid var(--color-primary);
}

@media screen and (max-width: 767px) {
    .inline-nav--scroll-mobile {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 10px;
    }

    .inline-nav {
        margin-left: calc(-16rem/16);
        margin-right: calc(-16rem/16);
    }

    .inline-nav__item {
        margin-left: calc(16rem/16);
        margin-right: calc(16rem/16);
        white-space: nowrap;
    }
}