.prime-hero {
    padding:2.5rem 0;
    position: relative;
    background: var(--bg-standard);
}
.prime-hero__foot {
    position: relative;
    z-index:2;
}
.prime-hero__body {
    position: relative;
    padding:5rem 0;
    z-index:2;
    @media screen and (min-width: 768px) {
        padding:11rem 0;
    }
}
.prime-hero__heading {
    max-width:62rem;
    margin:0 auto;
    font-size: calc(48rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(80rem / 16);
        line-height: 1;
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(120rem / 16);
    }
}
.prime-hero__heading--small {
    font-size: calc(40rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(70rem / 16);
    }
}
.prime-hero__left,
.prime-hero__right {
    width: auto;
    height:100%;
    display: none;
    position: absolute;
    left:0;
    bottom:0;
    z-index:1;
    @media screen and (min-width: 768px) {
        display: block;
        z-index:1;
    }
}
.prime-hero__right {
    left:auto;
    right:0;
}


/* logged in */
.prime-hero--loggedin {
    @media screen and (min-width: 768px) {
        margin-bottom:calc(160rem/16);
    }
}
.prime-hero--loggedin .prime-hero__body {
    padding:3rem 0;
    @media screen and (min-width: 768px) {
        padding:5.5rem 0;
    }
}
.prime-hero__card-holder {
    position: relative;
    @media screen and (min-width: 768px) {
        margin-bottom:-15.5rem;
    }
}
