.highlight-image {
    position: relative;
    background:#EADBD5;
    margin:2rem 0 0 2.5rem;
    padding-bottom: 2rem;
@media (min-width: 768px){
    margin:3rem 0 0 3.5rem;
    padding-bottom: 3rem;
}
}
.highlight-image__img {
    position: relative;
    margin:-2rem 0 0 -2.5rem;
@media (min-width: 768px){
    margin:-3rem 0 0 -3.5rem;
}
}
