.state__icon {
    display: inline-block;
    width: calc(7rem / 16);
    height: calc(7rem / 16);
    border-radius: 50%;
    background-color: var(--color-medium-grey);
    vertical-align: 0.1em;
    margin-right:.25rem;
}
.state__icon--part-delivery,
.state__icon--warning {
    background-color: #FFAC30;
}
.state__icon--delivered,
.state__icon--success {
    background-color: #44C25A;
}
.state__icon--items-shipped,
.state__icon--order {
    background-color: #447DC2;
}
.state__icon--danger {
    background-color: #DE252C;
}