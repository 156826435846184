.hero-teaser__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(20rem/16);
    text-shadow: 0 0 25px rgba(0,0,0,.35);

    @media (min-width: 992px) {
        padding: calc(40rem/16);
    }
}

.hero-teaser__btns {
    text-shadow: none;
    margin-top: calc(10rem/16);
    @media (min-width: 768px) {
        margin-top: 1.25rem;
    }
}

@media (max-width: 1199px) {

    .hero-teaser__btns .btn {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.hero-teaser__title {
    text-shadow: 0 0 15px rgba(255, 255, 255, .9);
    font-size: responsive;
    min-font-size: 24px;
    max-font-size: 40px;
    lower-font-range: 768px;
    upper-font-range: 1280px;
}

.hero-teaser__title.text-white {
    text-shadow: 0 0 15px rgba(0,0,0,.35);
}
