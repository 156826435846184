.delete-from-cart {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
}

.delete-from-cart__icon {
    font-size: calc(22rem/16);
}
