.form-control{
    height:3rem;
    padding: calc(12rem / 16) calc(12rem / 16);
    font-size: calc(16rem / 16);
}

.form-control--sm {
    height: 2.5rem;
    padding: calc(8rem / 16);
}

.form-control-label{
    font-size: calc(11rem / 16);
    font-family: var(--font-default-semi-bold);
}

.custom-radio__input.form-check-input{
    width: 0;
}