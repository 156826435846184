.theme-men {
    --color-redesign-primary: #222222;
    --color-redesign-primary-contrast: #fff;
}

.theme-men .portal-section .btn-primary {
    background:#fff;
    color:var(--color-text-default);
}
.theme-men .portal-section .btn-primary:hover,
.theme-men .portal-section .btn-primary:focus{
    background-color: #000;
    color: var(--color-redesign-primary-contrast);
}
.theme-men .btn-primary--white-outline-men,
.theme-men section .portal-categoryteaser .btn-primary {
    border:1px solid #fff;
}

@media screen and (min-width: 1800px){
    .theme-men .slider.recommendation-slider:not(.recommendation-slider--in-modal) .slick-arrow{
        background:var(--color-redesign-primary-contrast);
        color:var(--color-text-default);
    }
}

.theme-men .privacy-button {
    border:1px solid #fff !important;
}

/* overwrite --header-height-xs for theme-b2e */
.theme-b2e {
    --header-height: calc(82rem/16);
    --header-height-md: calc(148rem/16);
    --header-height-xs: calc(106rem/16);
}
.theme-b2e .page-title.page-title--checkout {
    margin-top:0;
}
