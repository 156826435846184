.hotspot-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.hotspot {
    position: absolute;
    width: calc(50rem/16);
    height: calc(50rem/16);
    display: block;
}
.hotspot.is-open {
    z-index: 3;
}
.hotspot__toggle:focus {
    outline: none;
}
.hotspot__toggle{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
}
.hotspot__toggle:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: calc(5rem/16) solid var(--color-primary);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
    opacity: .15;
}
.hotspot__toggle:before {
    content: '';
    height: calc(40rem/16);
    width: calc(40rem/16);
    background: var(--color-primary);
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
    transition: background 250ms ease-in-out;
    opacity: .7;
}
.is-open .hotspot__toggle:before {
    z-index: 2;
}
.hotspot__toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 250ms ease-in-out;
    z-index: 2;
    font-size: 9px;
    color: #fff;

    @media screen and (min-width: 768px) {
        font-size: 16px;
    }
}
.is-open .hotspot__toggle__icon {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

/* hotspot detail */
.hotspot__detail{
    position: absolute;
    top: calc(25rem/16);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(34,34,34,0.2);
    width: 352px;
    opacity: 0;
    visibility: hidden;
    cursor: default;
    z-index: 1;
    transition: opacity .2s, visibility 0s;
    max-width: calc(100vw - 40px);
}
.is-open .hotspot__detail {
    opacity: 1;
    visibility: visible;
}

.hotspot__detail-body {
    padding: calc(20rem/16);
}

.hotspot--detail-top .hotspot__detail {
    transform: translateX(-50%) translateY(-100%);
}
.hotspot--detail-right .hotspot__detail {
    transform: translateX(0);
}
.hotspot--detail-left .hotspot__detail {
    transform: translateX(-100%);
}
.hotspot--detail-top.hotspot--detail-right .hotspot__detail {
    transform: translateX(0) translateY(-100%);
}
.hotspot--detail-top.hotspot--detail-left .hotspot__detail {
    transform: translateX(-100%) translateY(-100%);
}


.circle-animation {
    width: calc(50rem/16);
    height: calc(50rem/16);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.circle-animation__circle {
    stroke-dasharray: 0 100;
}
.hotspot:hover .circle-animation {
    animation: circle-rotate 1200ms forwards infinite linear;
}
.hotspot.is-open:hover .circle-animation {
    animation-play-state: paused;
}
@keyframes circle-rotate {
    from {transform: rotate(-90deg)}
    to {transform: rotate(270deg)}
}
.hotspot:hover .circle-animation__circle,
.hotspot__toggle:focus .circle-animation__circle {
    stroke: #fff;
    animation: circle-fill-hover 1s infinite linear;
    animation-direction: alternate;
}
.hotspot.is-open .circle-animation__circle,
.hotspot.is-open:hover .circle-animation__circle {
    stroke:  #fff;
    animation: circle-fill 500ms forwards linear;
}
@keyframes circle-fill-hover {
    to { stroke-dasharray: 30 100; }
}

@keyframes circle-fill {
    to { stroke-dasharray: 100 100; }
}