/* categories in modal */
.newsletter-category {
    position: relative;
    margin-bottom: 1rem;
    display: block;
    width: 100%;
    border:0;
    padding:0;
    -webkit-appearance: none;
    background: transparent;
    outline: 0 !important;
}
.newsletter-category .form-check-input.is-invalid + .newsletter-category__text {
    color:var(--color-danger);
}
.newsletter-category .form-errors {
    position: absolute;
}
.newsletter-category__text {
    text-align: center;
    margin-top:.375rem;
    @media screen and (max-width: 767px) {
        font-size:.75rem;
        height:2.25rem;
    }
}
.newsletter-category .custom-checkbox__box {
    position: absolute;
    left:50%;
    bottom:2.5rem;
    transform: translateX(-50%);
    margin:0;
    padding:0;
    z-index: 2;
    @media screen and (max-width: 767px) {
        bottom:3.375rem;
    }
}

/* headline in success modal */
.nl-success-headline {
    margin: 0;
}
.nl-success-headline .icon {
    font-size:1.5rem;
    @media screen and (max-width: 767px) {
        font-size:1.125rem;
        vertical-align: -.1em;
    }
}



/* promotion */
.newsletter-promotion {
    position: relative;
    background:var(--color-light-grey);
}
.newsletter-promotion__body {
    padding:1.25rem 2.5rem;

    @media screen and (max-width: 767px) {
        padding:2.5rem;
    }
}