.prime-benefits-box {
    background: #fff;
    position: relative;
    height:100%;
}
.prime-benefits-box__header {
    padding:.875rem 1.25rem;
    @media (min-width: 768px) {
        padding:1.125rem 1.75rem;
    }
}
.prime-benefits-box__header--prime {
    background:var(--bg-prime-bronze);
}
.prime-benefits-box__header--standard {
    background:var(--bg-standard);
}
.prime-benefits-box__header--gold {
    background:var(--bg-prime-gold);
}
.prime-benefits-box__header--silver {
    background:var(--bg-prime-silver);
}
.prime-benefits-box__header--bronze{
    background:var(--bg-prime-bronze);
}
.prime-benefits-box__title {
    position: relative;
    margin:0;
    padding:0 3rem 0 0;
    letter-spacing: 1px;
    font-size:1rem;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    @media (min-width: 768px) {
        font-size:1.375rem;
    }
}
.prime-benefits-box__title-small {
    font-size:.75rem;
    text-transform: none;
    font-family: var(--font-default);
    font-weight: normal;
    margin-top:.5rem;
    @media (min-width: 768px) {
        font-size:.875rem;
    }
}
.prime-benefits-box__title-icon {
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    background: #fff;
    border-radius: 100%;
    width:2.25rem;
    height:2.25rem;
    line-height: 2.25rem;
    text-align: center;
    text-indent: 1px;
}
@media (min-width: 768px) {
    .prime-benefits-box__title-icon--large {
        font-size: 2rem;
        width:3.5rem;
        height:3.5rem;
        line-height: 3.5rem;
    }
}
.prime-benefits-box__body {
    padding:1.25rem;
    @media (min-width: 768px) {
        padding:1.125rem 1.75rem;
    }
}
.prime-benefits-box__body + .prime-benefits-box__body {
    border-top:.125rem solid var(--color-light-grey);
}