.language-switch{
    /*-webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';*/

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    padding: 0;
}

/*.language-switch::-ms-expand {
    display: none;
}*/

.reduced-header {
    text-align:center;
    padding:2rem 0;
    border-bottom:1px solid #e0e0e0;
    margin-bottom:4rem;
    @media screen and (max-width: 767px){
        padding:1rem 0;
        margin-bottom:2rem;
    }
}

header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: all .5s ease;
    background-color: var(--color-light-grey);
    z-index: 999;
    height:var(--header-height);

    @media screen and (max-width: 767px){
        height:var(--header-height-xs);
    }

    @media (min-width: 768px) and (max-width: 1650px) {
        height:var(--header-height-md);
    }
}
.header-placeholder {
    height:var(--header-height);

    @media screen and (max-width: 767px){
        height:var(--header-height-xs);
    }

    @media (min-width: 768px) and (max-width: 1650px) {
        height:var(--header-height-md);
    }
}
header.not-is-affix,
header.is-affix {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    will-change: transform;
}
/*header.is-affix {
    transform: translateY(-2.625rem);
    @media screen and (max-width: 767px){
        transform: translateY(-2.125rem);
    }
}*/
header.not-is-affix {
    transform: translateY(-110%);
}
