@font-face {
    font-family: 'Inter Regular';
    src: url('fonts/Inter-Regular.woff2') format('woff2'),
    url('fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter SemiBold';
    src: url('fonts/Inter-SemiBold.woff2') format('woff2'),
    url('fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Bold';
    src: url('fonts/Inter-Bold.woff2') format('woff2'),
    url('fonts/Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}
