/* place switch in nav */
@media screen and (min-width: 1651px){
    .gender-switch-nav-item {
        position: relative;
        width: 0;
        padding:0;
        margin: 0;
    }
    .gender-switch-nav-item .nav-genderswitch {
        position: absolute;
        left:-13rem;
    }
}

.gender-switch {
    border:1px solid var(--color-primary);
    padding:calc(4rem / 16);
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 10rem;
    background:#fff;
}
.gender-switch__btn {
    height:calc(38rem / 16);
    line-height:calc(38rem / 16);
    padding:0 2.625rem;
    display: inline-block;
    border-radius: 10rem;
    text-transform: uppercase;
    background:#fff;
    font-family: var(--font-default-semi-bold);
    color:var(--color-primary);
}
.gender-switch__btn:hover,
.gender-switch__btn:focus,
.gender-switch__btn--active {
    color:#fff;
    background:var(--color-primary);
}
.gender-switch__btn--vertical-top {
    margin-top:calc(-19rem/16);
    margin-bottom:0;
    @media screen and (min-width: 768px){
        margin-bottom:2rem;
    }
}

/* nav-genderswitch */
.nav-genderswitch {
    white-space: nowrap;
    display: inline-block;
    font-family: var(--font-default-semi-bold);
    font-size: .875rem;
    background: #fff;
    border-radius: 2rem;
    overflow: hidden;
    margin-top:.5rem;
    margin-right: .625rem;
    @media screen and (max-width: 767px){
        margin:0;
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        top:.125rem;
        font-size: .625rem;
    }
}
.nav-genderswitch__item {
    display: inline-block;
    padding:.5rem 1rem;
    border-radius: 2rem;
}
.nav-genderswitch__item:hover,
.nav-genderswitch__item:focus,
.nav-genderswitch__item--active {
    color:#fff;
    background:var(--color-text-default);
}

/* nav-genderswitch dropdown for mobile
.nav-genderswitch-dropdown {
    display: none;
}
@media screen and (max-width:767px){
    .nav-genderswitch-dropdown {
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        top:.25rem;
        white-space: nowrap;
        text-align: center;
        display: block;
        width: 6rem;
        height:1.75rem;
        color:var(--color-text-default);
        font-family: var(--font-default-semi-bold);
        font-size: .625rem;
    }
    .nav-genderswitch-dropdown__item {
        display: none;
        padding:0 .5rem;
        height:1.75rem;
        line-height:1.75rem;
        border-radius: 2rem;
        position:absolute;
        left:0;
        right: 0;
        top:100%;
        color:#fff;
        background:var(--color-text-default);
        overflow: hidden;
    }
    .nav-genderswitch-dropdown__item > .icon {
        display: none;
        font-size:.5rem;
        position: relative;
        margin-left:.25rem;
    }
    .nav-genderswitch-dropdown--open .nav-genderswitch-dropdown__item:not(.nav-genderswitch-dropdown__item--active) {
        display: block;
    }
    .nav-genderswitch-dropdown__item--active {
        top:0;
        display: block;
        color:var(--color-text-default);
        background: #fff;
    }
    .nav-genderswitch-dropdown__item--active > .icon {
        display: inline-block;
    }
}*/