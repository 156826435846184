.trust-badge {
    position: fixed;
    left:1rem;
    bottom:1rem;
    z-index:12;
    transition: transform 250ms ease-in-out;
}

.trust-badge.hide-trust-badge {
    transform: translateX(-120%);
}


@media screen and (max-width: 991px) {
    div[id^="trustbadge-container-"], div[id*="trustbadge-container-"] {
        z-index:900 !important;
    }
}