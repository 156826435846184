@media (max-width: 767px) {

    .table-mobile-blocks td,
    .table-mobile-blocks th {
        display: block;
        padding: .5rem;
    }

    .table-mobile-blocks .table-mobile-blocks__head {
        display: none;
    }
}

