.checkout-overview {
    padding: calc(20rem/16);
    background:#fff;
    @media (min-width: 1200px) {
        padding: calc(40rem/16);
    }
}
.checkout-overview__headline {
    font-size:1.25rem;
    position:absolute;
    top:.5rem;
    left:1rem;
    @media screen and (min-width: 768px) {
        font-size:1.75rem;
        top:-3rem;
        left:0;
    }
}
.checkout-overview .grand-total {
    font-size:1.125rem;
}
.checkout-overview .cart-grand-total .cart-grand-total__sum{
    font-size:1.375rem;
}
.checkout-overview .free-section,
.checkout-overview .grand-total-tax {
    font-size:.875rem;
}

/*!! sorry !!*/
.checkout-overview .old-price + span {
    margin-right: .5rem;
}

.checkout-delivery-img {
    display: inline-block;
    height:auto;
    max-width:7rem;
}

/* cart usp box */
.cart-usp-box {
    padding:1.25rem;
    font-family:var(--font-default-semi-bold);
    font-size:.75rem;
    background:#fff;
    @media screen and (min-width: 1200px) {
        padding:1.5rem 2.5rem 1.25rem 2.5rem;
    }
    @media screen and (max-width: 767px) {
        margin-top:3rem;
    }
}
.cart-usp-box__icon {
    display: block;
    height:1.5rem;
    font-size:1.125rem;
    margin-bottom:.125rem;
}
.cart-usp-box__icon.icon-return {
    font-size:1.375rem;
}


/* prime box */
.cart-prime-box {
    text-align: center;
    font-size:.75rem;
    padding: .25rem;
    background: var(--bg-standard);
}
.cart-prime-box__content {
    padding: 1rem 2rem;
    background: #fff;
}
.cart-prime-box__heading {
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size:.875rem;
    }
}
.cart-prime-box__heading-icon {
    margin-right:.5rem;
    font-size: 1rem;
    background: var(--bg-standard);
    border-radius: 100%;
    width:1.875rem;
    height:1.875rem;
    line-height: 1.625rem;
    text-align: center;
}