.title-block {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem / 16);
    }
}
.title-block__title,
.title-block__wysiwyg {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem / 16);
    }
}
.title-block:not(.title-block--main) .title-block__sub-title {
    font-family: var(--font-default);
    text-transform: uppercase;
    font-size: calc(14rem / 16);
    letter-spacing: 2px;
}

.theme-pius .title-block:not(.title-block--main) .title-block__title{
    display: inline-block;
    color:#fff;
    padding:.25rem .625rem;
    text-transform: uppercase;
    background:var(--color-primary);
    text-shadow: none;
}

.theme-rollingsoft .title-block:not(.title-block--main) .title-block__title{
    font-family: var(--font-default-semi-bold);
    text-transform: uppercase;
    letter-spacing: 0;
}

.title-block--main {
    margin-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(80rem / 16);
    }
}

.title-block__sub-title {
    font-size: calc(22rem / 16);
    letter-spacing: normal;
}
.title-block h2>a,
.title-block h3>a,
.title-block h4>a{
    text-decoration: none !important;
}