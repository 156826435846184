.custom-checkbox-linkstyle {
    position: relative;
    display: inline-block;
}

.custom-checkbox-linkstyle:before,
.custom-checkbox-linkstyle:after {
    content: " ";
    display: table;
}

.custom-checkbox-linkstyle:after {
    clear: both;
}

.custom-checkbox-linkstyle>label{
    cursor: pointer;
}
.custom-checkbox-linkstyle__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox-linkstyle__text {
    padding-bottom:1px;
    border-bottom:1px solid transparent;
    font-family: var(--font-default-bold);
    font-size: calc(14rem / 16);
    letter-spacing: 1px;
    display: block;
    overflow: hidden;
}
.custom-checkbox-linkstyle__input:checked + .custom-checkbox-linkstyle__text {
    border-bottom:1px solid;
}

/* btn style */
.custom-checkbox-linkstyle--btn .custom-checkbox-linkstyle__text{
    padding: 16px 40px;
    line-height: 1;
    font-size: calc(14rem / 16);
    background: #F5F5F5;
    border:1px solid transparent;
}
.custom-checkbox-linkstyle--btn .custom-checkbox-linkstyle__input:checked + .custom-checkbox-linkstyle__text {
    border:1px solid var(--color-primary);
    background: #fff;
}