.unregistered-return {
    @media screen and (min-width: 768px) {
        visibility:hidden;
    }
    @media screen and (max-width: 767px) {
        display:none;
    }
}


.return-shema {

}

.return-shema__item {
    position: relative;
    padding: calc(12rem / 16) 0 calc(50rem / 16) calc(60rem / 16);

    @media screen and (min-width: 768px) {
        padding:calc(28rem / 16) 0 calc(80rem / 16) calc(112rem / 16);
    }
}
.return-shema__item:before {
    content: '';
    width: 1px;
    border-left: 2px dotted var(--color-text-default);
    position: absolute;
    left: calc(25rem / 16);
    top: 0;
    bottom: 0;
    opacity: .55;

    @media screen and (min-width: 768px) {
        left:calc(45rem / 16);
    }

}
.return-shema__item.return-shema__item:last-child {
    padding-bottom: 1rem;
}

.return-shema__item:last-child:before {
    display: none;
}

.return-shema__item-bubble {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    border-radius: 100%;
    width: calc(50rem / 16);
    height: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        width:calc(90rem / 16);
        height:calc(90rem / 16);
    }
}
.return-shema__item-bubble > .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(24rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(38rem/16);
    }
}
.return-shema__item-bubble > .icon-package,
.return-shema__item-bubble > .icon-truck {
    font-size: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(30rem/16);
    }
}


/* return status in modal */
.return-status {
    @media screen and (max-width: 767px) {
        border-bottom: 2px solid var(--color-light-grey);
    }
}

.return-status + .return-status {
    margin-top:3rem;
}

.return-status__item {
    position: relative;
    /*color: #C4C4C4;*/
    padding: calc(14rem / 16) 0;
    font-size: .75rem;
    line-height:1.5;

    @media screen and (min-width: 768px) {
        padding:calc(20rem / 16) 0;
        font-size:1rem;
    }
}
.return-status__item:before {
    content: '';
    width: 1px;
    border-left: 2px dotted var(--color-text-default);
    position: absolute;
    left: calc(25rem / 16);
    top: calc(14rem / 16);
    bottom: -2rem;
    opacity: .35;

    @media screen and (min-width: 768px) {
        left:calc(36rem / 16);
        top:calc(28rem / 16);
    }

}
/*.return-status__item.return-status__item:last-child {
    padding-bottom: 1rem;
}*/
.return-status__item:last-child:before {
    display: none;
}

.return-status__item-headline {
    font-size: .875rem;
    font-family: var(--font-default-bold);
    margin-bottom:.375rem;
    opacity:.6;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}

.return-status__item-desc {
    margin-bottom:.5rem;
    opacity:.6;
}


.return-status__item.return-status__item--active .return-status__item-desc,
.return-status__item.return-status__item--completed .return-status__item-desc,
.return-status__item.return-status__item--active .return-status__item-headline,
.return-status__item.return-status__item--completed .return-status__item-headline {
    opacity:1;
}

.return-status__item-more {
    text-decoration: none;
    border-bottom:1px solid;
}
.return-status__item-more:hover,
.return-status__item-more:focus {
    border-bottom: none;
}
.return-status__item-bubble {
    position: relative;
    display: block;
    background: #F5F5F5;
    color: #C4C4C4;
    border-radius: 100%;
    width: calc(50rem / 16);
    height: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        width:calc(72rem / 16);
        height:calc(72rem / 16);
    }

}
.return-status__item.return-status__item--active {
    color: var(--color-text-default);
}

.return-status__item.return-status__item--active .return-status__item-bubble {
    background: var(--color-text-default);
    color: #fff;
}

.return-status__item.return-status__item--completed .return-status__item-bubble {
    color: var(--color-text-default);
}

.return-status__item-bubble > .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        font-size:calc(32rem/16);
    }

}

.return-status__item-bubble > .icon-items-shipped {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size:calc(20rem/16);
    }

}
.return-status__item-bubble > .icon-check-rounded {
    display: none;
}

.return-status__item--completed .return-status__item-bubble > .icon-check-rounded {
    display: block;
    left: auto;
    right: -.25rem;
    top: auto;
    bottom: .25rem;
    transform: translateY(0) translateX(0);
    font-size: calc(16rem / 16);
    color:#44C25A;

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }

}

/* dark */
.return-status__item.return-status__item--dark {
    color: var(--color-text-default);
}

.return-status__item.return-status__item--dark .return-status__item-bubble {
    color: var(--color-text-default);
}

/* sticky btn */
.sticky-return-button {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: .75rem;
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    background: var(--color-primary);
    z-index: 999;

    @media screen and (min-width: 768px) {
        font-size:calc(16rem / 16);
        padding:.875rem 4rem;
        width:auto;
    }

}

.sticky-return-button:hover,
.sticky-return-button:focus {
    color: #fff;
    background: #000;
}

.sticky-return-button__amount {
    font-size: calc(11rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(13rem / 16);
    }

}


/* label selection */
.return-label-item {
    display: block;
    width:100%;
    max-width:15rem;
    margin:0 auto;
    text-align: center;
    outline:0 !important;
}
.return-label-item__bubble {
    display: block;
    margin:1rem auto;
    position: relative;
    background: var(--color-light-grey);
    color: var(--color-primary);
    border-radius: 100%;
    width: calc(120rem / 16);
    height: calc(120rem / 16);

    @media screen and (min-width: 768px) {
        width:calc(150rem / 16);
        height:calc(150rem / 16);
    }
}

.return-label-item:hover .return-label-item__bubble,
.return-label-item:focus .return-label-item__bubble {
    background: var(--color-primary);
    color: #fff;
}
.return-label-item__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(52rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(64rem/16);
    }
}
.return-label-item__text {
    font-family: var(--font-default-bold);
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(18rem/16);
    }
}