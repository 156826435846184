.is-prime-bronze {
    --bg-standard: var(--bg-prime-bronze);
}
.is-prime-silver {
    --bg-standard: var(--bg-prime-silver);
}
.is-prime-gold {
    --bg-standard: var(--bg-prime-gold);
}

.bg-prime {
    background: var(--bg-standard);
}

.prime-points {
    position: relative;
    display: inline-block;
    font-size:.75rem;
    font-family: var(--font-default-bold);
    padding:.375rem 1rem .375rem 2rem;
    background: var(--bg-standard);
}
.prime-points__icon {
    position: absolute;
    left:.625rem;
    top:.625rem;
    font-size:.625rem;
}

.prime-box {
    padding:1.5rem;
    background: var(--bg-standard);
    @media screen and (min-width: 768px) {
        padding:2.5rem;
    }
}

.loyalty-consent--hidden {
    display: none;
}