.pagination {
    /*color: var(--color-dark-grey);*/
}

.page-item {
    font-size: 1rem;
}
.page-link:not(.pagination-circle-white) {
    margin:0 .5rem;
    padding:.25rem .25rem;
    color:#000;
    border-bottom:.125rem solid transparent;
}
.page-link:not(.pagination-circle-white):hover,
.page-link:not(.pagination-circle-white):focus,
.page-link--active:not(.pagination-circle-white) {
    border-bottom:.125rem solid;
}

.page-link__icon {
    font-size: calc(12rem / 16);
}

.pagination-circle-white.pagination-circle-white {
    background-color: #FFFFFF;
    border-radius: 50% !important;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0;
    color: #000000;
    text-align: center;
    margin: 0 0.5rem;
}
.pagination-circle-white.pagination-circle-white:hover,
.pagination-circle-white.pagination-circle-white:focus{
    background-color: #000 !important;
    color: #fff !important;
}

.pagination-circle-white--disabled {
    opacity: .5;
    cursor: not-allowed;
}