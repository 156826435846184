.dhl-contact {
    padding: calc(10rem/16);
    background-color: white;
    font-size: calc(13rem/16);
    transition: 0.2s ease;
}
.dhl-contact:hover {
    background-color: var(--color-secondary);
}
.dhl-contact.is-active {
    background-color: var(--color-secondary);
}
.dhl-contact + .dhl-contact {
    margin-top: 4px;
}

.dhl-contact__img {
    width: calc(28rem/16);
}