.premium-teaser .embed-responsive {
    background: #fff;
}
.premium-teaser--disabled .embed-responsive {
    background: #ccc;
}
.premium-teaser:not(.premium-teaser--disabled) .premium-teaser__image-wrapper {
    position: relative;
    padding:.125rem;
}
.premium-teaser__badge--bronze,
.premium-teaser--bronze .premium-teaser__badge,
.premium-teaser--bronze .premium-teaser__image-wrapper {
    background: var(--bg-prime-bronze);
}
.premium-teaser__badge--gold,
.premium-teaser--gold .premium-teaser__badge,
.premium-teaser--gold .premium-teaser__image-wrapper {
    background: var(--bg-prime-gold);
}
.premium-teaser__badge--silver,
.premium-teaser--silver .premium-teaser__badge,
.premium-teaser--silver .premium-teaser__image-wrapper {
    background: var(--bg-prime-silver);
}
.premium-teaser__badge {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--bg-prime-bronze);
    font-family: var(--font-default-semi-bold);
    padding: .125rem .75rem;
    font-size: .75rem;
}
.premium-teaser__img-text {
    position: absolute;
    top: 50%;
    left: .5rem;
    right: .5rem;
    transform: translateY(-50%);
    font-size: 1.125rem;
    line-height: 1.125;
    font-family: var(--font-default-bold);
    color: #fff;
    text-align: center;
}

.premium-teaser__icon {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom:auto;
    transform: translateY(-50%) translateX(-50%);
    font-size: 2.5rem;
}
.premium-teaser--disabled .strong,
.premium-teaser--disabled .wysiwyg,
.premium-teaser--disabled .premium-teaser__title {
    opacity: .4;
}

.premium-teaser__title {
    font-family: var(--font-default-bold);
    font-size: calc(14rem / 16);
    display: block;
}



/* large */
.premium-teaser__title--large,
.premium-teaser--large .premium-teaser__title {
    font-size: calc(17rem / 16);
    @media (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.premium-teaser--large .premium-teaser__badge {
    font-size: 1rem;
    padding: .25rem 1.125rem;
}
.premium-teaser--large .premium-teaser__img-text {
    font-size:  calc(30rem / 16);
}
.premium-teaser--large .premium-teaser__icon {
    font-size: 4.5rem;
}
