.ui-datepicker {
    font-size: 1rem;
    z-index:1100 !important; /* z-index higher than modal */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    @media (max-width: 767px) {
        font-size: .9375rem;
    }
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    color:inherit;
    font-size: .9375rem;
    border: 0 !important;
}