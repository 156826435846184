.slider .slick-dots>li>button {
    outline: 0;
}

/* arrows base */
.slider .slick-arrow {
    outline: 0;
    text-indent: 0;
    padding:0;
    font-size:.875rem;
    height: calc(40rem/16);
    width: calc(40rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: opacity .12s;
    @media (max-width: 767px) {
        height: calc(30rem/16);
        width: calc(30rem/16);
        font-size:.625rem;
    }
}
.slider .slick-arrow.slick-disabled {
    cursor: not-allowed;
    /*pointer-events: none;*/
}

/* arrows white */
.slider--white-arrows .slick-arrow {
    background: #fff;
    color: var(--color-text-default);
    border: .125rem solid var(--color-text-default);
    @media (max-width: 767px) {
        border-width:1px;
    }
}
.slider--white-arrows .slick-arrow:hover,
.slider--white-arrows .slick-arrow:focus {
    background: var(--color-text-default);
    color: #fff;
}

/* arrows dark */
.slider.recommendation-slider .slick-arrow,
.slider.slider--dark-arrow-btns .slick-arrow {
    background: var(--color-text-default);
    color: #fff;
    left: 1.25rem;
    outline: 0;
    z-index:5;
}
.slider.recommendation-slider .slick-arrow:hover,
.slider.recommendation-slider .slick-arrow:focus,
.slider.slider--dark-arrow-btns .slick-arrow:hover,
.slider.slider--dark-arrow-btns .slick-arrow:focus {
    background: #000;
}
.slider.recommendation-slider .slick-arrow.slick-next,
.slider.slider--dark-arrow-btns .slick-arrow.slick-next {
    left: auto;
    right:1.25rem;
}
.slider.recommendation-slider .slick-arrow .icon,
.slider.slider--dark-arrow-btns .slick-arrow .icon {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}



@media screen and (min-width: 1800px){
    .slider.recommendation-slider:not(.recommendation-slider--in-modal) .slick-arrow{
        left: -3.5rem;
    }
    .slider.recommendation-slider:not(.recommendation-slider--in-modal) .slick-arrow.slick-next{
        left: auto;
        right:-3.5rem;
    }
}

/* dots */
.slider .slick-dots > li:not(.slick-active) > button:focus {
    background: var(--slider-dot-background);
}