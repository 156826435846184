.shipping-address{
    border: calc(2rem / 16) solid transparent;
    position: relative;
    font-size: calc(14rem / 16);
    line-height: 1.875;
    padding:1.75rem;
    background: white;
    min-height: calc(335rem/16);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px){
        margin-bottom: calc(15rem / 16);
        padding:1.25rem;
        min-height: auto;
    }
}

/* adaptions for dhl in addresse-form */
.select-dhl-address__result .shipping-address{
    min-height: auto;
}
.select-dhl-address__result .shipping-address__bottom{
    display: none;
}


.shipping-address--blank{
    border: 0;
    padding: 0 calc(40rem / 16) 0 0;
    background:transparent;
    min-height: auto;
    display: block;
    flex-direction: unset;

    @media screen and (max-width: 767px){
        margin-bottom: calc(15rem / 16);
        padding: 0 calc(40rem / 16) 0 0;
    }
}

/* standalone shipping item */
.standalone-shipping .shipping-address{
    min-height: auto;
    display: block;
    flex-direction: unset;
}
.standalone-shipping .shipping-address__bottom {
    margin-top:1rem;
}

.shipping-address.is-active {
    border-color: var(--color-gabor);
}
.shipping-address__bottom {
    transition: opacity 200ms ease-in-out;
    margin-top: calc(24rem / 16);
    @media (min-width: 768px) {
        margin-top: auto;
    }
}
.is-active .shipping-address__bottom {
    opacity: 0;
}

.shipping-address__icons-top-right {
    position: absolute;
    top: 0;
    right: 0;
    @media (min-width: 768px) {
        top: calc(8rem / 16);
        right: calc(8rem / 16);
    }
}

.shipping-address--blank .shipping-address__icons-top-right {
    top: 0;
    right: 0;
}

.shipping-address__icons-bottom-right {
    position: absolute;
    bottom: calc(-2rem / 16);
    right: calc(-2rem / 16);
}

.shipping-address__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    font-size: calc(18rem / 16);
}

.shipping-address__icon--edit {
    font-size: calc(16rem / 16);
    @media (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.shipping-address__icon--edit-auto {
    width: auto;
    height: auto;
}
.shipping-address__icon--remove {
    font-size: calc(22rem / 16);
}
.shipping-address__icon--remove .icon {
    transform: rotate(45deg);
}
.shipping-address--selected {
    display: none;
}
.is-active .shipping-address--selected{
    display: flex;
}

.shipping-address__icon.bg-primary {
    color: white;
    background: var(--color-gabor) !important;
}
