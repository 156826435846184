.prime-hero-new {
    background: var(--bg-standard);
}
.prime-hero-new__text-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
    @media (min-width: 1200px) {
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}
.prime-hero-new__heading {
    font-size: calc(40rem / 16);
    margin: 0;
    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(100rem / 16);
    }
}

.prime-hero-new__text {
    font-size: calc(20rem/16);
    margin-top: calc(8rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(16rem/16);
        margin-top: calc(12rem/16);
        margin-bottom: calc(16rem/16);
    }
}

.prime-hero-new__foot {
    margin-top: auto;
}

.prime-hero-new__body {
    margin-top: auto;
    margin-bottom: calc(20rem/16);
}

.prime-hero-new__benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
}
