@media (min-width: 768px) {
    .breadcrumb {
        margin: calc(20rem/16) 0;
    }
}
@media screen and (max-width: 767px) {
    .breadcrumb {
        display: inline-block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: wrap;
        padding-bottom: calc(10rem/16);
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
        margin-bottom:0;
        scroll-behavior: smooth;
    }
    .magazine .breadcrumb {
        margin-top: calc(16rem/16);
    }
    .breadcrumb-wrapper .container.container {
        padding:0;
    }
}

.breadcrumb-item  {
    font-size:.75rem;
    @media screen and (max-width: 767px) {
        display: inline;
    }
}
.breadcrumb-item  {
    color:var(--color-medium-grey);
}
.breadcrumb-item a  {
    color:inherit;
}
.breadcrumb-item .icon {
    color:inherit;
    position: relative;
    top:.125rem;
}
.breadcrumb-item.active  {
    color:#444;
}
.breadcrumb-item+.breadcrumb-item:before {
    color:var(--color-medium-grey);
}

.breadcrumb__backlink {
    display: inline-block;
    font-size:.75rem;
    font-family: var(--font-default-semi-bold);
}
.breadcrumb__backlink .icon {
    font-size:.5rem;
    position: relative;
    vertical-align: .1em;
    margin-right: .25rem;
}