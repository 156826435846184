.shiptrack-collapse {
    display: block;
    padding:.75rem 0;
    border-top:2px solid var(--color-light-grey);
    border-bottom:2px solid transparent;
    margin:.625rem 0;
}
.shiptrack-collapse.collapsed {
    border-color:var(--color-light-grey);
}
.shiptrack-collapse__icon {
    font-size: .625rem;
    margin-left:.75rem;
    transform: rotate(90deg);
}
.collapsed .shiptrack-collapse__icon {
    transform: rotate(270deg);
}

.shiptrack-collapse-details {

    @media screen and (min-width:1200px) {
        padding-left:calc(147rem / 16);
    }
}




.shiptrack-status-bubble {
    position: relative;
    display: block;
    background: var(--color-text-default);
    color: #fff;
    border-radius: 100%;
    width: calc(30rem / 16);
    height: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        width:calc(40rem / 16);
        height:calc(40rem / 16);
    }

}

.shiptrack-status-bubble > .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size:calc(18rem/16);
    }
}

.shiptrack-status-bubble > .icon-items-shipped {
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size:calc(14rem/16);
    }
}


/* detailpage */
.shiptrack-info {
    padding:1rem;
    background:#fff;
    height:100%;
    @media screen and (min-width:768px) {
        padding:1.5rem;
    }
}

.shiptrack-box {
    padding:2.5rem 1rem 1.5rem 1rem;
    background:#fff;
    @media screen and (max-width:767px) {
        margin-bottom:3rem;
    }
    @media screen and (min-width:768px) {
        padding-top:3rem;
        padding-bottom:1.875rem;
        height:100%;
    }
}

.shiptrack-box__hl {
    font-size:calc(17rem / 16);
}
.shiptrack-box__desc {
    font-size:calc(15rem / 16);
}

.shiptrack-box__bubble {
    position: absolute;
    top:calc(-25rem / 16);
    left:50%;
    transform: translateX(-50%);
    display: block;
    background: var(--color-text-default);
    color: #fff;
    border-radius: 100%;
    width: calc(50rem / 16);
    height: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        top:calc(-30rem / 16);
        width:calc(60rem / 16);
        height:calc(60rem / 16);
    }

}

.shiptrack-box__bubble > .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size:calc(20rem/16);
    }
}