.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*//background: #808080;*/
    background: rgba(255, 255, 255, .7);
    z-index: 301;
    text-align: center;


    @media screen and (max-width: 767px){
        position: fixed;
    }
}


@media screen and (max-width: 767px){
    .loading-overlay--xs-absolute {
        position: absolute;
    }
}