.product-icon.icon-gabor-sale:before{
    font-size: calc(40rem / 16);
}

.icon.icon--red:before{
    color: #BE1A1A;
}

.product-icon.icon-gabor-IMTV:before{
    font-size: calc(40rem / 16);
}