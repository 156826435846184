.success-area,
.feedback-formular{
    max-width: 58.5rem;
    background: #fff;
    padding:1.5rem;
    margin:2rem auto;
    @media screen and (min-width: 768px) {
        padding:4rem;
        margin:4rem auto;
    }
}

.feedback-formular .form-group{
    margin-bottom: 0;
}

.feedback-formular .custom-radio__box + .custom-radio__text {
    font-size:.75rem;
}

.feedbackForm__submit-button{
    margin-top: 1rem;
}

.feedback-formular label{
    width: 100%;
}