.teaser-classic {

}
.teaser-classic__img {
    margin-bottom:2.25rem;
    @media screen and (max-width: 767px){
        margin-bottom:1.125rem;
    }
}
.teaser-classic__body {
    max-width:33rem;
}
.teaser-classic__topline {
    font-size: .875rem;
    letter-spacing: 2px;
    margin-bottom:.25rem;
    font-family: var(--font-default);
    text-transform: uppercase;
    @media screen and (max-width: 767px){
        font-size: .75rem;
    }
}
.teaser-classic__title {
    font-size: 1.875rem;
    letter-spacing: 1px;
    font-family: var(--font-default-bold);
    margin-bottom:.5rem;
    @media screen and (max-width: 767px){
        font-size: 1.25rem;
        margin-bottom:.75rem;
    }
}
.teaser-classic__txt {
    font-size:1rem;
    line-height:1.875;
    margin-bottom:1.125rem;
    @media screen and (max-width: 767px){
        margin-bottom:.75rem;
    }
}


/* SIZE ADAPTIONS */
/*one col*/
.teaser-classic--one-col .teaser-classic__img {
    @media screen and (max-width: 767px){
        margin-left:-1rem;
        margin-right:-1rem;
        width:auto;
    }
}
.teaser-classic--one-col .teaser-classic__body {
    max-width:65rem;
}




/*three col*/
.teaser-classic--three-col .teaser-classic__img {
    display: block;
    max-width:calc(275rem / 16);
}
@media screen and (min-width: 768px){
    .teaser-classic--three-col .teaser-classic__title {
        font-size: 1.125rem;
    }
}


/*four col*/
@media screen and (min-width: 768px){
    .teaser-classic--four-col .teaser-classic__title {
        font-size: 1rem;
    }
    .teaser-classic--four-col .teaser-classic__topline {
        font-size: .75rem;
    }
}