.color-link{
    width: calc(15rem / 16);
    height: calc(15rem / 16);
    border: 1px solid var(--color-primary); /* black in xd design */
    border-radius: 50%;
}

.color-link.more-colors{
    position: relative;
}
.color-link.more-colors:after{
    font-family: iconfont;
    content: var(--icon-plus);
    position: absolute;
    top: calc(2rem/16);
    left: calc(2rem/16);
    font-size: 9px;
}

.color-link:hover{
    transform: scale(1.5);
}

.view-more-colors{
    line-height: 1;
    position: absolute;
    top: 59%;
    left: 48%;
    transform: translate(-50%, -50%)rotate(45deg);
    color: var(--color-primary);
}

.product-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    padding: calc(15rem/16);
    @media (min-width: 768px) {
        padding: calc(20rem/16);
    }
}
.pimcore_area_product-grid .product-teaser:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    @media (pointer: coarse) {
        box-shadow: none;
    }
}
.product-teaser__sizes {
    display: none;
    background:#fff;
    padding: calc(20rem/16);
    position:absolute;
    left:0;
    right:0;
    top:calc(100% - 1rem);
    z-index:11;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
}
.pimcore_area_product-grid .product-teaser:hover .product-teaser__sizes {
    /* show sizes ony in grid, not in product-sliders */
    display:block;
    @media (pointer: coarse) {
        display:none;
    }
}

.product-teaser--colors .list-inline-item:not(:last-child){
    margin-right: 0;
}


/* in same height slider */
@media (min-width: 768px) {
    .slider--same-height .product-teaser {
        display: flex;
        flex-direction: column;
        flex: auto;
        height:100%;
    }
    .slider--same-height .product-teaser .product-teaser__detail {
        display: flex;
        flex-direction: column;
        flex: auto;
        height: auto;
    }
}


.tooltip .tooltip-inner {
    background-color: #ececec;
    color: black;
}

.tooltip .arrow:before{
    border-top-color: #ececec;
}

.old-price{
    text-decoration: line-through;
}
.product-teaser__topline {
    margin-bottom:.125rem;
    text-transform: uppercase;
}
.product-teaser__detail {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: auto;
}

.product-teaser .product-teaser--colors{
    min-height: calc(17rem / 16);
}

/* additional */
.product-teaser.product-teaser--additional {
    padding:0;
}
.product-teaser.product-teaser--additional .product-teaser__detail {
    padding: calc(20rem/16);
}

.product-teaser.product-teaser--additional .product-teaser__link {
    margin-top:1.25rem;
}

/* themes */
.theme-gabor .product-teaser,
.theme-rollingsoft .product-teaser {
    border-radius:6px;
}