.old-price {
    white-space: nowrap;
}

/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-light-blue { color: var(--color-info-blue); }


/* Background Color Helper */
.bg-redesign-primary {
    background-color: var(--color-redesign-primary);
}
.bg-light-grey {
    background-color: var(--color-light-grey);
}
.bg-medium-grey {
    background-color: var(--color-medium-grey);
}
.bg-turquoise {
    background-color: var(--color-turquoise);
}

/* Additional */
.tooltip {
    pointer-events: none;
    z-index:200;
}

.border-bottom-1 {
    border-bottom:1px solid rgba(0,0,0,.125) !important;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.z-index-1{
    z-index: 1;
}

.img-circle {
    border-radius: 100%;
}

.content-visibility-auto {
    content-visibility: auto;
}

.text-decoration-underline{
    text-decoration: underline;
}

.link--hover:hover{
    text-decoration: underline;
}
.custom-checkbox__text a,
.link--underline:not(.btn){
    text-decoration: none;
    height: fit-content;
    padding-bottom: 1px;
    font-family: var(--font-default-semi-bold);
    border-bottom: 1px solid;
}

.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus,
.custom-checkbox__text a:active,
.link--underline:not(.btn):hover{
    border-bottom: 1px solid transparent;
}

.line-height-1{
    line-height: 1;
}
.line-height-2 {
    line-height: 2;
}

@media screen and (min-width: 768px){
    .sticky-box {
        position: sticky;
        right: 0;
        top: 0;
    }
}

.bg-redesign-primary {
    background-color: var(--color-redesign-primary);
}
.bg-highlight {
    background-color: #fff;
}
body.magazine .bg-highlight {
    background-color: var(--color-light-grey);
}



.bg-md-primary{
    @media screen and (min-width: 768px){
        background-color: var(--color-primary) !important;
        color: white;
    }
}

.border-md-left{
    @media screen and (min-width: 768px){
        border-left: 1px solid var(--color-primary);
    }
}

.border-md-bottom{
    @media screen and (min-width: 768px){
        border-bottom: 1px solid var(--color-primary);
    }
}

.border-color-grey{
    border-color: #d1d1d1 !important;
}

.text-small{
    font-size: calc(12rem / 16);
}

.h-100{
    height: 100%;
}

.w-md-33{
    @media screen and (min-width: 768px) {
        width: 33% !important;
    }
}

.w-md-67{
    @media screen and (min-width: 768px) {
        width: 67% !important;
    }
}

.text-light-grey{
    color: var(--color-light-grey)!important;
}

.text-muted{
    color: var(--color-text-muted)!important;
}

.text-md-muted{
    @media screen and (min-width: 768px){
        color: var(--color-text-muted)!important;
    }
}

.text-dark-grey {
    color: var(--color-dark-grey)!important;;
}

.twitter-typeahead{
    width: 100%;
}

.text-big{
    font-size: calc(22rem / 16);
}

.text-no-wrap{
    white-space: nowrap;
}

.js-go-to-link,
.cursor-pointer,
.clickable {
    cursor: pointer;
}

.fz-10{
    font-size: calc(10rem / 16) !important;
}

.fz-11{
    font-size: calc(11rem / 16) !important;
}

.fz-12{
    font-size: calc(12rem / 16) !important;
}

.fz-13{
    font-size: calc(13rem / 16) !important;
}

.fz-14{
    font-size: calc(14em / 16) !important;
}

.fz-15{
    font-size: calc(15rem / 16) !important;
}

.fz-16{
    font-size: calc(16rem / 16) !important;
}

.fz-18{
    font-size: calc(18rem / 16) !important;
}

.fz-20{
    font-size: calc(20rem / 16) !important;
}

.z-index-2{
    z-index: 2!important;
}

.break-word{
    word-break: break-word;
}

.break-word-initial{
    word-break: initial;
}

.half-opacity{
    opacity: 0.5;
}


.mb-10px{
    margin-bottom: calc(10rem / 16);
}


.mb-40px{
    margin-bottom: calc(40rem / 16);
}

.mb-90px{
    margin-bottom: calc(90rem / 16);
}

.mt-40px {
    margin-top: calc(40rem / 16);
}

@media (min-width: 768px) {
    .mt-md-40px {
        margin-top: calc(40rem / 16) !important;
    }

    .mb-md-40px{
        margin-bottom: calc(40rem / 16) !important;
    }
}

.pt-30px {
    padding-top: calc(30rem / 16) !important;
}

.pb-30px {
    padding-bottom: calc(30rem / 16) !important;
}

.decorated-link {
    position: relative;
    padding-bottom: calc(6rem / 16);
}
.decorated-link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(1rem / 16);
    background: currentColor;
    transition: opacity 200ms ease-in-out;
}
.decorated-link:hover:after {
    opacity: 0;
}

.decorated-link--sm {
    font-size: calc(14rem / 16);
    letter-spacing: calc(1.5rem / 16);
}
.decorated-link__icon {
    font-size: calc(9rem / 16);
}

/* font helper */
.font-bold {
    font-family: var(--font-default-bold);
}

.font-semi-bold {
    font-family: var(--font-default-semi-bold);
}

@media (max-width: 768px) {
    .no-container-padding-mobile {
        margin-left: -1rem;
        margin-right: -1rem;
    }

}

.letter-spacing-1 {
    letter-spacing: calc(1rem/16);
}
.letter-spacing-reset {
    letter-spacing: initial;
}

.embed-responsive-item.embed-responsive-item--center-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.embed-responsive-item--center-bottom__img {
    max-height: 100%;
    max-width: 100%;
}
.no-transition {
    transition: none !important;
}

.overflow-visible {
    overflow: visible !important;
}
.pointer-events-none {
    pointer-events: none
}

#warning-static-release {
    display: none;
}

.uc-embed-container {
    background: transparent !important;
}

.uc-embed-list-headline,
.uc-embed-list-headline-description {
    padding-left:0 !important;
}
