.toggle-pw-btn{
    position: absolute;
    right: 0;
    top: 1.375rem;
    margin: auto 0;
    background:transparent;
    border: none;
    font-size: calc(18rem/16);
    padding: .625rem .75rem;
    color:inherit;
    -webkit-appearance: none;

    @media screen and (min-width: 768px) {
        top: 1.5rem;
        padding: calc(12rem/16) calc(18rem/16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(11rem/16) calc(24rem/16);
    }
}
.toggle-pw-btn:focus,
.toggle-pw-btn:hover{
    color: #000;
    box-shadow: none;
    outline: 0;
}
.toggle-pw-btn .icon{
    vertical-align: -0.1em;
}
.toggle-pw--hide{
    display: none;
}
.toggle-pw--show{
    display: inline-block;
}
.toggle-pw-btn.active .toggle-pw--hide{
    display: inline-block;
}
.toggle-pw-btn.active .toggle-pw--show{
    display: none;
}