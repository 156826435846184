.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12{
    position: relative;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (min-width: 1440px){
    .col-xxl-1{
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xxl-2{
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-xxl-3{
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xxl-4{
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xxl-5{
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-6{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7{
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xxl-8{
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xxl-9{
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10{
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xxl-11{
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xxl-12{
        flex: 0 0 100%;
        max-width: 100%;
    }

    /* offset */
    .offset-xxl-0 {
        margin-left: 0;
    }
    .offset-xxl-1 {
        margin-left: 8.33333%;
    }
    .offset-xxl-2 {
        margin-left: 16.66667%;
    }
    .offset-xxl-3 {
        margin-left: 25%;
    }
    .offset-xxl-4 {
        margin-left: 33.33333%;
    }

    /* right offset */
    .right-offset-xxl-1 {
        margin-right: 8.33333%;
    }
    .right-offset-xxl-2 {
        margin-right: 16.66667%;
    }
    .right-offset-xxl-3 {
        margin-right: 25%;
    }
    .right-offset-xxl-4 {
        margin-right: 33.33333%;
    }
}