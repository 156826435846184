.l-account-layout {
    @media screen and (min-width: 768px) {
        margin-top:3rem;
    }
}

.l-account-layout-main,
.l-account-layout__main {
    @media screen and (min-width: 1200px) {
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
    }
}