.download-teaser__btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    transition: transform .3s;
    z-index: 1;
    padding-bottom: calc(20rem/16);
    transform: translateZ(0);
    will-change: auto;

transform: translateY(100%);

}

.download-teaser__img-wrapper {
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    will-change: auto
}

.download-teaser:hover .download-teaser__btns {
    transform: translateY(0);
}

@media screen and (hover: none) {
    .download-teaser__img-wrapper .download-teaser__btns {
        transform: translateY(-100%);
    }
}

.download-teaser__img {
    position: relative;
    will-change: auto
}

.download-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-primary);
    opacity: 0;
    transition: opacity .3s;
}
@media screen and (hover: hover) {
    .download-teaser__img:hover:after {
        opacity: .3;
    }
}

.download-teaser-btn.download-teaser-btn {
    min-width: calc(40rem/16);
    min-height: calc(40rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(4rem/16);
}