body .lg-backdrop{
    background-color: white;
}

.lg-outer .lg-toolbar{
    background-color: transparent;
}

.lg-outer .lg-toolbar .lg-icon:hover{
    color: #333;
}

.lg-outer .lg-actions .lg-next,
.lg-outer .lg-actions .lg-prev,
.lg-outer .lg-actions .lg-next:hover,
.lg-outer .lg-actions .lg-prev:hover{
    color: #333;
    background-color: transparent;
}

.lg-outer #lg-zoom-in,
.lg-outer #lg-zoom-out{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    background:#fff;
    font-size: calc(32rem / 16);
    padding: 0;
    justify-content: center;
    align-items: center;
}

.lg-outer #lg-zoom-out{
    transform: translateY(100%);
}

.lg-outer .lg-close.lg-icon:after,
#lg-zoom-in.lg-icon:after,
#lg-zoom-out.lg-icon:after{
    font-family: iconfont;
}

#lg-zoom-in.lg-icon:after{
    content: var(--icon-plus);
}

#lg-zoom-out.lg-icon:after{
    content: var(--icon-minus);
}

.lg-outer .lg-close.lg-icon{
    width: 80px;
    height: 80px;
    font-size: calc(38rem / 16);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#fff;
}
.lg-outer .lg-close.lg-icon:after{
    transform: rotate(45deg);
    content: var(--icon-plus);
}

.lg-outer #lg-actual-size,
.lg-toogle-thumb.lg-icon{
    display: none;
}

.lg-outer .lg .lg-thumb-outer{
    background-color: white;

    @media screen and (max-width: 767px){
        display: none;
    }
}

.lg-outer .lg .lg-thumb-item.active,
.lg-outer .lg .lg-thumb-item:hover{
    border-radius: 0;
    border: none;
    border-bottom: 2px solid var(--color-primary);
}


.lg-outer .lg-video-cont.lg-has-iframe{
    @media screen and (min-width: 768px){
        vertical-align: initial;
    }
    @media screen and (min-width: 992px){
        vertical-align: top;
    }
}

body .lg-outer .lg-object,
body .lg-outer .lg-video .lg-object{
    @media screen and (min-width: 768px){
        height: 80vh;
        vertical-align: top;
    }
}

.lg-video-cont.lg-has-iframe{
    height: 100%;
}

.lg-outer .lg-video {
    @media screen and (max-width: 767px){
        padding-bottom: 100% !important;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        padding-bottom: 45.25% !important;
    }
    @media screen and (min-width: 992px){
        padding-bottom: 45.25% !important;
    }
}

.lg-prev.lg-icon,
.lg-next.lg-icon{
    display: none !important;
}

.lg-outer .lg-object.lg-image{
    @media screen and (min-width: 768px){
        height: 80vh !important;
    }
}

.lg-video-cont.lg-has-iframe .lg-video{
    height: 80vh;
}

@keyframes lg-loading-spinner {
    to {transform: rotate(360deg);}
}
.lg-item:not(.lg-complete):before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid var(--color-light-grey);
    border-top-color: var(--color-primary);
    animation: lg-loading-spinner .6s linear infinite;
}