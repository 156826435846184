/*todo: ab test cleanup (.ab-cart-trust, ab-visible)*/
.footer {
    font-size:calc(12rem/16);
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(80rem/16);
        padding-bottom: calc(60rem/16);
        font-size:calc(14rem/16);
    }
}
.magazine .footer {
    @media (max-width: 767px) {
        padding-top: 7rem;
    }
}
.footer-top {
    border-bottom:1px solid #ccc;
    padding-bottom: calc(30rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(80rem/16);
        margin-bottom: calc(60rem/16);
    }
}
.magazine .footer-top {
    @media (max-width: 767px) {
        padding-top: calc(30rem/16);
        border-top:1px solid #ccc;
    }
}
.footer-top {
    text-align: center;
}
.footer-top__wysiwyg a:not(.btn) {
    font-family: var(--font-default-bold);
    display: inline-block;
    background: var(--color-light-grey);
    padding:.125rem .625rem;
    text-decoration: none;
}
.footer-top__wysiwyg a:not(.btn):hover,
.footer-top__wysiwyg a:not(.btn):focus {
    background: transparent;
}
.footer--checkout .footer-top__nl-faq {
    display: none;
}
.footer-benefits {
    font-family: var(--font-default-bold);
    margin-bottom: calc(24rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(80rem/16);
    }
}

.footer-top__newsletter {
    text-align: left;
    background-color: var(--color-light-grey);
}
.footer-top__newsletter-body {
    padding: 1.5rem;
    max-width:calc(580rem/16);
    margin:0 auto;
    @media screen and (min-width: 768px) {
        padding: 2rem;
    }
}

.footer-top__newsletter-img {
    min-height:100%;
}
.footer-top__newsletter-img .embed-responsive-item {
    object-fit: cover;
}



.footer-main {
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(60rem/16);
    }
}
.footer-foot {
    border-top:1px solid #ccc;
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}


/* checkout footer */
.footer--checkout {
    padding-top: 1.5rem;
    @media screen and (min-width: 768px) {
        padding-top: 3rem;
    }
}
.footer--checkout>.container {
    display: flex;
    flex-flow: row wrap;

}
.footer--checkout .footer-top {
    order:2;
    width: 100%;
    border:0;
    @media screen and (max-width: 767px) {
        border:0;
    }
}
.footer--checkout .footer-benefits {
    order:1;
    width: 100%;
    border-bottom:1px solid #ccc;
    padding-bottom: 1.5rem;
    @media screen and (min-width: 768px) {
        padding-bottom: 3rem;
    }
}
.footer--checkout .footer-main {
    order:3;
    width: 100%;
}
.footer--checkout .footer-foot {
    order:4;
    width: 100%;
}



.sticky-add-to-cart .footer-foot {
    padding-bottom: calc(100rem/16);
}

footer .text-muted {
    color:#999 !important;
}
@media screen and (min-width: 768px) {
    .footer__collapse {
        margin-bottom:3.5rem;
    }
    .footer-main .footer__collapse .collapse {
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .footer__collapse {
        margin:0 .5rem;
        border-bottom:1px solid #ccc;
    }
    .footer__linklist--social .strong {
        font-size:calc(14rem/16);
        font-family: var(--font-default);
    }
}
.footer__collapse-main {
    letter-spacing: 1px;
    display:block;
    font-family:var(--font-default-bold);

    @media screen and (min-width: 768px) {
        margin-bottom:.25rem;
    }
    @media screen and (max-width: 767px) {
        position:relative;
        font-size:calc(14rem/16);
    }
}
.footer__collapse-main:not(.footer__collapse-main--link-only) {
    @media screen and (min-width: 768px) {
        pointer-events:none;
    }
}

@media screen and (max-width: 767px) {
    .footer__collapse-main  > a {
        display: block;
        padding:1.25rem 0 1.25rem 0;
    }
    .footer__collapse-main:not(footer__collapse-main--link-only) .footer__collapse-link {
        position: absolute;
        right: calc(10rem/16);
        top: 50%;
        transform: translateY(-50%) rotate(-270deg);
        transition: transform .2s ease;
    }
    .footer__collapse-main:not(footer__collapse-main--link-only) .collapsed .footer__collapse-link {
        transform: translateY(-50%) rotate(-90deg);
    }

}

.footer__collapse-main--link-only .footer__collapse-link {
    display: none;
}




/* ab test faq accordion
.ab-cart-trust .footer-top .footer__collapse {
    margin:0;
    border-bottom:1px solid #ccc;
}
@media screen and (min-width: 768px) {
    .ab-cart-trust .footer-top .footer__collapse-main {
        margin-bottom:0;
    }
    .ab-cart-trust .footer-top .footer__collapse-main>a {
        display: block;
        position:relative;
        pointer-events:initial;
        padding:1.25rem 0 1.25rem 0;
    }
    .ab-cart-trust .footer-top .footer__collapse-link {
        display: block;
        position: absolute;
        right:.25rem;
        top:1.5rem;
        transform: rotate(-90deg);
    }
    .ab-cart-trust .footer-top .footer__collapse-link:after {
        font-family: iconfont;
        font-size: .625rem;
        content:var(--icon-arrow-right);
    }
    .ab-cart-trust .footer-top .footer__collapse-link.collapsed,
    .ab-cart-trust .footer-top .collapsed .footer__collapse-link {
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 767px) {
    .footer__collapse-link {
        display: block;
        position: absolute;
        right:.25rem;
        top:1.5rem;
        transform: rotate(-90deg);
    }
    .footer__collapse-link:after {
        font-family: iconfont;
        font-size: .625rem;
        content:var(--icon-arrow-right);
    }
    .footer__collapse-link.collapsed,
    .collapsed .footer__collapse-link {
        transform: rotate(90deg);
    }
}
*/

.footer__copy {
    color:#999 !important;
}
.footer__copy:after {
    position: relative;
    top:-5px;
    content:'';
    background: #ccc;
    width:2.5rem;
    height:1px;
    display: block;
    margin: 1rem 0 .5rem 0;

    @media screen and (min-width: 768px) {
        display: inline-block;
        margin: 0 2.5rem;
    }
}
.footer__footlist {
    letter-spacing: 1px;
}
.footer__linklist {
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
        margin-bottom:1.5rem;
    }
}
.footer__linklist > li {
    line-height:1.5;
    margin-bottom:.5rem;
}
.footer__footlist a {
    margin-right:.5rem;
    @media screen and (min-width: 768px) {
        margin-right:2.5rem;
    }
}
.footer__footlist a,
.footer__linklist:not(.footer__linklist--social) a {
    padding-bottom:1px;
    border-bottom:1px solid transparent;
}
.footer__footlist a:hover,
.footer__footlist a:focus,
.footer__linklist:not(.footer__linklist--social) a:hover,
.footer__linklist:not(.footer__linklist--social) a:focus {
    border-bottom:1px solid var(--color-primary);
}
.footer__linklist--social a {
    display: inline-block;
    margin-top:.5rem;
    margin-right:1.125rem;
}
.footer__linklist--social a:hover,
.footer__linklist--social a:focus {
    color:#000;
}
.footer__linklist--social a .icon {
    color:inherit;
    font-size:1.5rem;
    @media screen and (max-width: 767px) {
        position:relative;
        top:.375rem;
    }
}
.footer__payment-icon {
    width: auto;
    height: calc(20rem/16);
    @media (min-width: 1600px) {
        height: 1.75rem;
    }
}
.footer__payment-icon--large {
    height: calc(28rem/16);
    @media (min-width: 1600px) {
        height: 2.5rem;
    }
}
.footer__valantic {
    display: inline-block;
    width:auto;
    height:1rem;
    margin-top:1.75rem;
    @media screen and (min-width: 768px) {
        height:1.25rem;
        margin-top:2.5rem;
    }
}