.recommendation-slider {
    margin:0 -20px;

    @media screen and (max-width: 767px) {
        overflow-x:hidden;
        width:100vw;
    }
}
.recommendation-slider.recommendation-slider--in-modal {
    width: 100%;
    margin:0;
}
.recommendation-slider .redesign-classic-teaser,
.recommendation-slider .product-teaser {
    margin:0 20px;
}

.modal-is-loading .recommendation-slider .product-teaser {
    opacity: 0;
    @media screen and (min-width: 768px) {
        max-height:50vh;
    }
}