:root {
    --account-card-padding-x: calc(18rem / 16);
    --account-card-padding-y: 1rem;

    @media screen and (min-width: 768px) {
        --account-card-padding-x: calc(30rem / 16);
        --account-card-padding-y: calc(24rem / 16);
    }
}

.account-card {
    background: #fff;
}

.account-card__header {
    padding: 1rem var(--account-card-padding-x);
    font-size: calc(14rem / 16);
}

.account-card__body {
    padding: var(--account-card-padding-y) var(--account-card-padding-x);
}
.account-card__body--prime {
    background: var(--bg-standard);
}

.account-card__body:not(:first-child) {
    border-top: 2px solid var(--color-light-grey);
}

.account-card__footer {
    border-top: 2px solid var(--color-light-grey);
    padding: 1rem var(--account-card-padding-x);
}

.account-card__title {
    margin-bottom: 0;
    font-size: calc(14rem / 16);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
    }
}
.account-card__link {
    font-size: calc(12rem / 16);
    line-height: 1.25;


    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.account-card__link + .account-card__link {
    margin-left: 1.25rem;
}
.account-card__link-text {
    text-decoration: none;
    height: fit-content;
    padding-bottom: 1px;
    font-family: var(--font-default-semi-bold);
    border-bottom: 1px solid;
}
.account-card__link-text + .icon {
    margin-left: .3125rem;
}
.account-card__link:hover .account-card__link-text {
    border-bottom: 1px solid transparent;
}
.account-card__body-img {
    @media screen and (min-width: 576px) {
        width: calc(117rem / 16);
    }
}