.accordion .card {
    background: transparent;
}
.accordion .card h2 {
    margin: 0;
}
.accordion__item {
    border-bottom:1px solid var(--color-text-default);
}
.accordion .accordion__hl {
    font-size:1rem;
    font-family: var(--font-default-bold);
    padding: 1rem 0;
    @media screen and (max-width: 767px) {
        font-size:.875rem;
        padding: .625rem 0;
    }
}
.accordion__body,
.accordion .card-body {
    line-height:2;
    padding:0 0 1.875rem 1.25rem;
}
.accordion__header-link .accordion__icon{
    font-size: calc(18rem / 16);
    font-family: var(--font-default-bold);
    padding: 0 calc(20rem / 16);
    transform: rotate(180deg);
}
.accordion__header-link .accordion__icon--absolute{
    position: absolute;
    right: 0;
}

.accordion__header-link .accordion__icon:before{
    content: '-';
    font-family: var(--font-default-bold);
}

.accordion__header-link.collapsed .accordion__icon:before{
    content: '+';
    font-family: var(--font-default-bold);
}