.teaser-card {
    height:100%;
    background: #fff;
}
.magazine .teaser-card {
    background: var(--color-light-grey);
}
.teaser-card__body {
    padding:2.5rem;
    @media screen and (max-width: 767px){
        padding:1.5rem;
    }
}
.teaser-card__title {
    font-size: 1.875rem;
    letter-spacing: 1px;
    font-family: var(--font-default-bold);
    margin-bottom:1rem;
    @media screen and (max-width: 767px){
        font-size: 1.25rem;
        margin-bottom:.75rem;
    }
}

.teaser-card--brand .teaser-card__img {
    @media screen and (max-width: 767px){
        display: none;
    }
}

.teaser-card__linklist>li {
    margin:.125rem 0;
}
.teaser-card__linklist a {
    padding-bottom: 1px;
    border-bottom: 1px solid transparent;
}
.teaser-card__linklist a:focus,
.teaser-card__linklist a:hover {
    border-bottom: 1px solid;
}
