.img-text-teaser__body {
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}
.img-text-teaser__body__title {
    font-size: calc(24rem / 16);
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}