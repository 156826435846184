/*contain - fix for svgs*/
.embed-responsive-contain {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: contain;
}

.embed-responsive-3by2:before {
    padding-top: 66.66667%;
}

.embed-responsive-8by5:before {
    padding-top: calc(5 / 8 * 100%);
}

.embed-responsive-classic-three:before {
    padding-top: 72.727272%;
}

.embed-responsive-header-img:before {
    padding-top: 50%;
    @media screen and (max-width: 767px){
        padding-top: 150%;
    }
}

.embed-responsive-newsletter:before {
    padding-top: 50%;
    @media screen and (max-width: 767px){
        padding-top: 100%;
    }
}

.embed-responsive-header-img.embed-responsive-header-img--small:before {
    padding-top: calc(1% / 2 * 100);
    @media (min-width: 768px) {
        padding-top: calc(450% / 1688 * 100);
    }
}

.embed-responsive-3d-iframe:before {
    padding-top: 50%;
    @media screen and (max-width: 767px){
        padding-top: 85%;
    }
}

.embed-responsive-prime-explain:before {
    padding-top: 135%;
}

.embed-responsive-3by2-teaser-card:before {
    padding-top: 70.33%;
}

.embed-responsive-3by2-thumbs:before {
    padding-top: 65.66667%;
}

.embed-responsive-2by3:before {
    padding-top: 150%;
}

.embed-responsive-3by4:before {
    padding-top: 133.3333%;
}

.embed-responsive-1by1:before {
    padding-top: 100%;
}

.embed-responsive-grid{
    &::before {
        padding-top: 132%
    }
}

@media (max-width: 767px) {
    .embed-responsive-7by3-xs:before {
        padding-top: calc(3% / 7 * 100);
    }

}

.embed-responsive-center-img,
.embed-responsive-center-img picture {
    display: flex;
    justify-content: center;
    align-items: center;
}

.embed-responsive-center-img .embed-responsive-item {
    position: static;
}