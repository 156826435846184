.prime-card {
    padding:1.5rem;
    position: relative;
    background: var(--bg-standard);
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.2);
    @media screen and (min-width: 768px) {
        min-height:24rem;
        margin:0 auto;
        max-width:calc(840rem / 16);
        overflow:hidden;
    }
}

.prime-card__hand {
    width: auto;
    height:22rem;
    display: none;
    position: absolute;
    left:-2rem;
    top:4.25rem;
    z-index:1;
    @media screen and (min-width: 768px) {
        display: block;
    }
}
.prime-card__title {
    position: relative;
    font-size: 1rem;
    font-family: var(--font-default-bold);
    margin-bottom:.875rem;
    z-index:2;
    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
        margin-bottom:.75rem;
        max-width: 13rem;
        margin-left: auto;
        margin-right: auto;
    }
}
.prime-card__icon {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    background:#fff;
    border-radius: 100%;
    width:1.875rem;
    height:1.875rem;
    line-height: 1.625rem;
    text-align: center;
    z-index:2;
}