.mw-checkout {
    max-width:calc(650rem/16);
}

.payment-method > label {
    cursor: pointer;
}
.payment-method--disabled {
    opacity: .6;
    cursor: not-allowed;
}
.payment-method--disabled>label {
    pointer-events: none;
}
.payment-disabled-invoice {
    border: .125rem solid #DCDCDC;
}
.payment-disabled-invoice__divider {
    margin: 0 1rem .75rem 1rem;
    border-top: .125rem solid #DCDCDC;
}
.payment-disabled-invoice__infobutton {
    margin: 0 1rem 1rem 1rem;
    padding-left:2.25rem;
    position: relative;
    font-size:.875rem;
    @media screen and (min-width: 768px) {
        margin-left:1.5rem;
    }
}
.payment-disabled-invoice__infobutton > .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:0;
    font-size:1.25rem;
}

.payment-options .custom-radio{
    border-bottom: 1px solid var(--color-primary);
    padding-bottom: calc(15rem / 16);
    margin-bottom: calc(10rem / 16);
}

.cart-checkout-products .checkout-products-mobile{
    display: block;
}

.checkout-products-mobile{
    display: none;
}

.checkout-products-mobile.is-open{
    display: block;
}

/*
.table.table--cart .table-cart__cell{
    @media screen and (max-width: 575px) {
        width: 90%;
    }
}

.table.table--cart .table-cart__thead .table-cart__cell,
.table.table--cart .table-cart__tbody .table-cart__cell{
    border-top: none;
}

.table.table--cart .table-cart__tbody{
    border-top: none;
    border-bottom: 1px solid var(--color-primary);
}

.table-cart__tbody + .table-cart__tbody {
    margin-top: calc(20rem/16);
}

.table.table--cart .table-cart__thead{
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.table.table--cart .table-cart__cell {
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.table.table--cart .table-cart__tr {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.table.table--cart .table-cart__tr .table--cart-product {
    @media screen and (max-width: 767px) {
        flex: 2 0 50%;
    }
}

.table.table--cart .table-cart__tr .table--cart-product .cart-product .cart-product__text{
    margin-bottom: calc(10rem / 16);
}

.table.table--cart .table-cart__tr .table-cart__cell:not(.table--cart-product){
    @media screen and (max-width: 767px) {
        flex: 1 0 50%;
        max-width: 50%;
    }
}

.table.table--cart .table-cart__tr .table-cart__cell.table--cart-delete{
    @media screen and (max-width: 767px) {
        width: initial;
        position: absolute;
        top: 0;
        right: 0;
    }
}
*/


.cart-amount {
    max-width: calc(96rem / 16);
}

.cart-amount .btn.btn-number-spinner {
    border: transparent;
    padding-left:.5rem;
    padding-right:.5rem;
}

.cart-amount__input {
    text-align: center;
    padding: .25rem;
    height: auto;
    border: none;
}

.change-shoesize__trigger{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
    width: 1.2rem;
    text-align: right;
}

.change-shoesize__select::-ms-expand {
    display: none;
}

.change-shoesize__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color:var(--color-text-default);
}

.change-shoesize__select.change-shoesize__select{
    width: calc(100rem/16);
    background-color: transparent;
    background-clip: padding-box;
    border-radius: 0;
}

.change-shoesize__select option:disabled {
    color: var(--color-light-grey);
}


.shipping-information__item {
    margin-top: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem / 16);
    }
}

.total-price__padding-md{
    @media screen and (min-width: 768px) and (max-width: 991px){
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.required-field{
    margin-top: 80px;
}


.dropdown.collapsed .dropdown-arrow{
    transform: rotate(270deg);
}

.dropdown .dropdown-arrow{
    transform: rotate(90deg);
}

.cart-refresh-btn{
    padding: 0 calc(6rem / 16);

    @media screen and (max-width: 767px){
        width: calc(45rem / 16);
    }
}

.modal-addShoeFailed .modal-header,
.modal-addShoeFailed .modal-body {
    padding: calc(30rem / 16);
}

.modal-addShoeFailed .modal-footer {
    padding: calc(20rem / 16);
}

.cart-product-image{
    padding-right: calc(10rem / 16);
    @media (max-width: 767px) {
        padding-right: calc(16rem / 16);
    }
}

.cart-product-image__img {
    width: calc(70rem / 16);
    height: auto;
    @media (min-width: 768px) {
        width: calc(110rem / 16);
    }
}

.cart-product-image--overlay .cart-product-image__img {
    width: calc(78rem/16);
}

.product-price{
    white-space: nowrap;
}

.e-mail-tooltip{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-right: 5px;
}

.e-mail-tooltip-container{
    padding-right: 25px;
}



.cart-product__heading{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.feedback-input{
    width: 100%;
}

.order-feedback .form-check-inline{
    margin-right: calc(6rem / 16);
    margin-left: calc(6rem / 16);

    @media screen and (max-width: 767px){
        margin:0;
        width: 8.5%;
    }
}
@media screen and (max-width: 767px){
    #feedbackForm_rating,
    #feedbackForm_rating .form-group{
        display: block;
        width: 100%;
    }
}

.order-feedback .custom-radio__box{
    margin-right: 0;
    width: 1.125rem;
    height: 1.125rem;
}

.order-feedback .custom-radio__text{
    white-space: nowrap;
}

.feedback-input__modifier{
    margin-bottom: calc(40rem / 16);
}

.feedback-subheadline{
    margin-bottom: calc(20rem / 16);
}

#feedbackForm_rating .custom-radio__text > span{
    display: none;
}
#feedbackForm_rating .form-check-inline:nth-child(n +11) .custom-radio__text > span{
    display: inline;
}

/* details mobile */
.order-details-btn {
    background:#fff;
    padding:1rem;
    display: block;
}
.order-details-mobile {
    background:#fff;
    padding:1rem;
}

/* sticky mobile btn */
.sticky-next-step-btn {
    display: none;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,.2);
}
@media screen and (max-width: 767px) {
    .sticky-next-step-btn {
        display: block;
        position: fixed;
        left:0;
        right:0;
        bottom:0;
        z-index:100;
        transform: translateY(10rem);
        transition: all .5s ease;
    }
    .sticky-next-step-btn.is-sticky {
        transform: translateY(0);
    }
}