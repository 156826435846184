@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-arrow-down:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-right-bold:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-bronze:before { content: "\EA06" }
.icon-burgermenu:before { content: "\EA07" }
.icon-calendar:before { content: "\EA08" }
.icon-cart-check:before { content: "\EA09" }
.icon-cart:before { content: "\EA0A" }
.icon-cashback:before { content: "\EA0B" }
.icon-check-rounded:before { content: "\EA0C" }
.icon-check:before { content: "\EA0D" }
.icon-checklist:before { content: "\EA0E" }
.icon-checkmark:before { content: "\EA0F" }
.icon-close:before { content: "\EA10" }
.icon-cloudfront:before { content: "\EA11" }
.icon-coupon:before { content: "\EA12" }
.icon-crown:before { content: "\EA13" }
.icon-customer-card:before { content: "\EA14" }
.icon-damen-ballerinas:before { content: "\EA15" }
.icon-damen-halbschuhe:before { content: "\EA16" }
.icon-damen-hausschuhe:before { content: "\EA17" }
.icon-damen-pumps:before { content: "\EA18" }
.icon-damen-sandalen:before { content: "\EA19" }
.icon-damen-sneaker:before { content: "\EA1A" }
.icon-damen-stiefel:before { content: "\EA1B" }
.icon-damen-stiefletten:before { content: "\EA1C" }
.icon-damen-taschen:before { content: "\EA1D" }
.icon-damenschuhe:before { content: "\EA1E" }
.icon-data:before { content: "\EA1F" }
.icon-declined:before { content: "\EA20" }
.icon-delay:before { content: "\EA21" }
.icon-delete:before { content: "\EA22" }
.icon-delivered:before { content: "\EA23" }
.icon-deny:before { content: "\EA24" }
.icon-desktop-screen:before { content: "\EA25" }
.icon-dhl:before { content: "\EA26" }
.icon-discount-percent:before { content: "\EA27" }
.icon-download:before { content: "\EA28" }
.icon-edit:before { content: "\EA29" }
.icon-elements:before { content: "\EA2A" }
.icon-eye-crossed-out:before { content: "\EA2B" }
.icon-eye:before { content: "\EA2C" }
.icon-facebook:before { content: "\EA2D" }
.icon-filter:before { content: "\EA2E" }
.icon-free-shipping:before { content: "\EA2F" }
.icon-friends:before { content: "\EA30" }
.icon-gabor-IMTV:before { content: "\EA31" }
.icon-gabor-logo:before { content: "\EA32" }
.icon-gabor-sale:before { content: "\EA33" }
.icon-gift:before { content: "\EA34" }
.icon-gold:before { content: "\EA35" }
.icon-hand-heart:before { content: "\EA36" }
.icon-healthy:before { content: "\EA37" }
.icon-heart-filled:before { content: "\EA38" }
.icon-heart:before { content: "\EA39" }
.icon-help:before { content: "\EA3A" }
.icon-herren-halbschuhe:before { content: "\EA3B" }
.icon-herren-hausschuhe:before { content: "\EA3C" }
.icon-herren-sandalen:before { content: "\EA3D" }
.icon-herren-sneaker:before { content: "\EA3E" }
.icon-herren-stiefel:before { content: "\EA3F" }
.icon-herrenschuhe:before { content: "\EA40" }
.icon-home:before { content: "\EA41" }
.icon-hurry:before { content: "\EA42" }
.icon-info:before { content: "\EA43" }
.icon-information:before { content: "\EA44" }
.icon-instagram:before { content: "\EA45" }
.icon-items-shipped:before { content: "\EA46" }
.icon-language:before { content: "\EA47" }
.icon-mail-outline:before { content: "\EA48" }
.icon-mail:before { content: "\EA49" }
.icon-menu-search:before { content: "\EA4A" }
.icon-minus:before { content: "\EA4B" }
.icon-more:before { content: "\EA4C" }
.icon-new:before { content: "\EA4D" }
.icon-news:before { content: "\EA4E" }
.icon-newsletter:before { content: "\EA4F" }
.icon-order-in-progress:before { content: "\EA50" }
.icon-order:before { content: "\EA51" }
.icon-orders:before { content: "\EA52" }
.icon-package:before { content: "\EA53" }
.icon-paper:before { content: "\EA54" }
.icon-part-delivery:before { content: "\EA55" }
.icon-phone:before { content: "\EA56" }
.icon-pinterest:before { content: "\EA57" }
.icon-play:before { content: "\EA58" }
.icon-plus:before { content: "\EA59" }
.icon-poi:before { content: "\EA5A" }
.icon-preferences:before { content: "\EA5B" }
.icon-premium:before { content: "\EA5C" }
.icon-print:before { content: "\EA5D" }
.icon-production-location:before { content: "\EA5E" }
.icon-related:before { content: "\EA5F" }
.icon-return-window:before { content: "\EA60" }
.icon-return:before { content: "\EA61" }
.icon-sale:before { content: "\EA62" }
.icon-search-plus:before { content: "\EA63" }
.icon-search:before { content: "\EA64" }
.icon-searchmenu:before { content: "\EA65" }
.icon-silver:before { content: "\EA66" }
.icon-similar:before { content: "\EA67" }
.icon-size-finder:before { content: "\EA68" }
.icon-size_profile:before { content: "\EA69" }
.icon-sustainable:before { content: "\EA6A" }
.icon-threesixty:before { content: "\EA6B" }
.icon-truck:before { content: "\EA6C" }
.icon-trust:before { content: "\EA6D" }
.icon-undefined:before { content: "\EA6E" }
.icon-user:before { content: "\EA6F" }
.icon-voucher:before { content: "\EA70" }
.icon-warning:before { content: "\EA71" }
.icon-youtube:before { content: "\EA72" }


        :root {
--icon-arrow-down: "\EA01";
    --icon-arrow-left: "\EA02";
    --icon-arrow-right-bold: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-up: "\EA05";
    --icon-bronze: "\EA06";
    --icon-burgermenu: "\EA07";
    --icon-calendar: "\EA08";
    --icon-cart-check: "\EA09";
    --icon-cart: "\EA0A";
    --icon-cashback: "\EA0B";
    --icon-check-rounded: "\EA0C";
    --icon-check: "\EA0D";
    --icon-checklist: "\EA0E";
    --icon-checkmark: "\EA0F";
    --icon-close: "\EA10";
    --icon-cloudfront: "\EA11";
    --icon-coupon: "\EA12";
    --icon-crown: "\EA13";
    --icon-customer-card: "\EA14";
    --icon-damen-ballerinas: "\EA15";
    --icon-damen-halbschuhe: "\EA16";
    --icon-damen-hausschuhe: "\EA17";
    --icon-damen-pumps: "\EA18";
    --icon-damen-sandalen: "\EA19";
    --icon-damen-sneaker: "\EA1A";
    --icon-damen-stiefel: "\EA1B";
    --icon-damen-stiefletten: "\EA1C";
    --icon-damen-taschen: "\EA1D";
    --icon-damenschuhe: "\EA1E";
    --icon-data: "\EA1F";
    --icon-declined: "\EA20";
    --icon-delay: "\EA21";
    --icon-delete: "\EA22";
    --icon-delivered: "\EA23";
    --icon-deny: "\EA24";
    --icon-desktop-screen: "\EA25";
    --icon-dhl: "\EA26";
    --icon-discount-percent: "\EA27";
    --icon-download: "\EA28";
    --icon-edit: "\EA29";
    --icon-elements: "\EA2A";
    --icon-eye-crossed-out: "\EA2B";
    --icon-eye: "\EA2C";
    --icon-facebook: "\EA2D";
    --icon-filter: "\EA2E";
    --icon-free-shipping: "\EA2F";
    --icon-friends: "\EA30";
    --icon-gabor-IMTV: "\EA31";
    --icon-gabor-logo: "\EA32";
    --icon-gabor-sale: "\EA33";
    --icon-gift: "\EA34";
    --icon-gold: "\EA35";
    --icon-hand-heart: "\EA36";
    --icon-healthy: "\EA37";
    --icon-heart-filled: "\EA38";
    --icon-heart: "\EA39";
    --icon-help: "\EA3A";
    --icon-herren-halbschuhe: "\EA3B";
    --icon-herren-hausschuhe: "\EA3C";
    --icon-herren-sandalen: "\EA3D";
    --icon-herren-sneaker: "\EA3E";
    --icon-herren-stiefel: "\EA3F";
    --icon-herrenschuhe: "\EA40";
    --icon-home: "\EA41";
    --icon-hurry: "\EA42";
    --icon-info: "\EA43";
    --icon-information: "\EA44";
    --icon-instagram: "\EA45";
    --icon-items-shipped: "\EA46";
    --icon-language: "\EA47";
    --icon-mail-outline: "\EA48";
    --icon-mail: "\EA49";
    --icon-menu-search: "\EA4A";
    --icon-minus: "\EA4B";
    --icon-more: "\EA4C";
    --icon-new: "\EA4D";
    --icon-news: "\EA4E";
    --icon-newsletter: "\EA4F";
    --icon-order-in-progress: "\EA50";
    --icon-order: "\EA51";
    --icon-orders: "\EA52";
    --icon-package: "\EA53";
    --icon-paper: "\EA54";
    --icon-part-delivery: "\EA55";
    --icon-phone: "\EA56";
    --icon-pinterest: "\EA57";
    --icon-play: "\EA58";
    --icon-plus: "\EA59";
    --icon-poi: "\EA5A";
    --icon-preferences: "\EA5B";
    --icon-premium: "\EA5C";
    --icon-print: "\EA5D";
    --icon-production-location: "\EA5E";
    --icon-related: "\EA5F";
    --icon-return-window: "\EA60";
    --icon-return: "\EA61";
    --icon-sale: "\EA62";
    --icon-search-plus: "\EA63";
    --icon-search: "\EA64";
    --icon-searchmenu: "\EA65";
    --icon-silver: "\EA66";
    --icon-similar: "\EA67";
    --icon-size-finder: "\EA68";
    --icon-size_profile: "\EA69";
    --icon-sustainable: "\EA6A";
    --icon-threesixty: "\EA6B";
    --icon-truck: "\EA6C";
    --icon-trust: "\EA6D";
    --icon-undefined: "\EA6E";
    --icon-user: "\EA6F";
    --icon-voucher: "\EA70";
    --icon-warning: "\EA71";
    --icon-youtube: "\EA72";
    
}