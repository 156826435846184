.size-selector {
    position: relative;
    margin:0;
    padding:0;
    border:0;
    background: transparent;
    -webkit-appearance: none;
}
.size-selector__size-icon {
    display: block;
    width: 1.125rem;
    height:1.125rem;
    line-height: 1.125rem;
    position: absolute;
    left:-.25rem;
    top:-.25rem;
    border-radius: 50%;
    text-align: center;
    color:#fff;
    font-size:.75rem;
    background: #000;
}
.size-selector__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.size-selector__label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: calc(40rem/16);
    min-height: calc(40rem/16);
    padding: 0 calc(4rem/16);
    border: 1px solid var(--color-primary);
    cursor: pointer;
    font-size: calc(12rem/16);
    line-height: 1;
}

.size-selector__label-icon {
    font-size: calc(10rem/16);
    margin-bottom: calc(4rem/16);
}

.size-selector__input:checked ~ .size-selector__label{
    color:#fff;
    background: var(--color-primary);
}
.size-selector__label:hover {
    background: var(--color-light-grey);
}
.size-selector__input:disabled ~ .size-selector__label {
    border-color: var(--color-dark-grey);
    opacity: .5;
    cursor: not-allowed;
}

.size-selector--disabled .size-selector__label {
    color: var(--color-dark-grey);
}