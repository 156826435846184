.invoice-icon {
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invoice-icon__icon {
    font-size: calc(16rem/16);
}

.invoice-icon__text {
    font-family: var(--font-default-bold);
    font-size: calc(9rem/16);
    text-transform: uppercase;
    word-break: normal;
    margin-top: calc(2rem/16);
}