.hours__day {
    min-width: 6em;
    display: inline-block;
}

.hours__day--narrow {
    min-width: 2.5em;
}

.hours-collapse__btn__icon {
    transform: rotate(90deg);
    transition: transform .32s;
}

.hours-collapse__btn.collapsed .hours-collapse__btn__icon {
    transform: rotate(-90deg);
}