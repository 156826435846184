.hurry-up {
    position: relative;
    padding:.75rem .75rem .75rem 2.5rem;
    font-size: .75rem;
    background:#FFBD03;
    font-family: var(--font-default-semi-bold);
}
.hurry-up > .icon {
    font-size:1.125rem;
    position: absolute;
    left:.75rem;
    top:50%;
    transform: translateY(-50%);
}



@media screen and (max-width: 767px) {
    .hurry-up--no-icon-mobile {
        padding-left:.75rem;
    }
}

@media screen and (min-width: 768px) {
    .hurry-up {
        display: inline-block;
    }
}