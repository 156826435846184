.thumb-slider-main {
    position: relative;
}
.thumb-slider__nr {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    z-index: 1;
    color:#fff;
    font-size: .875rem;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
}
.thumb-slider__current {
    position: relative;
    font-size: 1.875rem;
}



/* thumb slider */
.slick-vertical.thumb-slider__thumbs .slick-slide {
    border: 0;
    line-height: 0;
}
.thumb-slider__thumb {
    margin: 0 0 1.625rem 0;

    @media screen and (min-width: 1680px){
        margin: 0 0 1.75rem 0;
    }
}

