.link-list {
    margin-left: calc(-10rem/16);
    margin-right: calc(-10rem/16);
    display: flex;
    flex-wrap: wrap;
}
.link-list-wrapper {
    position: relative;
}
@media screen and (max-width: 767px) {
    .link-list {
        display: block;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        padding:0 1rem calc(10rem/16) 1rem;
        margin-left: -1rem;
        margin-right: -1rem;
        position: relative;
    }
    .link-list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .link-list::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
        background-color:transparent;
    }
    .link-list::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        border-radius: .25rem;
    }
    .link-list-wrapper:after {
        transition: opacity 0.2s ease;
        width: calc(55rem/16);
        position: absolute;
        right: -1rem;
        top:0;
        bottom:.25rem;
        background: linear-gradient(270deg, #F5F5F5 0%, rgba(245,245,245,0) 100%);
        content:'';
        height: auto;
        opacity: 0;
        pointer-events: none;
    }
    .link-list-wrapper.scroll-indicator--visible:after {
        opacity:1;
    }
    .link-list-wrapper:before {
        transition: opacity 0.2s ease;
        position: absolute;
        z-index: 2;
        font-size: calc(10rem/16);
        right:0;
        bottom:calc(23rem/16);
        content: var(--icon-arrow-right);
        display: inline-block;
        font-family: iconfont;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        color: var(--color-primary);
        pointer-events: none;
        opacity:0;
    }
    .link-list-wrapper.scroll-indicator--visible:before {
        opacity:1;
    }
}


.link-list__item-wrapper {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    @media screen and (max-width: 767px) {
        display: inline-block;
        padding-left: calc(4rem/16);
        padding-right: calc(4rem/16);
    }
}
.link-list__item {
    background-color: #fff;
    font-size: calc(12rem/16);
    letter-spacing: calc(0.86rem/16);
    padding: calc(8rem/16) calc(11rem/16);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        letter-spacing: calc(0.5rem/16);
        padding: calc(10rem/16) calc(11rem/16);
    }
}
.link-list__item:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 calc(1rem/16) var(--color-primary);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.link-list__item:hover:before {
    opacity: 1;
}
.link-list__item-icon {
    color: var(--color-dark-grey);
    margin-left: calc(8rem/16);
    font-size: calc(9rem/16);
    transition: color 0.3s ease-in-out;
}
.link-list__item:hover .link-list__item-icon {
    color: var(--color-primary);
}