.back-link {
    display: inline-flex;
    align-items: center;
}

.back-link--navbar {
    position: absolute;
    left: 0;
    top: 18px;
    z-index: 1;
}

@media (max-width: 1439px) {
    .back-link--navbar.back-link--navbar {
        display: none;
    }
}

@media (min-width: 1440px) {
    .back-link--main-content.back-link--main-content {
        display: none;
    }
}