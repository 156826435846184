.navbar-teaser {
    transition: color 200ms ease-in-out;
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(22/16);
    }
}
.navbar-teaser__link {
    position: absolute;
    left: calc(32rem/16);
    right: calc(32rem/16);
    bottom: calc(32rem/16);
    font-size: calc(14rem/16);
    padding-bottom: calc(2rem/16);
    display: block;
    width: auto;
}
.navbar-teaser__img {
    transition: transform 250ms ease-in-out;
}
.navbar-teaser:hover .navbar-teaser__img {
    transform: scale(1.05);
}

.navbar-teaser__logo {
    width: calc(140rem/16);
    height: auto;
}