.color-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    color:#fff;
}

@media (min-width: 768px) {
    .color-tile:after {
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        right:-2rem;
        width:2rem;
        background: inherit;
    }
}
.color-tile__title {
    width: 100%;
    padding:1rem;
    font-size: calc(26rem/16);
    @media (min-width: 768px) {
        font-size: calc(34rem/16);
        max-width:calc(250rem / 16);
    }
    @media (min-width: 1200px) {
        font-size: calc(42rem/16);
    }
    @media (min-width: 1440px) {
        font-size: calc(48rem/16);
    }
}