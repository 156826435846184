.dsgvo-hint {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    width:15rem;
    padding:2rem;
    text-align:center;
    background: #fff;
    color:var(--color-text-default);
    z-index:7;
    display: none;
}
.dsgvo-hint__wysiwyg {
    font-size: calc(14rem / 16);
}
