.form-group {
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
    }
}
.newsletter-categories .form-group {
    margin-bottom:0;
}

.contact-form-sizer {
    max-width:calc(550rem / 16);
}
.guestform-sizer {
    margin:0 auto;
    max-width:calc(550rem / 16);
}

.form-block__legend {
    font-size: calc(22rem / 16);
    letter-spacing: normal;
    margin-bottom: calc(22rem / 16);
}
.form-block + .form-block {
    margin-top: 1rem;
}

.password-strength {
    position: relative;
}
.password-strength:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: transform 200ms ease-in-out, background 200ms ease-in-out;
    transform-origin: left center;
    transform: scaleX(0);
}
.is-low .password-strength:before {
    background-color: #ffafae;
    transform: scaleX(.25);
}
.is-medium .password-strength:before {
    background-color: #ffd6b3;
    transform: scaleX(.5);
}
.is-strong .password-strength:before {
    background-color: #c5eeac;
    transform: scaleX(.75);
}
.is-really-strong .password-strength:before {
    background-color: #81b562;
    transform: scaleX(1);
}

.input-group-append .btn,
.input-group-append .input-group-text {
    padding: calc(9rem/16) calc(12rem/16);
    border: 1px solid var(--color-primary);
    border-left: none;
    position: relative;
    z-index: 3;
    background: white;
    color: var(--color-primary);
    font-size: calc(14rem/16);
}
.input-group-append .btn-primary {
    background: var(--color-primary);
    color:#fff;
}
.form-group.is-invalid .input-group-append .btn,
.form-group.is-invalid .input-group-append .input-group-text {
    border-color: var(--color-danger);
}
.form-group.is-valid .input-group-append .btn,
.form-group.is-valid .input-group-append .input-group-text {
    border-color: var(--color-success);
}
.input-group-append .btn.input-group__btn--sm {
    padding: calc(11rem/16) calc(6rem/16);
    font-size: calc(11rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(13rem/16);
        padding: calc(9rem/16) calc(12rem/16);
    }
}

/*
.select{
    position: relative;
}

.select > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: calc(40rem/16);
    cursor: pointer;
}
.select select::-ms-expand {
    display: none;
}

.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-right);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 0;
    right: calc(20rem/16);
    font-size: calc(10rem/16);
    transform: rotate(90deg);
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-primary);
}*/

.form-control--transparent,
.form-control.form-control--transparent {
    display: inline-block;
    width: auto;
    background: transparent;
    border: none;
    padding: .5rem;
    margin: -.5rem;
    height: auto;
}
select.form-control--transparent,
select.form-control.form-control--transparent {
    padding-right: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 12px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

