.return-group{
    margin-bottom: calc(40rem / 16);
}

.shipping-adress--return{
    font-size: calc(14rem / 16);
}

.return-group__item{
    padding-top: calc(20rem / 16);
    padding-bottom: calc(20rem / 16);
}

.return-group__heading{
    margin-bottom: calc(10rem / 16);
}

.return-group .return-group__item:not(:last-of-type){
    border-bottom: 1px solid var(--color-secondary);
}

.return-group__image{
    padding-left: calc(10rem / 16);
    padding-right: calc(100rem / 16);
}

.return-reason{
    font-size: calc(14rem / 16);
}

.return-checkbox{
    font-size: calc(14rem / 16);
    margin-top: calc(15rem / 16);
}

.return-reason__container{

    @media screen and (min-width: 1200px){
        min-width: 300px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        min-width: 250px;
    }
}

.registered-return{
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px){
        font-size: 15px;
        margin-left: 10px;
        margin-top: 25px;
    }
}

.return-order-back{
    font-size: calc(14rem / 16);
}

/*table*/
.return-group__table{
    font-size: calc(14rem / 16);
    margin-bottom: 0;
    margin-top: calc(15rem / 16);
}
.return-group__table td{
    padding: 0;
    padding-bottom: calc(5rem / 16);
}
.return-group__table-number{
    width: 40px;
}
.table--lastOrders.table--lastOrders-rma{
    margin-top: 0;
}


.return-state{
    font-size: calc(13rem / 16);
    margin-bottom: calc(30rem / 16);
    margin-top: calc(10rem / 16);
}
.return-info{
    font-size: calc(13rem / 16);
}
.return-info__title{
    font-size: calc(18rem / 16);
}
.return-link{
    font-size: calc(12rem / 16);
}

.block-title.return-rma__title{
    margin-bottom: calc(15rem / 16);
}

.feedback-form{
    max-width: 450px;
}

.return-form{
    margin-bottom: calc(90rem / 16);
}

.title-block__sub-title.return-subtitle{
    margin-bottom: calc(60rem / 16);
    font-size: calc(25rem / 16);
}

.return-intro{
    font-size: calc(14rem / 16);
}

.return-intro .wysiwyg-spacer{
    margin-top: calc(22rem / 16);
    margin-bottom: calc(55rem / 16);
}

.return-shipment-start-form{
    max-width: calc(450rem / 16);
}