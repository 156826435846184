.dropdown-select__toggle {
    font-size: calc(14rem/16);
    border: 1px solid var(--color-medium-grey);
}
.dropdown-select__toggle {
    display: flex;
    align-items: center;
    padding: calc(12rem/16);
}

.dropdown-select__toggle-arrow {
    transform: rotate(90deg);
    font-size: calc(10rem/16);
}

.dropdown-select__dropdown-menu {
    margin-top: 0;
    font-size: calc(14rem/16);
}

.dropdown-select__dropdown-item {
    line-height: 1.1;
    padding: calc(4rem/16) calc(12rem/16) calc(8rem/16);
    white-space: normal;
}