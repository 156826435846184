.magazine-filter {
    margin-top: calc(20rem/16);
    padding-top:90px;
    transform: translateY(-90px);
    margin-bottom:-90px;
    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
        padding-top:150px;
        transform: translateY(-150px);
        margin-bottom:-150px;
    }
    @media screen and (max-width: 767px) {
        overflow-x: hidden;
    }
}
.magazine-filter__categories {
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}
.magazine-filter__categories a {
    font-family: var(--font-default-bold);
    padding-bottom:1px;
    border-bottom:1px solid transparent;
}
.magazine-filter__categories a:hover,
.magazine-filter__categories a:focus,
.magazine-filter__categories a.active {
    border-bottom:1px solid #000;
}
.nav-tabs--magazine-filter {
    border:0;
    margin: 0;
}
.nav-tabs--magazine-filter .nav-link {
    padding: 16px 40px;
    line-height: 1;
    font-size: calc(14rem / 16);
    background: #F5F5F5;
    border:1px solid transparent;
    margin: 0;
}
.nav-tabs--magazine-filter .nav-link:focus,
.nav-tabs--magazine-filter .nav-link:hover {
    border-color:transparent;
}
.nav-tabs--magazine-filter .nav-link.active {
    font-family: var(--font-default-bold);
    border:1px solid var(--color-primary);
    background: #fff;
}