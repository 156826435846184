.dealer-contact {
    padding: calc(20rem/16);
    background: white;
}

.dealer-contact.is-active {
    background: var(--color-secondary);
}

.dealer-contact + .dealer-contact {
    margin-top: 4px;
}

.dealer-contact__img {
    width: calc(28rem/16);
}