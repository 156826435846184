.grid-category-teaser {
    text-align: center;
    padding:2.75rem 5rem 3.5rem 5rem;
    background: var(--color-redesign-primary);
    color:var(--color-redesign-primary-contrast);
    @media screen and (max-width: 767px) {
        position: relative;
        margin:0 -1rem;
        padding:2rem 1.5rem .5rem 1.5rem;
    }
}
.grid-category-teaser--sale {
    background-color: var(--color-sale);
    color:#fff;
}
.grid-category-teaser__body {
    font-size:.875rem;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.grid-category-teaser__icon {
    font-size:calc(80rem / 16);
    line-height:1px;
    vertical-align:-.25em;
    @media screen and (max-width: 767px) {
        font-size:calc(40rem / 16);
    }
}

@media screen and (max-width: 767px) {
    .grid-category-teaser__icon.icon-damenschuhe,
    .grid-category-teaser__icon.icon-herrenschuhe {
        display: none;
    }
}

.grid-category-teaser__icon.icon-herren-sneaker {
    vertical-align:-.3em;
}