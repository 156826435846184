.portal-marken-teaser {
    position: relative;
    background:#fff;
    height:100%;
    padding:1.875rem;
    @media screen and (min-width: 768px){
        padding:2.5rem;
    }
}
.portal-marken-teaser__title {
    @media screen and (min-width: 768px) and (max-width: 1400px){
        font-size:1.5rem;
    }
}
.portal-marken-teaser__txt {
    font-size: .875rem;
}
.portal-marken-teaser__img {
    width:auto;
    max-width:15rem;
    @media screen and (max-width: 767px){
        height:calc(30rem / 16);
    }
    @media screen and (min-width: 768px) {
        height:calc(40rem / 16);
    }
}
.portal-marken-teaser__detail-img {
    margin:0 auto;
    display: block;
    height:calc(62rem / 16);
    width:auto;
}
.portal-marken-teaser__link {
    font-size:1rem;
    padding-bottom:.125rem;
    font-family: var(--font-default-bold);
    border-bottom:.125rem solid var(--color-primary);
    @media screen and (max-width: 767px){
        font-size: .875rem;
    }
}
.portal-marken-teaser__link:hover,
.portal-marken-teaser__link:focus {
    border-bottom:.125rem solid transparent;
}