.checkout-progress{
    margin: calc(32rem / 16) 0 0 0;
    color: var(--color-primary);
    @media screen and (min-width: 768px) {
        margin: calc(40rem / 16) 0 calc(20rem / 16);
    }
}
.checkout-progress__bar:not(.checkout-progress__bar--first):before{
    content: "";
    position: absolute;
    top: calc(10rem / 16);
    right: 10.375rem;
    left: -5.5rem;
    height: 2px;
    background-color: var(--color-primary);
    @media screen and (max-width: 767px) {
        right: calc(72rem/16);
        left: calc(-16rem/16);
    }
}


.checkout-progress__bar--disabled {
    color: var(--color-medium-grey);
}
.checkout-progress__bar--disabled:before{
    background-color: var(--color-medium-grey) !important;
}

.checkout-progress__text{
    font-size: calc(8rem / 16);
    letter-spacing: calc(2rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(7rem / 16);
        letter-spacing: calc(1rem / 16);
    }
    @media screen and (max-width: 374px) {
        display:none;
    }
}

.checkout-progress__bar__step{
    margin-left: auto;
    @media screen and (max-width: 767px) {
        width: calc(80rem / 16);
    }
    @media screen and (min-width: 768px) {
        width: 16rem;
    }
}
