@media (min-width: 768px){
    .size-advisor-modal .modal-dialog {
        max-width: 620px !important;
    }
}

.size-advisor-modal .modal__close.modal__close {
    color: #fff;
    opacity:1;
    font-family: 'iconfont';
    @media screen and (max-width: 767px) {
        padding: calc(12rem/16) calc(15rem/16);
    }
}
.size-advisor__header {
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(14rem/16);
    font-family: var(--font-default-semi-bold);
    padding: calc(15rem/16) calc(30rem/16);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.size-advisor__header-icon {
    font-size: calc(22rem/16);
    margin-right: calc(10rem/16);
}
.size-advisor__body {
    background-color: var(--color-light-grey);
    padding: calc(27rem/16) calc(20rem/16) calc(27rem/16);
    @media screen and (min-width: 768px) {
        font-size:calc(15rem / 16);
        padding: calc(30rem/16);
    }
}
.size-advisor__img {
    margin-left: auto;
    display: flex;
}
.size-advisor__title {
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.size-advisor__wysiwyg {
    font-size: calc(10rem/16);
    margin-bottom: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.size-advisor__wysiwyg > p {
    line-height: 1.5;
}
.size-advisor__features {
    margin-top: calc(60rem/16);
    text-align: center;
    padding-bottom: calc(20rem/16);
}
.size-advisor__features-title {
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.size-advisor__feature-title {
    font-size: calc(12rem/16);
    font-family: var(--font-default-semi-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.size-advisor__feature-text {
    font-size: calc(10rem/16);
    margin-top: calc(6rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.size-advisor__step-header {
    border-bottom: 1px solid var(--color-primary);
    background-color: var(--color-light-grey);
    height: calc(51rem/16);
}
.size-advisor__step-header__item {
    padding: calc(4rem/16);
    font-size: calc(11rem/16);
    font-family: var(--font-default-semi-bold);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (min-width: 768px) {
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
    }
}
.size-advisor__side-note {
    font-size: calc(8rem/16);
    margin-top: calc(14rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(12rem/16);
    }
}
.size-advisor__side-note:empty {
    display: none;
}
.size-advisor__side-note p {
    line-height:1.33;
}
.size-advisor__feature-icon {
    font-size: calc(23rem/16);
}