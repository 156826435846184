/*
.side-nav{
    line-height: 1;
    width: calc(215rem / 16);
    max-width: 100%;
}
.side-nav--sm {
    width: calc(185rem / 16);
}
.side-nav .side-nav__parent.active{
    border-left: 2px solid black;
}

.side-nav .side-nav__child{
    border-left: 1px solid #ececec;
}

.side-nav li{
    border-left: 3px solid transparent;
}

.side-nav .side-nav--active{
    border-left: 3px solid black;
}

.side-nav__first-layer{
    padding-bottom: 1rem;
    margin-bottom: .5rem;
}

.side-nav__second-layer{
    padding-left: calc(12rem / 16);
}

.side-nav__third-layer{
    margin-bottom: 1.5rem;
}

.side-nav__fourth-layer{
    margin-top: calc(17rem / 16);
    line-height: 1.5;
}

.side-nav__fourth-layer.active{
    border-left: none;
}
.side-nav__fourth-layer > a,
.side-nav__fourth-layer .side-nav__third-layer{
    padding-left: calc(11rem / 16);
}
.side-nav__fourth-layer.active > a{
    font-family: var(--font-default-bold);
    border-left: 2px solid black;
}

.side-nav__fourth-layer:first-of-type{
    margin-top: calc(17rem / 16);
}

.side-nav--grey:not(:first-of-type){
    margin-top: calc(4rem / 16);
}

.link--hover-spacing{
    text-decoration: none;
    height: fit-content;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
}

.link--hover-spacing:hover{
    text-decoration: underline;
    text-underline-position: under;
}*/

.side-nav {
    font-size: calc(14rem/16);
}


@media screen and (min-width: 768px) {
    .side-nav + .side-nav,
    .side-nav__item + .side-nav__item {
        margin-top: calc(32rem/16);
    }
    .side-nav__item__sub-level {
        margin-left: 2rem;
    }
    .side-nav__item__sub-level .side-nav__item {
        margin-top: calc(20rem/16)
    }
    .side-nav__item__heart {
        vertical-align: -.4em;
        margin-left: .375rem;
    }
}

.side-nav__item > .btn-no-styling,
.side-nav__item > a {
    border-bottom:1px solid transparent;
}

.side-nav__item.has-sale > .btn-no-styling,
.side-nav__item.has-sale > a {
    color: var(--color-danger);
}

.side-nav__item > .btn-no-styling:hover,
.side-nav__item > .btn-no-styling:focus,
.side-nav__item.side-nav--active > .btn-no-styling,
.side-nav__item > a:hover,
.side-nav__item > a:focus,
.side-nav__item.side-nav--active > a {
    border-bottom:1px solid inherit;
}
@media screen and (max-width: 767px) {
    .side-nav {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding-bottom: .625rem;
        margin-left: calc(-16rem/16);
        margin-right: calc(-16rem/16);
    }
    .side-nav::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .side-nav::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
        background-color:transparent;
    }
    .side-nav::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        border-radius: .25rem;
    }
    .side-nav__item {
        margin:0 0 0 1rem;
        white-space: nowrap;
        display: inline;
    }
    .side-nav__item > .btn-no-styling,
    .side-nav__item > a {
        display: inline-block;
        padding:.5rem 1rem;
        border-radius:10rem;
        border: 1px solid #000;
        color:#000;
        line-height:1;
        font-size: calc(13rem/16);
    }
    .side-nav__item.has-sale > .btn-no-styling,
    .side-nav__item.has-sale > a {
        border: 1px solid var(--color-danger);
        color: var(--color-danger);
    }
    .side-nav__item > .btn-no-styling:hover,
    .side-nav__item > .btn-no-styling:focus,
    .side-nav__item.active > .btn-no-styling,
    .side-nav__item > a:hover,
    .side-nav__item > a:focus,
    .side-nav__item.active > a {
        background:#000;
        color:#fff;
        border: 1px solid #000;
    }
    .side-nav__item:last-of-type {
        padding-right: calc(20rem/16);
    }
}