.product-grid-anchor {
    position: relative;
    pointer-events: none;
    position: relative;
    margin-top:calc(-180rem/16);
    padding-top:calc(180rem/16);

    @media (max-width: 767px) {
        margin-top:calc(-72rem/16);
        padding-top:calc(72rem/16);
    }
}
.grid-footer {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    padding:1rem;
    background:#fff;
    font-size:1.125rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: transform 250ms ease-in-out;
    transform: translateY(100px);

    @media (max-width: 767px) {
        font-size:.875rem;
        padding:0;
    }
}
.grid-footer--show {
    transform: translateY(0);
}
.grid-footer__btn .icon {
    font-size: 110%;
    position: relative;
    top:.125rem;
}