.wysiwyg-with-medium__additional-icon {
    flex: 0 0 calc(65rem/16);
}

.wysiwyg-with-medium__additional-icon img{
    max-height: 4rem;
}

.wysiwyg-with-medium__media-svg {
    width: 100%;
    max-width: 100%;
    height: auto;
    padding:1rem;
    max-height:9rem;
    @media (min-width: 768px) {
        max-height:12rem;
    }
}