/* default countdown with circles */
.countdown .countdown__item {
    color:inherit;
    display: inline-block;
    text-align: center;
    margin-right: .5rem;
}
.countdown .countdown__value {
    display: block;
    margin:0 auto;
    border:.25rem solid;
    border-radius:100%;
    width: 2.875rem;
    height:2.875rem;
    line-height:2.375rem;
    font-size:1rem;
    font-family: var(--font-default-bold);
}
.countdown .countdown-item__text {
    display: block;
    font-size:.625rem;
    white-space: nowrap;
    font-family: var(--font-default);
}


/* countdown inline */
.countdown-inline {
    white-space: nowrap;
}
.countdown-inline .countdown__item {
    color: inherit;
    display: inline-block;
    margin-right: .375rem;
}