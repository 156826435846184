.nav-teaser-scroll {
    display:block;
    position: relative;
    margin:-.5rem 0 .5rem .5rem;
    overflow-x: auto;
    padding:.75rem 0;
    background: var(--color-light-grey);
}
.nav-teaser-scroll-area--2 {
    width: calc((136rem/16 + .5rem) * 2);
}
.nav-teaser-scroll-area--3 {
    width: calc((136rem/16 + .5rem) * 3);
}
.nav-teaser-scroll-area--4 {
    width: calc((136rem/16 + .5rem) * 4);
}
.nav-teaser-scroll-area--5 {
    width: calc((136rem/16 + .5rem) * 5);
}
.nav-teaser-scroll-area--6 {
    width: calc((136rem/16 + .5rem) * 6);
}
.nav-teaser {
    padding: .5rem;
    background:#fff;
    text-decoration: none;
    display: inline-block;
    margin-right: .5rem;
    width: calc(136rem / 16);
    float:left;
}
.nav-teaser__img {
    width: calc(120rem/16);
    height: calc(160rem/16);
    display: block;
}
.nav-teaser__text {
    text-align: center;
    font-family: var(--font-default-bold);
    white-space: normal;
    font-size: .75rem;
    display: block;
    margin:.25rem 0 0 0;
    min-height: calc(34rem/16);
}