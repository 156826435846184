.size-advisor__btn {
    color:inherit;
    border: solid black thin;
    box-shadow: none;
    position: relative;
    padding: calc(9rem/16) calc(50rem/16);
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--color-light-grey);
}
.size-advisor__btn--small {
    padding: calc(9rem/16) calc(9rem/16) calc(9rem/16) calc(45rem/16);
}
.size-advisor__btn--edit-configuration {
    padding: calc(11rem/16) calc(10rem/16);
    text-align:left;
}
.size-advisor__btn-edit-text {
    font-size: calc(10rem/16);
    font-family: var(--font-default-semi-bold);
    text-align:left;
}
.size-advisor__btn-badge {
    font-size: calc(10rem/16);
    letter-spacing: calc(1rem/16);
    line-height: calc(12/10);
    color:#fff;
    padding: calc(6rem/16);
    text-transform: uppercase;
    background-color: var(--color-turquoise);
    position: absolute;
    top:0;
    left:0;
}
.size-advisor__btn-text {
    text-align: left;
}
.size-advisor__btn-text__title {
    font-size: calc(12rem/16);
    font-family: var(--font-default-semi-bold);
}
.size-advisor__btn-icon {
    font-size: calc(26rem/16);
}