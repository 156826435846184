html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(14rem / 16);
    word-break: break-word;

    @media screen and (min-width: 786px) {
        font-size: var(--font-size-default);
    }
}
.font-default.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

.font-family-bold{
    font-family: var(--font-default-bold) !important;
}

.font-family-semi-bold{
    font-family: var(--font-default-semi-bold) !important;
}

.font-family-md-bold{
    @media screen and (min-width: 768px){
        font-family: var(--font-default-bold) !important;
    }
}



.font-family-normal{
    font-family: var(--font-default) !important;
}

b,
strong,
.strong {
    font-weight: normal;
    font-family: var(--font-default-bold);
}
p{
    line-height: calc(24rem / 16);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--font-default-bold);
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: 700;
}

h1, .h1 {
    font-size: calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(60rem/16);
    }
}
h1.h1--large, .h1--large {
    text-transform:uppercase;
    letter-spacing: calc(8rem/16);
    font-size: calc(40rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(80rem/16);
        letter-spacing: calc(40rem/16);
    }
    @media screen and (min-width: 1440px){
        font-size: calc(120rem/16);
        letter-spacing: calc(80rem/16);
    }
}
h2, .h2 {
    font-size: calc(40rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(24rem/16);
    }
}
h3, .h3 {
    font-size: calc(30rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }

}
h4, .h4 {
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
    }
}
h5, .h5 {
    font-size: calc(14rem/16);
}
h6, .h6 {
    font-size: 1em;
}
.font-size-xs {
    font-size: .625rem;
}