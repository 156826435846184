.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg  table {
    line-height: 2;
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    text-decoration: underline;
    color: var(--color-primary);
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-primary);
    text-decoration: none;
}

.wysiwyg__with-bg-link a:not(.btn) {
    font-family: var(--font-default-bold);
    display: inline-block;
    background: var(--color-light-grey);
    padding:.125rem .625rem;
    text-decoration: none;
}
.wysiwyg__with-bg-link a:not(.btn):hover,
.wysiwyg__with-bg-link a:not(.btn):focus {
    background: transparent;
}

.wysiwyg ul{
    list-style: none;
    padding-left: 0;
}

.wysiwyg ul li{
    position: relative;
    padding-left: calc(32rem / 16);
}

.wysiwyg.wysiwyg--no-list-style ul li:before{
    content: none;
}

.wysiwyg ul li:before {
    position: absolute;
    font-family: "iconfont";
    content: var(--icon-check);
    font-weight:900;
    left: 0;
    top: calc(16rem / 16);
    transform: translateY(-50%);
    font-size: calc(10rem / 16);
}
.wysiwyg--benefits-list:not(.wysiwyg--benefits-list-small) ul li + li{
    margin-top: calc(5rem / 16);
}
.wysiwyg--benefits-list ul li:before{
    content: var(--icon-delivered);
    font-size: calc(18rem / 16);
}
.wysiwyg--benefits-list__icon {
    position: absolute;
    left: 0;
    top: calc(16rem / 16);
    transform: translateY(-50%);
    font-size: calc(18rem / 16);
}
.wysiwyg--benefits-list ul li.disabled-item{
    color:#ccc;
}
.wysiwyg--benefits-list ul li.disabled-item:before{
    content: var(--icon-deny);
    color:#ccc;
}
.wysiwyg--benefits-list-small ul li{
    font-size: calc(15rem / 16);
}
.wysiwyg--benefits-list-small ul li:before{
    font-size: calc(14rem / 16);
    top: 0.875rem;
}
.wysiwyg--benefits-list--custom-icons ul li:before {
    display: none;
}


.lead.wysiwyg ul li:before{
    top: calc(22rem / 16);
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6{
    color:var(--color-primary);
    margin-bottom: calc(15rem / 16);
}
.wysiwyg h2>a,
.wysiwyg h3>a,
.wysiwyg h4>a{
    text-decoration: none !important;
}
.wysiwyg p{
    min-height: 1px;
}

.wysiwyg-spacer{
    margin-top: calc(48rem / 16);
    margin-bottom: calc(48rem / 16);
}

.wysiwyg table {
    word-break: normal;
}

.wysiwyg .table td,
.wysiwyg .table th {
    padding: .5rem .75rem;
}

/* lead */
.lead {
    font-size: 1.125rem;
    @media (min-width: 768px) {
        font-size: 1.25rem;
    }
    @media (min-width: 1440px){
        font-size: 1.5rem;
    }
}

/* portal */
.wysiwyg--portal > p,
.wysiwyg--portal > ul,
.wysiwyg--portal > ol {
    line-height:1.6;
    font-size: 1rem;
    @media (min-width: 1440px){
        font-size: 1.25rem;
    }
}