.key-value-list {
    font-size: calc(14rem / 16);
}
.key-value-list dt {
    color: var(--color-dark-grey);
    margin: 0;
}
.key-value-list dd {
    font-family: var(--font-default-bold);
    margin: 0;
}


@media screen and (min-width: 768px) {
    .key-value-list {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        grid-auto-columns: 1fr;
        grid-gap: 0.25rem 2rem;
    }
    .key-value-list dt {
        grid-row: 1;
    }
    .key-value-list dd {
        grid-row: 2;
    }
}

@media screen and (max-width: 767px) {
    .key-value-list {
        font-size: calc(13rem / 16);
    }
    .key-value-list {
        margin-top: -.5rem;
    }
    .key-value-list dt {
        margin-top: .5rem;
    }
    /*.key-value-list {
        grid-template-columns: 1fr;
        grid-gap: 0.125rem 1rem;
    }
    .key-value-list dt:nth-child(4n-1) {
        grid-row: 3;
    }
    .key-value-list dd:nth-child(4n) {
        grid-row: 4;
    }*/
}

