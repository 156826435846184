.header-img {
    position: relative;

    @media screen and (max-width: 767px){
        margin-left:-1rem;
        margin-right:-1rem;
    }
}
.header-img__overlay {
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    text-shadow: 0 0 25px rgba(0,0,0,.35);
    padding:1.25rem;

    @media screen and (min-width: 768px){
        padding:0;
        bottom:auto;
        top:50%;
        transform: translateY(-50%);
    }
}
.header-img .header-img__hl{
    color:var(--color-primary);
}

/* theme headlines */
.theme-pius.header-img .header-img__hl span {
    color:var(--color-primary);
    padding:.25rem 1.125rem;
    text-transform: uppercase;
    background:#fff;
    text-shadow: none;
}
.theme-pius.header-img .header-img__hl.text-white span {
    color:#fff;
    padding:.25rem 1.125rem;
    text-transform: uppercase;
    background:var(--color-primary);
    text-shadow: none;
}

.theme-rollingsoft.header-img .header-img__hl{
    text-transform: uppercase;
    font-family: var(--font-default-semi-bold);
}



.header-img__btns {
    margin-top:2.5rem;
    text-shadow: none;
    @media screen and (max-width: 767px){
        margin-top:1.25rem;
    }
}

.header-img__btns .btn {
    margin-right:2.5rem;
    @media screen and (max-width: 767px){
        margin-right:1.25rem;
        margin-bottom:1.25rem;
    }
}

.text-right .header-img__btns .btn {
    margin-right:0;
    margin-left:2.5rem;
    @media screen and (max-width: 767px){
        margin-left:1.25rem;
        margin-bottom:1.25rem;
    }
}