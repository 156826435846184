
.testimonial-teaser__img {
    @media screen and (max-width: 767px){
        margin-top: calc(16rem/16);
    }
}
.testimonial-teaser__body {
    @media screen and (max-width: 767px){
        padding:1.5rem;
    }
}
