.portal-imageteaser {
    color:#fff;
    position: relative;
}
.portal-imageteaser__video {
    object-fit: cover;
    transform: scale(1.01);
}
.portal-imageteaser__body {
    text-align: center;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    padding:1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    @media screen and (min-width: 768px){
        padding:2.5rem;
    }
}
.portal-imageteaser__headline {
    font-size:calc(20rem / 16);
    margin-bottom:.875rem;
    text-shadow: 0 0 25px rgba(0,0,0,.35);
    @media screen and (min-width: 768px){
        font-size:calc(26rem / 16);
    }
}

.portal-imageteaser__headline--dark { color:var(--color-text-default) }
.portal-imageteaser__headline--left {
    position: relative;
    text-align: left;
    width: 100%;
}
.portal-imageteaser__headline--right {
    position: relative;
    text-align: right;
    width: 100%;
}
.portal-imageteaser__headline--top {
    position: absolute;
    top:1.5rem;
    padding:0 1.5rem;
    @media screen and (min-width: 768px){
        padding:0 2.5rem;
        top:2.5rem;
    }
}

.portal-categoryteaser {
    position: relative;
    background:#fff;
    padding:1rem 1rem 0 1rem;
    height:100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    /*@media screen and (min-width: 768px) and (max-width: 991px){
        padding-bottom:2rem;
    }*/
    @media screen and (min-width: 992px){
        padding:2rem 2rem 2.5rem 2rem;
    }
}
.portal-categoryteaser__logo {
    display: block;
    width: auto;
    height:1.75rem;
    margin:0 auto;
    @media screen and (min-width: 768px){
        height:2rem;
    }
}
.portal-categoryteaser__body {
    text-align: center;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
@media screen and (max-width: 991px){
    .portal-categoryteaser__btn-container {
        margin:0 -1rem;
        width:auto;
    }
    .portal-categoryteaser__btn-container .btn {
        width:100%;
    }
}

.portal-categorylinks {
    padding:calc(155rem / 16) 0 calc(55rem / 16) 0;
    background: var(--color-redesign-primary);
    color:var(--color-redesign-primary-contrast);
    margin-top:calc(-100rem / 16);

    @media screen and (max-width: 767px) {
        padding:calc(415rem / 16) 0 calc(30rem / 16) 0;
        margin-top:calc(-400rem / 16);
    }
}
.portal-category-linklist {
    font-family: var(--font-default-bold);
    position: relative;
    align-items:center;
    @media screen and (min-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;
    }
}
.portal-category-linklist__item {
    @media screen and (max-width: 540px) {
        flex:50%;
        max-width: 50%;
        margin:0 !important;
    }
    @media screen and (min-width: 541px) and (max-width: 991px) {
        flex:33%;
        max-width: 33%;
        margin:0 !important;
    }
}
.portal-category-linklist a {
    display: flex;
    align-items: center;
    color:inherit;

    @media screen and (max-width: 767px) {
        padding:.75rem 0;
    }
}
.portal-category-linklist a > .icon:not(.icon-arrow-right) {
    margin-right:.5rem;
    font-size:calc(80rem / 16);

    @media screen and (max-width: 767px) {
        font-size:calc(40rem / 16);
    }
}
.portal-category-linklist a > .icon.icon-arrow-right {
    margin-right:.375rem;
    font-size:calc(10rem / 16);
}
.portal-category-linklist a > .portal-category-linklist__link-text {
    display: inline-block;
    border-bottom:.125rem solid transparent;
}
.portal-category-linklist a:hover > .portal-category-linklist__link-text,
.portal-category-linklist a:focus > .portal-category-linklist__link-text {
    border-bottom:.125rem solid var(--color-redesign-primary-contrast);
}



/* portal-categoryteaser */
.portal-cat-teaser {
    display: none;

    @media screen and (max-width: 767px) {
        padding:calc(435rem / 16) 1rem calc(30rem / 16) 1rem;
        margin-top:calc(-400rem / 16);
        background: var(--color-redesign-primary);
        font-family: var(--font-default-bold);
        font-size:.75rem;
    }
}
.portal-cat-teaser__link {
    display: block;
}
.portal-cat-teaser__link > .icon.icon-arrow-right {
    margin-right:.125rem;
    font-size:calc(8rem / 16);
}