.custom-checkbox {
    position: relative;
    padding-left: 2.875rem;

    @media screen and (min-width: 768px) {
        padding-left:2rem;
    }

    @media screen and (max-width: 767px) {
        margin-bottom:.375rem;
    }

}
.custom-checkbox--inline {
    display: inline-block;
    margin-right: 1.5rem;
}
.custom-checkbox--inline .custom-checkbox__box {
    display: inline-block;
    top: .25rem;
    position: relative;
}


.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem / 16);
    overflow: hidden;
    border: 1px solid var(--color-primary);
    background: #ffffff;
    color: var(--color-primary);
    width: 1.625rem;
    height: 1.625rem;
    margin-top: -.125rem;

    @media screen and (min-width: 768px) {
        width:1.25rem;
        height:1.25rem;
        margin-top: 0.125rem;
    }

}
.custom-checkbox--centered-box .custom-checkbox__box {
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
}

.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(9rem / 16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box,
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    border-color: var(--color-primary);
    border-width:2px;
}
/*.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}*/
/*.has-error .custom-checkbox__input:focus ~ .custom-checkbox__box{
    border-color: var(--color-danger);
}*/
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}


/* checkbox sizes */
.custom-checkbox--sm {
    font-size: calc(14rem / 16);
}

.custom-checkbox--sm .custom-checkbox__box:before {
    font-size: calc(10rem / 16);
    @media screen and (min-width: 768px) {
        font-size:calc(8rem / 16);
    }
}

.custom-checkbox--sm .custom-checkbox__box {
    @media screen and (max-width: 767px) {
        margin-top: .125rem;
    }
}

/* checkbox with icons */
.large-checkbox-icon {
    background: #fff;
    font-size: 5rem;
    margin: 0 0 1rem -2.875rem;
    padding: 2rem 3rem;
    text-align: center;
    line-height: 1px;

    @media screen and (min-width: 768px) {
        padding: 2rem 4rem;
        margin: 0 0 0.625rem -2.25rem;
    }
}


/* checkbox with logos */
@media screen and (min-width: 768px) {
    .custom-checkbox--gabor ~ .custom-checkbox__text,
    .custom-checkbox--pius ~ .custom-checkbox__text {
        padding-right: 10.25rem;
        display: inline-block;
        position: relative;
    }
    .custom-checkbox--gabor ~ .custom-checkbox__text:after,
    .custom-checkbox--pius ~ .custom-checkbox__text:after {
        content:'';
        display: inline-block;
        width:9rem;
        height:1.5rem;
        position: absolute;
        top:-1px;
        right:0;
        background-position: 0 50%;
        background-repeat: no-repeat;
    }
    .custom-checkbox--gabor ~ .custom-checkbox__text:after {
        background-image: url(static/img/logo-gabor-sm.jpg);
    }
    .custom-checkbox--pius ~ .custom-checkbox__text:after {
        background-image: url(static/img/logo-pius-sm.jpg);
    }
}