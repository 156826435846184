.media-grid {

}
.media-grid__item {
    margin-bottom:1.5rem;
    @media screen and (min-width: 768px) and (max-width: 1439px){
        margin-bottom:2.5rem;
    }
}

.media-grid__caption {
    margin-top:1rem;
}
.media-grid__caption a {
    font-family: var(--font-default-bold);
    border-bottom: 1px solid;
}
.media-grid__caption a:hover,
.media-grid__caption a:focus {
    border-bottom: 1px solid transparent;
}
.media-grid__caption-links {
    margin-top:.5rem;
}
.media-grid__caption-links a {
    display: inline-block;
    margin-bottom:.25rem;
}