.circle-icon {
    display: inline-flex;
    height: 1.5em;
    width: 1.5em;

    background: white;
    border-radius: 100%;

    justify-content: center;
    align-items: center;
}

.circle-icon__icon {
    font-size: .5em;
}