.gallery-slider-main {
    position: relative;
}
.gallery-slider__nr {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    color:#fff;
    font-size: .875rem;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
}
.gallery-slider__current {
    position: relative;
    font-size: 1.875rem;
}

.slider.gallery-slider .slick-dots{
    top:auto;
    bottom:1.5rem;
    left:50%;
    transform: translateX(-50%);
    @media screen and (max-width: 767px){
        bottom:1rem;
    }
}
.slider.gallery-slider .slick-dots li {
    margin:0;
}
.slider.gallery-slider .slick-dots li button{
    border-radius: 0;
    background: #fff;
    padding: 0;
    border: 0;
    box-shadow: none;
    width: 2.5rem;
    height: .25rem;
    margin:0 .125rem;
    text-indent: -19999px;
    opacity:.3;
    outline: 0;
}
.slider.gallery-slider .slick-dots li button:hover,
.slider.gallery-slider .slick-dots li button:focus,
.slider.gallery-slider .slick-dots li.slick-active button{
    background: #fff;
    opacity:1;
}