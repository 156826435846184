.account-form{
    max-width: 450px;
}

.account-fieldset{
    margin-bottom: calc(40rem / 16);
}

.account-subtitle{
    margin-bottom: calc(25rem / 16);
}

.text-small-margin-top{
    margin-top: calc(45rem / 16);
}

.account-data__submit{
    margin-top: calc(24rem / 16);
    margin-bottom: calc(48rem / 16);
}

.default-country-select{
    margin-bottom: calc(15rem / 16);
}

.btn.btn__account,
.btn.btn__account:hover
{
    border: 4px solid darkred;
}

.tracking-number__container{
    margin-top: calc(30rem / 16);
    margin-bottom: calc(30rem / 16);
}
.tracking-number{
    font-size: calc(14rem / 16);
}

.account-subline {
    margin-bottom:.25rem;
    font-size: .9375rem;
    font-family: var(--font-default-bold);
}


/* side nav */
.account-side-nav {
    padding:1.5rem 3.25rem;
    margin:2.75rem 0;
    background: #fff;
}
.account-side-nav__list>li{
    margin:1.5rem 0;
}
.account-side-nav__list a {
    padding-bottom:1px;
    font-size:.9375rem;
    border-bottom:1px solid transparent;
    font-family: var(--font-default);
}

.account-side-nav__list li a:hover,
.account-side-nav__list li a:focus,
.account-side-nav__list li.side-nav--active a {
    border-bottom:1px solid var(--color-primary);
}
.account-side-nav__list li.side-nav--active a {
    font-family: var(--font-default-bold);
}

/* overview */
.account-overview__link {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding:.75rem;
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: .875rem;
    height:5.5rem;
    background: #fff;
}
.account-overview__txt {
    margin-top: auto;
}