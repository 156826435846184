.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}
.vertical-gutter--20 {
    margin-top: calc(-20rem/16);
}
.vertical-gutter--20 > .vertical-gutter__item {
    margin-top: calc(20rem/16);
}
.vertical-gutter--30 {
    margin-top: calc(-30rem/16);
}
.vertical-gutter--30 > .vertical-gutter__item {
    margin-top: calc(30rem/16);
}
.vertical-gutter--40 {
    margin-top: calc(-40rem/16);
}
.vertical-gutter--40 > .vertical-gutter__item {
    margin-top: calc(40rem/16);
}
.vertical-gutter--60 {
    margin-top: calc(-60rem/16);
}
.vertical-gutter--60 > .vertical-gutter__item {
    margin-top: calc(60rem/16);
}

@media (min-width: 768px) {
    .vertical-gutter--md-40 {
        margin-top: calc(-40rem/16);
    }
    .vertical-gutter--md-40 > .vertical-gutter__item {
        margin-top: calc(40rem/16);
    }
}


.vertical-gutter--100 {
    margin-top: calc(-60rem/16);

    @media (min-width: 992px){
        margin-top: calc(-100rem/16);
    }
}
.vertical-gutter--100 > .vertical-gutter__item {
    margin-top: calc(60rem/16);

    @media (min-width: 992px){
        margin-top: calc(100rem/16);
    }
}
