.prime-explain {

}

.prime-explain__step {
    position: relative;
    margin:0 auto;
    width: 100%;
    max-width: 52rem;
    padding-top:calc(120rem / 16);
    @media (min-width: 768px) {
        padding-top:calc(210rem / 16);
    }
}
.prime-explain__step:before {
    content:'';
    display: block;
    position: absolute;
    left:50%;
    top:calc(40rem / 16);
    width:1px;
    height: calc(40rem / 16);
    background: var(--color-text-default);
    @media (min-width: 768px) {
        top:calc(70rem / 16);
        height: calc(70rem / 16);
    }
}


.prime-explain__image {
    position: relative;
    padding-top:calc(10rem / 16);
    @media (min-width: 768px) {
        padding-top:calc(100rem / 16);
    }
}
.prime-explain__points-wrapper {
    background: var(--bg-standard);
    @media (min-width: 768px) {
        transform:translateY(-4rem);
    }
}
.prime-explain__points {
    position: absolute;
    left:0;
    top:50%;
    right:0;
    bottom:auto;
    transform: translateY(-50%);
    text-align: center;
    font-size:2.5rem;
    line-height: 1.25;
    font-family: var(--font-default-bold);
}
.prime-explain__points-point {
    font-size:3.75rem;
}