.collapse-btn {
    cursor: pointer;
    font-size: calc(12rem / 16);
    letter-spacing: calc(1.2rem / 16);
    position: relative;
    padding-bottom: calc(4rem / 16);
}
.collapse-btn:focus {
    outline: none;
}
.collapse-btn:not(.collapse-btn--covering):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(1rem / 16);
    background: currentColor;
    transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 768px) {
    .btn.collapse-btn--covering {
        padding-top:.9375rem;
        padding-bottom:.9375rem;
        box-shadow: none !important;
    }
}

.collapse-btn:focus:after,
.collapse-btn:hover:after {
    opacity: 0;
}

.collapsed .collapse-btn__more,
.collapse-btn__less {
    display: block;
}
.collapse-btn__more,
.collapsed .collapse-btn__less {
    display: none;
}