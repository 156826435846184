.radio-collapse__radio .custom-radio__text{
    font-size: calc(14rem / 16);
    letter-spacing: normal;
}
.radio-collapse__radio {
    padding: calc(15rem / 16);
    padding-right: calc(20rem/16);
    padding-left: calc(50rem/16);
    @media (min-width: 768px) {
        padding: calc(25rem / 16);
        padding-left: calc(60rem/16);
    }
}

.radio-collapse__radio .custom-radio__box {
    top: calc(16rem/16);
    left: calc(14rem/16);
    @media (min-width: 768px) {
        top: calc(27rem/16);
        left: calc(24rem/16);
    }
}

.radio-collapse__body {
    padding: 0 calc(20rem/16) calc(10rem/16) calc(20rem/16);
    @media (min-width: 768px) {
        padding: 0 calc(30rem/16) calc(10rem/16) calc(30rem/16);
    }
}

.radio-collapse__img {
    height: calc(30rem/16);
    width: auto;
    @media (max-width: 1199px) {
        max-width: calc(60rem/16);
    }
}