.dhl-search {
    @media (min-width: 768px) {
        height: calc(750rem/16);
    }
}

.dhl-search__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 768px) {
        margin-top: 1.5rem;
    }
}

.dhl-search__list {
    position: relative; /* important for scrolling with js*/
    flex: 1;
    margin-bottom: calc(20rem/16);
    overflow-y: auto;
    margin-top: calc(10rem/16);
}

.dhl-search__map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.dhl-search__map-wrapper {
    position: relative;
    height: 100%;
}
.dhl-search__form {
     margin-bottom: calc(15rem/16);
}
@media (max-width: 767px) {
    .dhl-search__map-wrapper {
        position: relative;
        overflow: hidden;
    }

    .dhl-search__map-wrapper:before {
        display: block;
        content: "";
        padding-top: 100%;
    }
}
