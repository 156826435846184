.privacy-button {
    position: fixed;

    border: none;
    border-radius: 50%;
    bottom: calc(35rem/16);
    right: calc(23rem/16);
    height: calc(40rem/16);
    width: calc(40rem/16);
    padding: calc(8rem/16);

    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    transition: transform .3s,
                background-color .2s,
                color .2s;

    @media (max-width: 767px) {
        height: calc(36rem/16);
        width: calc(36rem/16);
        bottom: calc(48rem/16);
        right: calc(5rem/16);
        padding: calc(9rem/16);
    }
}

@media (max-width: 767px) {
    .scroll-top-visible .privacy-button {
        transform: translateY(calc(-24rem/16));
    }
}
