.grid-filterbar {
    top: -1.625rem;
    position: relative;
    margin-bottom: -1.625rem;
    padding: 0 2.75rem;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.grid-filterbar.grid-filterbar--no-results,
.grid-filterbar-mobile.grid-filterbar-mobile--no-results {
    display: none !important;
}

.grid-filterbar-mobile {
    display: none;
}

.grid-sort-link {
    position: relative;
    display: inline-block;
    padding-right:1.375rem;
}
.grid-sort-link:after {
    content: var(--icon-arrow-right);
    font-family: iconfont;
    font-size:calc(11rem/16);
    pointer-events: none;
    position: absolute;
    right: .125rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

@media screen and (max-width: 767px) {
    .grid-filterbar-mobile {
        display:block;
        padding:.5rem 0 0 0;
        margin:0 -1rem;
        position: relative;
        top:-1px;
        background: var(--color-redesign-primary);
    }
    .grid-category-teaser--sale + .grid-filterbar-mobile {
        background-color: var(--color-sale);
        color:#fff;
    }
    .grid-filterbar-mobile__scroll {
        margin-bottom: 3rem;
        overflow-x: scroll;
        padding-bottom: 0.75rem;
        white-space: nowrap;
        position: relative;
        top: 2rem;
        margin-top: -2rem;
    }
}

.grid-filter-btn:hover button,
.grid-filter-btn button:focus{
    text-decoration: none;
}
.grid-filter-btn {
    background-color:#fff;
}
.grid-filter-btn__btn {
    color:#000;
    padding: calc(16rem / 16) calc(20rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(17/14);
    width: 100%;
    display: flex;
    align-items:center;
    background-color:#fff;
    border: none;
    box-shadow: none;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        color:var(--color-text-default);
        font-family: var(--font-default-semi-bold);
        letter-spacing: 1px;
        text-align:left;
        padding: calc(14rem/16);
    }
}
.grid-filter-btn__btn:focus {
    outline:none;
}
@media screen and (max-width: 767px) {
    .filter-collapse .grid-filter-btn--custom-mobile-styling {
        padding:0;
        border: none;
    }
    .filter-collapse .grid-filter-btn--custom-mobile-styling .grid-filter-btn__btn {
        padding: calc(11rem/16) 0;
        background-color: var(--color-light-grey);
    }
    .filter-collapse .grid-filter-btn--custom-mobile-styling .grid-filter-btn__btn.collapsed {
        background-color: var(--color-light-grey);
        border-bottom: 1px solid var(--color-medium-grey);
    }
}
.heart-img {
    display: inline-block;
    background: #EADBD5;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    padding: .25rem;
    text-align: center;
    vertical-align: 0;
}
.theme-men .btn--w-heart .heart-img,
.theme-men .side-nav__item__heart,
.heart-img--shoe{
    background:#fff;
    padding: .125rem;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
}

/* heart button after AB-Test*/
@media screen and (max-width: 767px) {
    .btn--w-heart {
        position: relative;
        padding-left:3.25rem;
    }
    .btn--w-heart .heart-img {
        position: absolute;
        left:1rem;
    }
    .theme-men .btn--w-heart .heart-img {
        position: absolute;
        top:.5rem;
    }
}


@media screen and (min-width: 768px) {
    .grid-filter-btn__btn--heart{
        position: relative;
        padding-left:3.25rem;
    }
    .grid-filter-btn__btn--heart .heart-img{
        position: absolute;
        left:1rem;
        top:.75rem;
    }
}

.grid-filter-btn__btn__sub-title {
    display:block;
    font-size: calc(10rem/16);
    font-family: var(--font-default);
    letter-spacing: 0;
    margin-top: calc(3rem/16);
    @media screen and (min-width: 768px) {
        display:none;
    }
}

.filter-collapse .grid-filter-btn .card-header{
    border: 0px solid transparent;

    /*margin-bottom: 0 !important;
    transform: translateX(1px) translateY(1px);*/
}

.filter-collapse .grid-filter-btn{
    border:1px solid #ececec;
    padding: 1px; /*fixes zoom chrome rendering bug*/
}

.filter-collapse .grid-filter-btn:hover,
.filter-collapse .grid-filter-btn.active{
    @media screen and (min-width: 768px) {
        border: 1px solid black;
    }
}


.grid-filter-btn .grid-filter-btn__arrow-icon{
    transform: rotate(270deg);
    transition-duration: 0s;
    color:inherit;
    @media screen and (max-width: 767px) {
        font-size: .625rem;
        padding: .625rem;
    }
}

.grid-filter-btn .collapsed .grid-filter-btn__arrow-icon{
    transform: rotate(90deg);
}

.filter-collapse .grid-filter-btn.has-value{
    border: 1px solid var(--color-primary);
}

@media screen and (min-width: 768px){
    .filter-collapse .grid-filter-btn.open{
        border: 1px solid var(--color-primary);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 15;
    }
}
.grid-filter-btn__body {
    padding: calc(20rem / 16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
    /*padding-top: calc(40rem / 16);*/
}
.filter-collapse--main .grid-filter-btn__body {
    @media screen and (max-width: 767px) {
        padding-top:0;
    }
}

.colorOptions__checkbox[type=checkbox]:checked + .colorOptions__color{
    border: 2px solid black;
    border-radius: 50%;
}

.colorOptions__color{
    cursor: pointer;
    border: 2px solid transparent;
}

.colorOptions__color:hover{
    border: 2px solid var(--color-primary);
    border-radius: 50%;
}

.colorOptions__content{
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    border-radius: 50%;
    border: 1px solid #dadada;
}

.colorOptions__container{
    line-height: 1;
}
.checkbox.colorOptions__container:not(:last-child) {
    margin-right: calc(4rem / 16);
}
.grid-filter-btn .collapse,
.grid-filter-btn .collapsing {
    transition: none;
}

@media screen and (max-width: 767px){
    .dont-collapse-mobile{
        display: block !important;
        height: auto !important;
        visibility: visible;
    }
    .dont-collapse-mobile.collapsing {
        transition: none;
    }
}

.product-grid-slider .noUi-connect{
    height: 10px;
    border-radius: 10px;
    border: none;
    background: var(--color-medium-grey);
    top: -1px;
}

.product-grid-slider .noUi-connects,
.product-grid-slider .noUi-base,
.product-grid-slider .ui-slider-horizontal,
.product-grid-slider .noUi-horizontal{
    height: 10px;
    border-radius: 10px;
    background: transparent;
}

.product-grid-filter--open{
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 1500;
    max-height: 100vh;
    overflow: auto;
}


.product-grid-slider .noUi-handle:after,
.product-grid-slider .noUi-handle:before{
    content: none !important;
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
}

.product-grid-slider .noUi-horizontal .noUi-handle{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    background: var(--color-primary);
    box-shadow: none;
}

html:not([dir=rtl]) .product-grid-slider .noUi-horizontal .noUi-handle{
    right: -15px;
    top: -10px;
}

.product-grid-slider .noUi-target{
    border: none;
    background-color: var(--color-light-grey);
    box-shadow: none;
    padding: 0 calc(13rem/16);
}

.grid-filter-btn .custom-checkbox__box:not(.custom-checkbox__box--sale) {
    width: calc(16rem / 16);
    height: calc(16rem / 16);
}

.product-grid__input{
    -webkit-appearance: none;
}

.product-grid__input::-ms-expand{
    display: none;
}

.card-header__btn {
    padding: calc(16rem / 16) calc(20rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(17/14);
}
.reset-btn {
    display: flex;
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    font-size: calc(10rem / 16);
    margin-top: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-top:0;
    }
}
.size-advisor__icon,
.reset-btn__icon {
    font-size: calc(14rem / 16);
    margin-right: calc(10rem / 16);
}

/*
.custom-select.custom-select--sort{
    background-image: var(--icon-arrow-right);
}
.custom-select.custom-select--sort:before{
    font-family: iconfont;
    content: var(--icon-arrow-right);
}*/

.custom-select--blank .custom-select{
    background: none;
    border:0;
    color:inherit;
    padding-left:0;
    border-bottom: .125rem solid;
}

.custom-select--icon .custom-select{
    background-image: none;
}
.custom-select--blank:before,
.custom-select--icon:before{
    position: absolute;
    font-family: iconfont;
    content: var(--icon-arrow-right);
    top: 50%;
    right: 10px;
    font-size: 11px;
    transform: translateY(-50%) rotate(90deg);
    pointer-events: none;
}
.product-grid__sticky-filter-btn {
    display: none;
}
@media screen and (max-width: 767px) {
    .product-grid__sticky-filter-btn {
        position: fixed;
        left:0;
        right:0;
        top:0;
        z-index:100;
        transform: translateY(0);
        transition: all .5s ease;
    }
    .header-is-affix .product-grid__sticky-filter-btn {
        transform: translateY(68px);
    }
    .product-grid__sticky-filter-btn.is-sticky {
        display: flex;
        z-index:220;
    }
}

.filter-min-height{
    min-height: 41px;
}

.product-grid__quickfilter-group{
    display: flex;
    flex-wrap: wrap;
}
.product-grid__quickfilter-group .quickfilter-btn{
    color:var(--color-text-default);
    padding-right: calc(20rem / 16);
    position: relative;
}
.product-grid__quickfilter-group .quickfilter-btn:after{
    font-family: iconfont;
    content: var(--icon-plus);
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 6px;
    font-size: calc(9rem / 16);
}

.colorOptions__content--colorful{
    background: linear-gradient(to right, red, yellow, lime, aqua, blue, magenta, red);
    background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}

.colorOptions__content--colorful-simple{
    background: linear-gradient(to right, #4169e1, #8b592b, #9932cc);
    background: conic-gradient(#4570f0, #af7036, #ab38e4, #808080, #008b00);
}