:root {
    --nav-teaser-body-padding-y: 1rem;
    --nav-teaser-body-padding-x: 2rem;
}

.nav-card {
    background: #fff;
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
    .nav-card__content.dropdown-menu.show {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    }
}
.nav-card__toggle {
    text-align: left;
    font-family: var(--font-default-semi-bold);
    font-size: .875rem;
    padding: .75rem 1rem;
    display: flex;
    align-items: baseline;
}
.nav-card__toggle-icon {
    margin-left: auto;
    transform: rotate(-90deg);
    transition: transform 120ms ease-out;
}
.nav-card.show .nav-card__toggle-icon {
    transform: rotate(90deg);
}
.nav-card__content.nav-card__content {
    padding: 0;
    margin: 0;
    border: none;
    right: 0;
    /*box-shadow: 0 4rem 4rem 0 rgba(0, 0, 0, .2);*/

    @media screen and (min-width: 576px) and (max-width: 1199px) {
        min-width: 25rem;
        left: auto;
    }

    @media screen and (min-width: 1200px) {
        box-shadow: none;
        position: static;
        display: block;
        float: none;
    }
}

.nav-card__title {
    font-size: 20px;
    margin: 0;

    @media screen and (min-width: 980px) and (max-width: 1399px) {
        font-size: 1rem;
    }
}
.nav-card__body {
    padding: var(--nav-teaser-body-padding-y) var(--nav-teaser-body-padding-x);

    @media screen and (min-width: 980px) and (max-width: 1399px) {
        padding: calc(var(--nav-teaser-body-padding-y) * .75) calc(var(--nav-teaser-body-padding-x) * .75);
    }
}
.nav-card__body--full-padding-y {
    padding-top: calc(var(--nav-teaser-body-padding-y) * 2);
    padding-bottom: calc(var(--nav-teaser-body-padding-y) * 2);

    @media screen and (min-width: 980px) and (max-width: 1399px) {
        padding-top: calc(var(--nav-teaser-body-padding-y) * 2 * .75);
        padding-bottom: calc(var(--nav-teaser-body-padding-y) * 2 * .75);
    }
}
.nav-card__body:not(.d-none) + .nav-card__body {
    border-top: 2px solid var(--color-light-grey);
}
.nav-card__list {
    list-style: none;
    padding-left: 0;
}
.nav-card__link {
    display: flex;
    align-items: center;
    padding: var(--nav-teaser-body-padding-y) var(--nav-teaser-body-padding-x);
    margin: 0 calc(var(--nav-teaser-body-padding-x) * -1);

    @media screen and (min-width:980px) and (max-width: 1399px) {
        padding: calc(var(--nav-teaser-body-padding-y) * .75) calc(var(--nav-teaser-body-padding-x) * .75);
        margin: 0 calc(var(--nav-teaser-body-padding-x) * .75 * -1);
    }
}
.is-active > .nav-card__link,
.nav-card__link.is-active {
    background: var(--color-light-grey);
    border-left: 2px solid var(--color-text-default);
}
.nav-card__link.nav-card__link--overview {
    background: var(--bg-standard) !important;
}
.nav-card__link:hover {
    background: var(--color-light-grey);
}


.nav-card__icon {
    flex: none;
    text-align: center;
    font-size: 1.5rem;
    width: 2rem;
    margin-right: 2rem;

    @media screen and (min-width: 980px) and (max-width: 1399px) {
        font-size: 1rem;
        width: 1.25rem;
        margin-right: 1.25rem;
    }
}
.nav-card__link-text {
    flex: auto;
    font-size: .875rem;
    font-family: var(--font-default-semi-bold);
}
.nav-card__link-sub-line {
    opacity: .7;
    font-size: .75rem;
    font-family: var(--font-default);
}

