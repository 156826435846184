.offscreen-filter {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(-100%);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    z-index: 1050;
    display: flex;
    will-change: transform;
    transition: transform 0.4s ease-in-out;
    flex-direction: column;
}
.modal-open .offscreen-filter {
    z-index:1040;
}
.offscreen-filter--bottom {
    top: auto;
    transform: translateY(100%);
    transition: 200ms ease-in-out;
}

@media (min-width: 768px) {
    .offscreen-filter--lg-right {
        left:auto;
        width:25rem;
        transform: translateX(100%);
        transition: 200ms ease-in-out;
    }
}

@media (max-width: 767px) {
    .offscreen-filter--xs-bottom {
        top: auto;
        transform: translateY(100%);
        transition: 200ms ease-in-out;
    }
}
.offscreen-filter.is-open.no-transition {
    transition: none;
}
.offscreen-filter.is-open {
    transform: translateY(0);
}
.offscreen-filter__head {
    position: relative;
    padding: calc(20rem / 16);
    @media (min-width: 768px) {
        padding:2rem 2rem 1.25rem 2rem;
    }
}
.offscreen-filter__head--product-filter{
    background-color: var(--color-primary);
    color:#fff;
    height: calc(48rem/16);
    padding: 0;
}
.offscreen-filter__footer {
    padding-bottom: calc(2 * env(safe-area-inset-bottom));
    text-align:center;
    background-color: var(--color-primary);
}
.offscreen-filter__head-item {
    padding: calc(14rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.offscreen-filter__head--bordered > [class^="col"]:nth-child(1) {
    border-bottom: 1px solid var(--color-light-grey);
}
.offscreen-filter__head-item--has-border {
    border-left: 1px solid rgba(245, 245, 245, 0.2);
    border-right: 1px solid rgba(245, 245, 245, 0.2);
}
.offscreen-filter__head__close {
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    font-size: calc(18rem / 16);
    line-height: 1;
}

.offscreen-filter__body {
    padding: calc(20rem / 16);
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @media (min-width: 768px) {
        padding:2rem;
    }
}

.offscreen-filter__body.bg-light-grey {
    padding-top: calc(26rem/16);
}

.offscreen-filter__collapse-filter {
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    text-align:left;
    padding: calc(14rem/16);
    display:flex;
    justify-content: space-between;
}

.offscreen-filter__collapse-filter__title {
    letter-spacing: 1px;
    font-family: var(--font-default-semi-bold);
}

.collapsed .offscreen-filter__collapse-filter__icon {
    transform: rotate(-90deg);
}
.offscreen-filter__collapse-filter__icon {
    transform: rotate(90deg);
    font-size: calc(10rem/16);
    padding: calc(10rem/16);
}
