.alert-notification__container.alert-notification__container {
    width: 20rem;
    @media (min-width: 768px) {
        width: 28rem;
    }
}

@media (min-width: 768px) {
    .alert-notification--fixed {
        bottom:-1rem;
    }
}
@media (max-width: 767px) {
    .alert {
        padding: calc(40rem/16) calc(30rem/16);
    }

    .alert-notification--fixed {
        padding: 0;
        left:auto;
        right:1rem;
    }

    /*.sticky-add-to-cart .alert-notification--fixed {
        bottom: calc(60rem/16);
    }*/

    .sticky-add-to-cart--double .alert-notification--fixed {
        bottom: calc(110rem/16);
    }
}
.alert {
    padding:1.75rem;
    font-size: .875rem;
}

.alert-notice {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert a:not(.btn) {
    text-decoration: underline;
}
.alert a:not(.btn):hover,
.alert a:not(.btn):focus {
    text-decoration: none;
}

.alert-with-message.alert-with-message {
    padding: 1.5rem 1rem 3rem;
}

.alert-with-message__message {
    background: var(--color-success);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.alert-notification--fixed {
    z-index: 1100;
}